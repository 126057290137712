import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormControl, Checkbox } from '@uitk/react';
import styled from 'styled-components';
import { getCombinedFontsForTheme } from 'builder/util/getCombinedFonts';
import { fontSizes } from 'builder/util/constants';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import { MainLabelStyles } from 'builder/components/WidgetConfigurations/Typography/Typography';
import { ColorButtonWrapper } from 'builder/components/WidgetConfigurations/Background/Background';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import {
  FontSelect,
  FontSizeSelect,
} from 'builder/components/WidgetConfigurations/Accordion/AccordionTypography';

const FontDropdowns = styled.div`
  display: flex;
  padding-left: 50px;
  margin-top: -25px;
`;

const StyledWrapper = styled.div`
  padding-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextIconWrapper = styled.div`
  cursor: pointer;
  font-family: OptumSans, sans-serif;
  text-align: center;
  padding-right: 5px;
  border-radius: 7px;
  &:hover {
    background: #d9f6fa;
  }
`;

export const StyledCustomIcon = styled.span`
  height: 1em;
`;

export interface NavigationTypographyProps<T> {
  handleFontSize: (data: string) => void;
  handleFont: (data: string) => void;
  handleTextColor: (data: string) => void;
  handleHoverTextColor: (data: string) => void;
  handleHoverTextColorCheckbox: (data: boolean) => void;
  widgetState: Widget<T>;
  pages: Array<Page>;
  site?: Site;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
  color: string;
  disabled?: boolean;
}

export const NavigationTypography: React.FC<NavigationTypographyProps<any>> = (
  navigationTypographyProps: NavigationTypographyProps<any>
) => {
  const {
    handleFontSize,
    handleFont,
    handleTextColor,
    handleHoverTextColor,
    handleHoverTextColorCheckbox,
    widgetState,
    site,
    updateSite,
    saveSite,
    disabled,
  } = navigationTypographyProps;

  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const [
    hoverPickerAnchorEl,
    setHoverPickerAnchorEl,
  ] = useState<HTMLDivElement | null>(null);
  const [
    isDifferentTextColorOnHover,
    setIsDifferentTextColorOnHover,
  ] = useState<boolean>(widgetState.config.isDifferentTextColorOnHover);
  const pickerOpen = Boolean(pickerAnchorEl);
  const hoverPickerOpen = Boolean(hoverPickerAnchorEl);
  const pickerId = pickerOpen
    ? 'simple-popover-navigation-typography'
    : undefined;
  const hoverPickerId = hoverPickerOpen ? 'hover-text-color-picker' : undefined;

  useEffect(() => {
    setIsDifferentTextColorOnHover(
      widgetState.config.isDifferentTextColorOnHover
    );
  }, [widgetState.config]);

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
    setHoverPickerAnchorEl(null);
  };

  const handleFontChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    handleFont(e.target.value);
  };

  const handleFontSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    handleFontSize(e.target.value);
  };

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.id === 'hover-text-color'
      ? setHoverPickerAnchorEl(e.currentTarget)
      : setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsDifferentTextColorOnHover(e.target.checked);
    handleHoverTextColorCheckbox(e.target.checked);
  };

  return (
    <>
      <MainLabelStyles>Font</MainLabelStyles>
      <FontDropdowns>
        <FormControl
          data-test-id="font-select-dropdown"
          disabled={disabled}
          id="controlled-select"
        >
          <FontSelect
            onChange={handleFontChange}
            value={widgetState.config.font}
          >
            {Object.keys(getCombinedFontsForTheme()).map(f => {
              return (
                <option value={f} key={f}>
                  {f}
                </option>
              );
            })}
          </FontSelect>
        </FormControl>
        <FormControl
          data-test-id="fontsize-dropdown"
          disabled={disabled}
          id="controlled-select"
        >
          <FontSizeSelect
            onChange={handleFontSizeChange}
            value={widgetState.config.fontSize}
          >
            {fontSizes.map(f => (
              <option value={f}>{f}pt</option>
            ))}
          </FontSizeSelect>
        </FormControl>
      </FontDropdowns>
      <StyledWrapper>
        <MainLabelStyles>Text Color</MainLabelStyles>
        <ColorButtonWrapper
          id="text-color"
          data-test-id="text-color-icon"
          onClick={handleColorEditClick}
        >
          <ColorButton
            color={widgetState.config.textColor}
            disabled={disabled}
          />
        </ColorButtonWrapper>
        <StyledPickerPopover
          data-test-id="widget-color-picker-popover"
          id={pickerId}
          open={pickerOpen}
          onClick={handlePickerClick}
          onClose={handlePickerClose}
          anchorEl={pickerAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <StyledPickerWrapper>
            <StyledPickerHeader data-test-id="styled-picker-header">
              Regular Text Color Selector
            </StyledPickerHeader>
            <StyledClose
              data-test-id="close-icon"
              onClick={handlePickerClose}
            />
          </StyledPickerWrapper>
          <GradientColorPicker
            value={widgetState.config.textColor}
            site={site}
            handleColorChange={handleTextColor}
            onChange={handleTextColor}
            updateSite={updateSite}
            saveSite={saveSite}
            anchorEl={pickerAnchorEl}
          ></GradientColorPicker>
        </StyledPickerPopover>
      </StyledWrapper>
      <StyledWrapper>
        <FormControl id="hover-text-color-checkbox" disabled={disabled}>
          <Checkbox
            onChange={handleCheckboxChange}
            checked={isDifferentTextColorOnHover}
          />
        </FormControl>
        <MainLabelStyles>Different Text Color on Hover</MainLabelStyles>
        {isDifferentTextColorOnHover && (
          <>
            <ColorButtonWrapper
              id="hover-text-color"
              data-test-id="hover-text-color-icon"
              onClick={handleColorEditClick}
            >
              <ColorButton
                color={
                  widgetState.config.hoverTextColor
                    ? widgetState.config.hoverTextColor
                    : 'black'
                }
                disabled={disabled}
              />
            </ColorButtonWrapper>
            <StyledPickerPopover
              data-test-id="widget-color-picker-popover"
              id={hoverPickerId}
              open={hoverPickerOpen}
              onClick={handlePickerClick}
              onClose={handlePickerClose}
              anchorEl={hoverPickerAnchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
            >
              <StyledPickerWrapper>
                <StyledPickerHeader data-test-id="styled-picker-header">
                  Hover Text Color Selector
                </StyledPickerHeader>
                <StyledClose
                  data-test-id="close-icon"
                  onClick={handlePickerClose}
                />
              </StyledPickerWrapper>
              <GradientColorPicker
                value={widgetState.config.hoverTextColor}
                site={site}
                handleColorChange={handleHoverTextColor}
                onChange={handleHoverTextColor}
                updateSite={updateSite}
                saveSite={saveSite}
                anchorEl={hoverPickerAnchorEl}
              />
            </StyledPickerPopover>
          </>
        )}
      </StyledWrapper>
    </>
  );
};
