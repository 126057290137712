import React, { useState, useEffect, MouseEvent } from 'react';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import styled from 'styled-components';
import { ColorButtonWrapper } from '../Background/Background';
import { Config } from 'shared/widgets/Tabs/types';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import { MainLabelStyles } from '../Typography/Typography';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export interface TabsColorSelectorProps<T> {
  pages?: Array<Page>;
  site?: Site;
  widgetState?: Widget<T>;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
  handleHoverFocusColor: (data: string) => void;
  disabled?: boolean;
}

const TabsColorSelector = (props: TabsColorSelectorProps<Config>) => {
  const {
    disabled,
    handleHoverFocusColor,
    widgetState,
    site,
    updateSite,
    saveSite,
  } = props;

  const [hoverFocusColor, setHoverFocusColor] = useState(
    widgetState.config.hoverFocusColor
  );
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover-tabs' : undefined;

  useEffect(() => {
    setHoverFocusColor(widgetState.config.hoverFocusColor);
  }, [widgetState.config]);

  useEffect(() => {
    handleHoverFocusColor(hoverFocusColor);
  }, [hoverFocusColor]);

  const handleColorEditClick = (e: MouseEvent<any>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorChange = newColor => {
    setHoverFocusColor(newColor);
  };

  return (
    <>
      <StyledWrapper>
        <MainLabelStyles>Hover and Focus Color</MainLabelStyles>
        <ColorButtonWrapper
          data-test-id="color-icon"
          id="button-color"
          onClick={handleColorEditClick}
        >
          <ColorButton color={hoverFocusColor} disabled={disabled} />
        </ColorButtonWrapper>
      </StyledWrapper>
      <StyledPickerPopover
        data-test-id="widget-color-picker-popover"
        id={pickerId}
        open={pickerOpen}
        onClick={handlePickerClick}
        onClose={handlePickerClose}
        anchorEl={pickerAnchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <StyledPickerWrapper>
          <StyledPickerHeader data-test-id="styled-picker-header">
            Color Selector
          </StyledPickerHeader>
          <StyledClose data-test-id="close-icon" onClick={handlePickerClose} />
        </StyledPickerWrapper>
        <GradientColorPicker
          value={hoverFocusColor}
          site={site}
          handleColorChange={handleColorChange}
          onChange={handleColorChange}
          updateSite={updateSite}
          saveSite={saveSite}
          anchorEl={pickerAnchorEl}
        />
      </StyledPickerPopover>
    </>
  );
};

export default TabsColorSelector;
