import axios from 'shared/util/axios';
import env from 'builder/config';

const version = env.permissionsEnabled ? 'v3' : 'v2';

const fetchSites = () => {
  return axios
    .get<Array<Site>>(`site-builder/${version}/sites`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const fetchSitesByUuid = () => {
  return axios
    .get<Array<Site>>(`site-builder/${version}/sites/sitesByUuid`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const fetchSite = (siteId: number) => {
  return axios
    .get(`site-builder/${version}/sites/${siteId}/current`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const createSite = (site: Partial<Site>) => {
  return axios
    .post<Site>(`site-builder/${version}/sites`, site)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const saveSite = (siteId: number, site: Site) => {
  return axios
    .put<Site>(`site-builder/${version}/sites/${siteId}`, site, {
      responseType: 'json',
    })
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const publishSite = (siteId: number) => {
  return axios
    .put(`site-builder/${version}/sites/${siteId}/publish`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};
const publishSiteToDemo = (siteId: number) => {
  return axios
    .put(`site-builder/${version}/sites/${siteId}/publish?isDemo=true`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};
const unPublishSite = (siteId: number) => {
  return axios
    .put(`site-builder/${version}/sites/${siteId}/unpublish`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const archiveSite = (siteId: number) => {
  return axios
    .put(`site-builder/${version}/sites/${siteId}/archive`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const fetchPages = (siteId: number) => {
  return axios
    .get<Array<Page>>(`site-builder/${version}/sites/${siteId}/pages`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const createPage = (siteId: number, page: Partial<PageVersion>) => {
  return axios
    .post<Page>(`site-builder/${version}/sites/${siteId}/pages`, page)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const savePage = (siteId: number, pageId: number, page: PageVersion) => {
  return axios
    .put<PageVersion>(
      `site-builder/${version}/sites/${siteId}/pages/${pageId}`,
      page
    )
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const saveAllPage = async (siteId: number, pages: Array<PageVersion>) => {
  try {
    const response = await axios.put<Array<PageVersion>>(
      `site-builder/${version}/sites/${siteId}/pages`,
      pages
    );

    return response.data;
  } catch (error) {
    return await Promise.reject(error);
  }
};

const createFormWidget = (
  siteId: number,
  pageId: number,
  widget: FormWidget
) => {
  return axios
    .post<FormWidget>(
      `site-builder/${version}/sites/${siteId}/pages/${pageId}/widgets/form`,
      widget
    )
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const updateFormWidget = (
  siteId: number,
  pageId: number,
  widget: FormWidget
) => {
  return axios
    .put<FormWidget>(
      `site-builder/${version}/sites/${siteId}/pages/${pageId}/widgets/form`,
      widget
    )
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const removePage = (siteId: number, pageId: number) => {
  return axios
    .delete(`site-builder/${version}/sites/${siteId}/pages/${pageId}`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const fetchSearchResults = (query: string, site: Site) => {
  const payload = {
    query: 'what is optum pro',
    searchServices: ['example'],
    metadata: {
      aideId: 'UHGWM110-023857',
      siteId: 548,
      siteName: 'site',
    },
  };

  return axios
    .post('horizon-search-api/api/v1/search', payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      return response.data.responses.map((item: any) => {
        // Extract date from content using regex
        const dateMatch = item.content.match(/^(\d{4}-\d{2}-\d{2})/);
        const extractedDate = dateMatch ? dateMatch[1] : '1970-01-01'; // Default if no date found

        return {
          category: item.pageFolder || 'Uncategorized',
          title: item.pageTitle,
          description: item.content.split(' - ')[1] || '',
          link: item.pageUrl,
          dateUpdated: extractedDate,
        };
      });
    })
    .catch(error => {
      console.error(
        'Error fetching search results:',
        query,
        site,
        error.response?.data || error.message
      );
      return [];
    });
};

export {
  fetchSites,
  createSite,
  saveSite,
  publishSite,
  publishSiteToDemo,
  unPublishSite,
  fetchPages,
  createPage,
  savePage,
  saveAllPage,
  createFormWidget,
  updateFormWidget,
  removePage,
  archiveSite,
  fetchSite,
  fetchSitesByUuid,
  fetchSearchResults,
};
