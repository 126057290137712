import { linkDeveloperTheme as theme } from 'link-ui-react';
import styled from 'styled-components';

const Wrapper = styled.div<{ disabled?: boolean }>`
  padding: 0.25rem;
  border-radius: 5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  & :hover {
    background: ${props => !props.disabled && theme.colors.aux.skyBlue};
  }
`;

const BigDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 26px;
  border-radius: 3px;
  border: 1px solid ${theme.colors.aux.darkNavyBlue};
  background: ${theme.colors.aux.white};
  & #strikethrough {
    position: relative;
  }
  & #strikethrough:before {
    position: absolute;
    content: '';
    top: 44%;
    right: -2px;
    left: -1px;
    border-top: 2px solid;
    border-color: #ef4e63;

    -webkit-transform: rotate(-28deg);
    -moz-transform: rotate(-28deg);
    -ms-transform: rotate(-28deg);
    -o-transform: rotate(-28deg);
    transform: rotate(-28deg);
  }
`;

const LittleDiv = styled.div<{ color: string }>`
  width: 34px;
  height: 20px;
  background: ${p => p.color};
  border: 1px solid ${theme.colors.aux.darkNavyBlue};
  border-radius: 3px;
`;

export interface ColorButtonProps {
  color: string;
  disabled?: boolean;
}

export const ColorButton: React.FC<ColorButtonProps> = (
  props: ColorButtonProps
) => {
  const { color, disabled } = props;

  return (
    <Wrapper disabled={disabled}>
      <BigDiv>
        <LittleDiv
          title={
            color === '#ffffff00' || color === 'transparent'
              ? 'Transparent'
              : color
          }
          id={
            color === '#ffffff00' || color === 'transparent'
              ? 'strikethrough'
              : ''
          }
          color={
            color === '#ffffff00' || color === 'transparent' ? '#f2f2f2' : color
          }
        />
      </BigDiv>
    </Wrapper>
  );
};
