import DropdownWidget from './DropdownWidget';
import DropdownWidgetControl from './DropdownWidgetControl';
import { Config } from './types';
import { defaultSizeLocationData } from 'builder/util/constants';

export default {
  type: 'Dropdown',
  displayName: 'Dropdown',
  icon: 'Dropdown',
  initialConfig: {
    ...defaultSizeLocationData,
    borderData: {
      thickness: 0,
      radius: 10,
    },
    uniqueId: '123abc',
    lineColor: 'red',
    required: false,
    label: 'My Label',
    value: 'Option 1, Option 2, Option 3',
    buttonColor: '#333333',
    minHeight: 106,
    width: 345,
    visible: true,
    font: 'OptumSans',
  },
  components: {
    render: DropdownWidget,
    control: DropdownWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
