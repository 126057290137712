import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isSameMonth,
  isSameDay,
  isAfter,
  isBefore,
} from 'date-fns';
import styled from 'styled-components';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { fetchEvents } from 'shared/api/events';
import {
  AccessTimeIcon,
  DateRangeIcon,
  LinkIcon,
  LocationOnIcon,
} from '@uitk/react-icons';

interface CalendarContainerProps {
  width: number;
  height: number;
  fontFamily: string;
  accentColor: string;
}
const Container = styled.div`
  display: flex;
  align-items: left-right;
`;

const CalendarWrapper = styled.div`
  display: flex;
`;

const EventsContainer = styled.div`
  display: flex;
  width: 390px;
  height: 277px;
  justify-content: flex-start;
`;
const EventsWindow = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const Triangle = styled.div`
  bottom: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid rgba(0, 0, 0, 0.12);
`;

const EventDetails = styled.div`
  width: 390px;
  height: 277px;
  box-shadow: 0px 0px 3px 0px rgba(13, 6, 6, 0.4);
  overflow: auto;
  position: relative;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;
const EventName = styled.div`
  font-family: 'OptumSans';
  padding-left: 8px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const EventItem = styled.div`
  margin-bottom: 10px;
  border: 1px;
  padding: 20px;
`;
const EventLine = styled.div`
  margin: 2px;
  padding: 3px;
`;

const CalendarContainer = styled.div<CalendarContainerProps>`
  display: flex;
  flex-direction: column;
  font-family: ${({ fontFamily }) => fontFamily};
  color: ${({ accentColor }) => accentColor};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  padding: 20px;
  box-shadow: 0px 0px 3px 0px rgba(13, 6, 6, 0.4);
`;

const Header = styled.div`
  font-family: 'OptumSans';
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
`;

const ArrowContainer = styled.div`
  cursor: pointer;
  padding: 5px;
`;

const DaysRow = styled.div`
  font-family: 'OptumSans';
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  font-family: OptumSans, sans-serif;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  flex-grow: 1;
`;

interface CellProps {
  isDisabled: boolean;
  isSelected: boolean;
  isCurrentDay: boolean;
  hasEvent: boolean;
  fontFamily: string;
}

const Cell = styled.div<CellProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
  position: relative;
  font-family: ${({ fontFamily }) => fontFamily || 'OptumSans'};
  background-color: ${({ isSelected, isCurrentDay, hasEvent }) =>
    isSelected || isCurrentDay || hasEvent ? '007bff' : 'inherit'};

  color: ${({ isSelected, isCurrentDay, hasEvent }) =>
    isSelected || isCurrentDay ? '#007bff' : hasEvent ? 'red' : 'inherit'};

  border-radius: ${({ isSelected, isCurrentDay }) =>
    isSelected || isCurrentDay ? '50%' : '0'};
  ${({ isDisabled }) =>
    isDisabled &&
    `
    color: #ccc;
    background-color: #f0f0f0;
  `}
`;

const Number = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const DateContainer = styled.div`
  text-align: center;
`;

interface MonthYearProps {
  accentColor: string;
  fontFamily: string;
  isMonth?: boolean;
}

const MonthYear = styled.div<MonthYearProps>`
  color: ${({ accentColor }) => accentColor || '#052575'};
  font-family: ${({ fontFamily }) => fontFamily || 'OptumSans'};
  font-size: ${({ isMonth }) => (isMonth ? '24px' : '16px')};
`;
const StyledSpan = styled.span`
  vertical-align: middle;
`;

interface CalendarProps {
  width?: number;
  height?: number;
  fontFamily?: string;
  accentColor?: string;
  editing?: boolean;
  published?: any;
  widgetId?: string;
}

interface Event {
  id: number;
  name: string;
  description: string;
  location: string;
  eventUrl: string;
  startTime: Date;
  endTime: Date;
  calendarId?: number;
}

function fetchEventsDummy(): Promise<Event[]> {
  return new Promise(resolve => {
    setTimeout(() => {
      const currentDate = new Date();
      const year = currentDate.getUTCFullYear();
      const month = currentDate.getUTCMonth();
      const day = currentDate.getUTCDate();
      // next day
      const nextDay = currentDate.getUTCDate() + 1;
      resolve([
        {
          id: 2,
          name: 'Sample Event',
          description: 'This is a sample event with a description.',
          location: 'Hyderabad',
          eventUrl: 'https://google.com/',
          startTime: new Date(Date.UTC(year, month, day, 10, 0, 0)),
          endTime: new Date(Date.UTC(year, month, day, 12, 0, 0)),
          calendarId: 5,
        },
        {
          id: 3,
          name: 'Another Sample Event',
          description:
            'This is another sample event with a different description.',
          location: 'New Jersey',
          eventUrl: 'https://www.youtube.com/',
          startTime: new Date(Date.UTC(year, month, nextDay, 13, 0, 0)),
          endTime: new Date(Date.UTC(year, month, nextDay, 15, 0, 0)),
          calendarId: 5,
        },
      ]);
    }, 1000);
  });
}

const Calendar: React.FC<CalendarProps> = ({
  width = 300,
  height = 400,
  fontFamily = 'OptumSans',
  accentColor = '#007bff',
  widgetId,
  published,
  editing,
}) => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);
  const [isEventDetailsVisible, setIsEventDetailsVisible] = useState(true);
  const eventDetailsRef = useRef(null);

  const handleClickOutside = event => {
    if (
      eventDetailsRef.current &&
      !eventDetailsRef.current.contains(event.target)
    ) {
      setIsEventDetailsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Fetch events from the API
    const fetchEventsData = async () => {
      try {
        if (published && !editing) {
          const data = await fetchEvents(widgetId);
          const transformedEvents = data.map(event => ({
            id: event.id,
            name: event.name,
            description: event.description,
            location: event.location,
            eventUrl: event.eventUrl,
            startTime: new Date(event.startTime),
            endTime: new Date(event.endTime),
          }));
          setEvents(transformedEvents);
        } else {
          const dummy = await fetchEventsDummy();
          setEvents(dummy);
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEventsData();
  }, [published, widgetId]);

  const handleMonthChange = useCallback((direction: 'prev' | 'next') => {
    setCurrentMonth(prevMonth =>
      direction === 'prev' ? subMonths(prevMonth, 1) : addMonths(prevMonth, 1)
    );
  }, []);

  const handleCloseButtonClick = () => {
    setIsEventDetailsVisible(false);
    setSelectedEvents([]); // Reset selected events
  };

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
    const eventsOnDate = events.filter(
      event =>
        isSameDay(new Date(event.startTime), date) ||
        isSameDay(new Date(event.endTime), date) ||
        (isAfter(date, new Date(event.startTime)) &&
          isBefore(date, new Date(event.endTime)))
    );
    setSelectedEvents(eventsOnDate);
    setIsEventDetailsVisible(true); // Ensure the popup is visible
  };

  const renderHeader = useCallback(
    () => (
      <Header data-test-id="calendar-header">
        <ArrowContainer
          onClick={() => handleMonthChange('prev')}
          data-test-id="leftArrow"
        >
          <ArrowLeftIcon />
        </ArrowContainer>
        <DateContainer>
          <MonthYear accentColor={accentColor} fontFamily={fontFamily} isMonth>
            {format(currentMonth, 'MMMM')}
          </MonthYear>
          <MonthYear accentColor={accentColor} fontFamily={fontFamily}>
            {format(currentMonth, 'yyyy')}
          </MonthYear>
        </DateContainer>
        <ArrowContainer
          onClick={() => handleMonthChange('next')}
          data-test-id="rightArrow"
        >
          <ArrowRightIcon />
        </ArrowContainer>
      </Header>
    ),
    [currentMonth, accentColor, fontFamily, handleMonthChange]
  );

  const renderDays = useCallback(() => {
    const days = [];
    const date = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <Cell
          key={i}
          isDisabled={false}
          isSelected={false}
          isCurrentDay={false}
          hasEvent={false}
          fontFamily={fontFamily}
          data-test-id={`dayCell${i}`}
        >
          {format(addDays(date, i), 'EEE')}
        </Cell>
      );
    }

    return <DaysRow>{days}</DaysRow>;
  }, [currentMonth, fontFamily]);

  const renderCells = useCallback(() => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = day;
        const hasEvent = events.some(
          event =>
            isSameDay(new Date(event.startTime), day) ||
            (isSameMonth(day, new Date(event.endTime)) &&
              isAfter(day, new Date(event.startTime)) &&
              isBefore(day, new Date(event.endTime))) ||
            (isBefore(new Date(event.startTime), day) &&
              isAfter(new Date(event.endTime), day)) ||
            (new Date(event.startTime).getFullYear() !==
              new Date(event.endTime).getFullYear() &&
              isAfter(day, new Date(event.startTime)) &&
              isBefore(day, new Date(event.endTime))) ||
            (isBefore(new Date(event.startTime), monthStart) &&
              isAfter(new Date(event.endTime), monthEnd)) ||
            (new Date(event.startTime).getFullYear() !==
              new Date(event.endTime).getFullYear() &&
              isBefore(new Date(event.startTime), monthStart) &&
              isAfter(new Date(event.endTime), monthEnd))
        );
        days.push(
          <Cell
            key={day.toString()}
            isDisabled={!isSameMonth(day, monthStart)}
            isSelected={isSameDay(day, selectedDate)}
            isCurrentDay={isSameDay(day, new Date())}
            hasEvent={hasEvent}
            onClick={() => handleDateClick(cloneDay)}
            fontFamily={fontFamily}
          >
            <Number>{format(day, 'd')}</Number>
          </Cell>
        );
        day = addDays(day, 1);
      }
      rows.push(<Row key={day.toString()}>{days}</Row>);
      days = [];
    }

    return <Body>{rows}</Body>;
  }, [currentMonth, selectedDate, events, fontFamily]);
  return (
    <Container>
      <CalendarWrapper>
        <CalendarContainer
          width={width}
          height={height}
          accentColor={accentColor}
          fontFamily={fontFamily}
          data-test-id="calendar"
        >
          {renderHeader()}
          {renderDays()}
          {renderCells()}
        </CalendarContainer>
      </CalendarWrapper>
      <EventsContainer>
        {isEventDetailsVisible && selectedEvents.length > 0 && (
          <EventsWindow>
            <Triangle />
            <EventDetails>
              <CloseButton onClick={handleCloseButtonClick}>
                &times;
              </CloseButton>
              {selectedEvents.map((event, index) => (
                <EventItem key={index}>
                  <EventName>{event.name}</EventName>
                  <EventLine>
                    <StyledSpan role="img" aria-label="calendar">
                      <DateRangeIcon />
                    </StyledSpan>{' '}
                    {format(new Date(event.startTime), 'EEE, MMM d, yyyy')} -{' '}
                    {format(new Date(event.endTime), 'EEE, MMM d, yyyy')}
                  </EventLine>
                  <EventLine>
                    <StyledSpan role="img" aria-label="clock">
                      <AccessTimeIcon />
                    </StyledSpan>{' '}
                    {format(new Date(event.startTime), 'p')} -{' '}
                    {format(new Date(event.endTime), 'p')}
                  </EventLine>
                  <EventLine>
                    <StyledSpan role="img" aria-label="location">
                      <LocationOnIcon />
                    </StyledSpan>{' '}
                    {event.location}
                  </EventLine>
                  <EventLine>
                    <StyledSpan role="img" aria-label="link">
                      <LinkIcon />
                    </StyledSpan>{' '}
                    <a
                      href={event.eventUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Event Link
                    </a>
                  </EventLine>
                  <EventLine>{event.description}</EventLine>
                </EventItem>
              ))}
            </EventDetails>
          </EventsWindow>
        )}
      </EventsContainer>
    </Container>
  );
};

export default React.memo(Calendar);
