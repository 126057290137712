import { Config } from './types';
import CheckboxButtonControl from './CheckboxButtonControl';
import {
  defaultBorderData,
  defaultSizeLocationData,
} from 'builder/util/constants';
import CheckboxButton from './CheckboxButton';

export default {
  type: 'Checkbox',
  displayName: 'Checkbox',
  icon: 'pipe-section',
  initialConfig: {
    ...defaultSizeLocationData,
    ...defaultBorderData,
    uniqueId: 'Checkbox123',
    value: 'Option 1, Option 2, Option 3',
    lineColor: 'none',
    label: 'Label',
    required: false,
    buttonColor: '#002677',
    width: 365,
    height: 85,
    isHorizonWidget: true,
    parentId: '',
    visible: true,
    font: 'OptumSans',
  },
  components: {
    render: CheckboxButton,
    control: CheckboxButtonControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
