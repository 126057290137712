import React from 'react';
import { Panel, Label } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import {
  PanelTop,
  SizeLocationPanel,
  StyledPanel,
  StyledPanelTitle,
} from '../Text/TextWidgetControl';
import { LoginGeneralPanel } from 'builder/components/WidgetConfigurations/Login/LoginGeneralPanel';
import { Typography } from 'builder/components/WidgetConfigurations/Typography/Typography';
import styled from 'styled-components';
import {
  Background,
  BackgroundData,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';

const StyledTypography = styled.div`
  padding: 1rem 0.65rem 0 0.65rem;
`;

const LoginButtonControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const {
    value,
    onChange,
    widgetState,
    currentPage: { layoutLocked },
  } = props;

  const handleSignInTypeChange = e => {
    onChange({
      ...value,
      signInType: e,
    });
  };

  const handleSignInOptionChange = enabled => {
    onChange({
      ...value,
      signInOptionEnabled: enabled,
    });
  };

  const handleProfileInDropdownChange = enabled => {
    onChange({
      ...value,
      profileInLoginDropdownEnabled: enabled,
    });
  };

  const handleSignInOptionTitleChange = (inputValue: string) => {
    onChange({
      ...value,
      signInOptionTitle: inputValue,
    });
  };

  const updateWidgetSizeLocation = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };

  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };

  const handleFontData = data => {
    onChange({
      ...value,
      font: data,
    });
  };

  const handleFontSizeData = data => {
    onChange({
      ...value,
      fontSize: data,
    });
  };

  const handleBoldData = data => {
    onChange({
      ...value,
      bold: data,
    });
  };

  const handleItalicData = data => {
    onChange({
      ...value,
      italic: data,
    });
  };

  const handleUnderlineData = data => {
    onChange({
      ...value,
      underline: data,
    });
  };

  const handleStrikethroughData = data => {
    onChange({
      ...value,
      strikeThrough: data,
    });
  };

  const handleSignInLinkColor = data => {
    onChange({
      ...value,
      signInLinkColor: data,
    });
  };

  const handleButtonColor = data => {
    onChange({
      ...value,
      buttonColor: data,
    });
  };

  const handleProfileIconChange = data => {
    onChange({
      ...value,
      signInIcon: data,
    });
  };

  const handleSignInOptionLinksChange = data => {
    onChange({
      ...value,
      signInOptionLinks: data,
    });
  };

  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };

  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };

  const updateBackgroundData = (data: BackgroundData) => {
    onChange({
      ...value,
      backgroundData: data,
    });
  };

  const updateBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };

  const handleBackgroundColor = (data: string) => {
    onChange({
      ...value,
      backgroundData: {
        ...value.backgroundData,
        color: data,
      },
    });
  };

  const handleSimulateUserCheck = data => {
    onChange({
      ...value,
      simulateLoggedOffUser: data.target.checked,
    });
  };

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
            data-test-id="login-general-panel"
          >
            <PanelTop>
              <LoginGeneralPanel
                data-test-id="login-panel"
                disabled={layoutLocked}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                handleSignInOptionChange={handleSignInOptionChange}
                handleSignInOptionTitleChange={handleSignInOptionTitleChange}
                handleProfileIconChange={handleProfileIconChange}
                widgetState={props.widgetState}
                handleSignInTypeChange={handleSignInTypeChange}
                handleSignInOptionLinksChange={handleSignInOptionLinksChange}
                handleButtonColor={handleButtonColor}
                handleSimulateUserCheck={handleSimulateUserCheck}
                handleProfileInDropdownChange={handleProfileInDropdownChange}
              />
            </PanelTop>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Typography</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="login-typography-panel"
          >
            <StyledTypography>
              <PanelTop>
                <Typography
                  data-test-id="typography-panel"
                  disabled={layoutLocked}
                  pages={props.pages}
                  site={props.site}
                  updateSite={props.updateSite}
                  saveSite={props.saveSite}
                  widgetState={widgetState}
                  boldData={handleBoldData}
                  handleFontSize={handleFontSizeData}
                  handleFont={handleFontData}
                  handleItalicData={handleItalicData}
                  handleUnderLine={handleUnderlineData}
                  handleStrikeThrough={handleStrikethroughData}
                  handleSignInLinkColor={handleSignInLinkColor}
                  hideAlign={true}
                  hideSpacing={true}
                  hideTextHighlight={true}
                />
              </PanelTop>
            </StyledTypography>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="authentication-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              disabled={layoutLocked}
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLocation={updateWidgetSizeLocation}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Background</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="background-banner-panel"
          >
            <Background
              data-test-id="background-component"
              disabled={layoutLocked}
              backgroundData={updateBackgroundData}
              backgroundImage={updateBackgroundImage}
              widgetState={widgetState}
              handleBackgroundColor={handleBackgroundColor}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Border</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="border-panel"
          >
            <Border
              data-test-id="border-data"
              disabled={layoutLocked}
              borderData={updateBorderData}
              handleLineColor={handleLineColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Padding</Label>}
            initiallyClosed
            icon={Arrow}
          >
            <Padding
              data-test-id="padding-data"
              disabled={layoutLocked}
              paddingData={updatePaddingData}
              widgetState={widgetState}
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default LoginButtonControl;
