import { PureComponent } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uuid from 'uuid/v4';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import URI from 'urijs';
import { getSignedUrl as fetchSignedUrl } from 'shared/api/s3';
import ReactS3Uploader from 'react-s3-uploader';
import { withRouter } from 'react-router-dom';
import config from 'builder/config';
import SiteEditContext from 'shared/util/SiteEditContext';
import { Button } from '@material-ui/core';
import { ArrowUpwardIcon } from '@uitk/react-icons';
import { RouteComponentProps } from 'react-router';
import { PathParamsType } from './types';
import { getTemplateSiteID } from '../../scenes/PageTemplates/utils';
import { TEMPLATES_SITE_ID } from '../../scenes/PageTemplates/constants/constants';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  font-size: 13px;
`;

const UploadLabel = styled.label`
  z-index: 1;
  &:hover {
    cursor: pointer;
  }
`;

export const UploadButton = styled(Button)`
  z-index: -1;
  .MuiButton-label {
    align-items: baseline;
    font-family: OptumSans;
  }
`;

const ErrorLabel = styled.div`
  color: #e40005;
  font-size: 13px;
  font-family: OptumSans;
  font-weight: 500;
`;

interface S3ImageState {
  uploading: boolean;
  uuid: string;
  error: boolean;
}
interface S3ImageProps extends RouteComponentProps<PathParamsType> {
  image?: Image;
  label: string;
  onUpload: (image: Image) => void;
  staticContext: any;
}

class S3Image extends PureComponent<S3ImageProps, S3ImageState> {
  static contextType = SiteEditContext;
  context!: React.ContextType<typeof SiteEditContext>;

  constructor(props: S3ImageProps) {
    super(props);
    this.state = {
      uploading: false,
      uuid: uuid(),
      error: false,
    };
  }

  handleFinish = (e: any) => {
    const { onUpload } = this.props;
    this.setState({
      uploading: false,
    });
    const uri = URI(e.signedUrl);
    const image: Image = {
      url: uri
        .hostname(config.cdn)
        .query('')
        .toString(),
      alt: uri.filename().toString(),
    };
    onUpload(image);
  };

  render() {
    const { uploading } = this.state;
    const { uuid, error } = this.state;
    const {
      image,
      label,
      match: {
        params: { siteId },
        path,
      },
    } = this.props;

    const siteUploadId = getTemplateSiteID(siteId, path);
    const { siteEditPermission } = this.context || {};
    // TODO: Fix the context in the template editor
    // template editor does not have a context set
    // defaulting to enabling file upload for template editor
    // for the time being until context is fixed and roles applied
    const isUploaderDisabled =
      siteUploadId === `${TEMPLATES_SITE_ID}` ? false : !siteEditPermission;
    return (
      <Wrapper>
        <ReactS3Uploader
          disabled={isUploaderDisabled}
          getSignedUrl={(file, cb) => fetchSignedUrl(file, cb, siteUploadId)}
          s3path={`site-builder/${siteUploadId}`}
          scrubFilename={filename => filename}
          uploadRequestHeaders={{}} // https://github.com/odysseyscience/react-s3-uploader/issues/106#issuecomment-283218194
          onProgress={() => {
            this.setState({ uploading: true });
          }}
          onFinish={this.handleFinish}
          style={{ display: 'none' }}
          accept="image/*"
          onError={() => {
            this.setState({ uploading: false });
            toast('Error uploading image', { type: 'error', theme: 'colored' });
          }}
          id={uuid}
          type="file"
        />
        <InputWrapper>
          <UploadLabel htmlFor={uuid}>
            <UploadButton
              style={{ textTransform: 'none' }}
              size="medium"
              variant="outlined"
              startIcon={
                uploading ? <CircularProgress size={15} /> : <ArrowUpwardIcon />
              }
            >
              {label}
            </UploadButton>
          </UploadLabel>
        </InputWrapper>
        {image &&
          image.url &&
          URI(image.url)
            .filename()
            .toString()}
        {error && <ErrorLabel>Invalid File</ErrorLabel>}
      </Wrapper>
    );
  }
}

export { S3ImageProps };
export default withRouter<S3ImageProps>(S3Image);
