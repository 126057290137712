import { PureComponent } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uuid from 'uuid/v4';
import styled from 'styled-components';
import { CircularProgress, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { UploadedFile } from '../shared/types';
import Uploader from './Uploader';
import URI from 'urijs';
import { ArrowUpwardIcon } from '@uitk/react-icons'; // ArrowUpwardIcon
import { UploadButton } from 'builder/components/S3Image/S3Image';

export type PathParamsType = {
  siteId: string;
};

export interface MultipartUploadFileProps
  extends RouteComponentProps<PathParamsType> {
  file: UploadedFile;
  label: string;
  accept: string;
  fileFormatRegex: RegExp;
  onUpload: (file: UploadedFile) => void;
  staticContext: any;
  siteId?: number;
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: auto;
`;

const UploadLabel = styled.label`
  &:hover {
    cursor: pointer;
  }
  width: 100%;
`;

interface MultiPartFileState {
  uploading: boolean;
  uuid: string;
  uploadedFile: File;
  siteId: string;
  fileUplaod: boolean;
}

class MultipartUpload extends PureComponent<
  MultipartUploadFileProps,
  MultiPartFileState
> {
  uploader: Uploader | null;

  constructor(props: MultipartUploadFileProps) {
    super(props);
    this.state = {
      uploading: false,
      uuid: uuid(),
      uploadedFile: null,
      siteId: null,
      fileUplaod: false,
    };
    this.uploader = null;
  }

  handleFinish = (signedUrlObject: string) => {
    this.setState({
      uploading: true,
    });
    try {
      const { onUpload } = this.props;

      const uri = new URI(signedUrlObject); // Initialize URI object with the URL string
      const file: UploadedFile = {
        url: uri
          .protocol('https')
          .query('')
          .toString(),
        name: uri.filename().toString(),
      };
      if (typeof onUpload === 'function') {
        onUpload(file);
      }
      this.setState({
        uploading: false,
      });

      toast('video upload successful', { type: 'success', theme: 'colored' });
    } catch (error) {
      console.error('Error in handleFinish:', error);
    }
  };

  rejectUpload = () => {
    this.setState({
      uploading: false,
    });
    toast('This file type is not supported', {
      type: 'error',
      theme: 'colored',
    });
  };

  getSiteIdFromPath(path: string) {
    return path.split('/')[2];
  }
  handleButtonClick() {
    this.setState({ uploading: true });
  }

  render() {
    const { uploading, uuid } = this.state;
    const {
      label,
      accept,
      fileFormatRegex,
      match: {
        params: { siteId },
      },
    } = this.props;

    let currentSiteId = '0';
    if (!siteId) {
      currentSiteId = this.getSiteIdFromPath(window.location.pathname);
    } else {
      currentSiteId = siteId.toString();
    }
    let renderUpload = this.state.uploadedFile;
    this.setState({ siteId: currentSiteId });

    return (
      <Wrapper>
        <InputWrapper>
          <UploadLabel htmlFor={`input-${uuid}`}>
            <UploadButton
              style={{ textTransform: 'none' }}
              size="medium"
              variant="outlined"
              startIcon={
                uploading ? <CircularProgress size={15} /> : <ArrowUpwardIcon />
              }
              onClick={this.handleButtonClick}
            >
              {label}
            </UploadButton>
            <TextField
              id={`input-${uuid}`}
              type="file"
              onChange={e => {
                const inputElement = e.target as HTMLInputElement;
                const files = inputElement.files;
                if (files && files.length > 0) {
                  this, this.setState({ uploading: true });
                  const selectedFile = files[0];
                  if (
                    fileFormatRegex &&
                    !fileFormatRegex.test(selectedFile.name)
                  ) {
                    this.rejectUpload();
                  } else {
                    this.setState({ uploadedFile: selectedFile });
                  }
                }
              }}
              inputProps={{
                accept,
                style: { display: 'none' },
                'data-test-id': 'file-input',
              }}
            />
            {renderUpload && (
              <Uploader
                file={renderUpload}
                siteId={this.state.siteId}
                onFinish={this.handleFinish}
              ></Uploader>
            )}
          </UploadLabel>
        </InputWrapper>
      </Wrapper>
    );
  }
}

export default withRouter(MultipartUpload);
