import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  updateWidgetConfig,
  selectWidget,
  setActiveWidgetId,
  removeWidget,
  addSibling,
  updatePageContent,
  removeDefaultWidgets,
  updateCurrentPageWidgetConfig,
  selectSite,
} from 'shared/state/ducks/sites';
import { getShowGridToggleValue } from 'shared/state/ducks/ui';
import { WidgetConnectorNew } from './WidgetConnectorNew';
import { addUndoRedoState } from 'shared/state/ducks/todos';
import { resetUndoRedo, setResetToFalse } from 'shared/state/ducks/ui';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { clearTodo } from 'shared/state/ducks/todos';
import { selectUser } from 'shared/state/misc/oidc';
import { initiateLogout } from 'shared/state/ducks/session';
import { selectIsEditingTemplate } from 'shared/state/ducks/pageTemplates';

interface WidgetConnectorContainerNewProps {
  siteId: number;
  pageId: number;
  site?: Site;
  page?: Page;
  widgetId: string;
  initiateLogout?: () => void;
}

const mapStateToProps = (
  state: RootState,
  ownProps: WidgetConnectorContainerNewProps
) => ({
  widgetState: selectWidget(
    ownProps.siteId,
    ownProps.pageId,
    ownProps.widgetId
  )(state),
  site: selectSite(ownProps.siteId)(state),
  activeWidgetId: ownProps.widgetId,
  shouldDisplayGridLines: getShowGridToggleValue()(state),
  undoState:
    state.undoabletodos.present[state.undoabletodos.present.length - 1],
  isUndoClick: state.ui.undoRedoPressedState.undo,
  isRedoClick: state.ui.undoRedoPressedState.redo,
  isResetState: state.ui.undoRedoPressedState.reset,
  user: selectUser()(state),
  isAccordionPanelOpen: state.ui.panelWidget.open,
  accordionShiftWidgetId: state.ui.panelWidget.widgetId,
  accordionHeightShift: state.ui.panelWidget.height,
  isEditingTemplate: selectIsEditingTemplate()(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: WidgetConnectorContainerNewProps
) => ({
  onUpdateConfig: (config: any, widgetId: string, defaultWidget: boolean) => {
    dispatch(
      updateWidgetConfig(
        ownProps.siteId,
        ownProps.pageId,
        widgetId,
        config,
        defaultWidget
      )
    );
  },
  updateCurrentPageWidgetConfig: (config: any, widgetId: string) => {
    dispatch(
      updateCurrentPageWidgetConfig(
        ownProps.siteId,
        ownProps.pageId,
        widgetId,
        config
      )
    );
  },
  setActiveWidgetId: (widgetId: string) => {
    dispatch(setActiveWidgetId(widgetId));
  },
  removeWidget: (widgetId: string) => {
    dispatch(removeWidget(ownProps.siteId, ownProps.pageId, widgetId));
  },
  removeDefaultWidgets: (widgetId: string) => {
    dispatch(removeDefaultWidgets(ownProps.siteId, ownProps.pageId, widgetId));
  },
  addSibling: (
    widgetType: string,
    config: any,
    siblingWidgetId: string,
    children: string[],
    newWidgetId: string,
    tabIndex: number
  ) => {
    dispatch(
      addSibling(
        ownProps.siteId,
        ownProps.pageId,
        siblingWidgetId,
        widgetType,
        config,
        newWidgetId,
        children,
        tabIndex
      )
    );
  },
  addTodoState: (pageContent: any) => {
    dispatch(addUndoRedoState(ownProps.siteId, ownProps.pageId, pageContent));
  },
  updatePageContent: (pageContent: any) => {
    dispatch(updatePageContent(ownProps.siteId, ownProps.pageId, pageContent));
  },
  resetUndoRedo: () => dispatch(resetUndoRedo()),
  setResetToFalse: () => dispatch(setResetToFalse()),
  onFlush: () => {
    dispatch(clearTodo());
    dispatch(UndoActionCreators.clearHistory());
  },
  initiateLogout: () => {
    dispatch(initiateLogout());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetConnectorNew);
