import { useEffect, useRef, useState } from 'react';
import { debounce, truncate } from 'lodash';
import { NodeKey } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TableOfContentsPlugin as LexicalTableOfContentsPlugin } from '@lexical/react/LexicalTableOfContentsPlugin';
import { TableOfContentsEntry } from '@lexical/react/LexicalTableOfContentsPlugin';

import './index.css';

const MARGIN_ABOVE_EDITOR = 100;
const HEADING_WIDTH = 9;
const MAX_HEADING_CHAR_LENGTH = 27;

function isHeadingAboveViewport(element: HTMLElement): boolean {
  const elementYPosition = element?.getClientRects()[0].y;
  return elementYPosition < MARGIN_ABOVE_EDITOR;
}

function isHeadingBelowTheTopOfThePage(element: HTMLElement): boolean {
  const elementYPosition = element?.getClientRects()[0].y;
  return elementYPosition >= MARGIN_ABOVE_EDITOR + HEADING_WIDTH;
}

export function TableOfContentsList({
  tableOfContents,
}: {
  tableOfContents: Array<TableOfContentsEntry>;
}): JSX.Element {
  const [selectedKey, setSelectedKey] = useState('');
  const selectedIndex = useRef(0);
  const [editor] = useLexicalComposerContext();

  function scrollToNode(key: NodeKey, currIndex: number) {
    editor.getEditorState().read(() => {
      const domElement = editor.getElementByKey(key);
      if (domElement !== null) {
        domElement.scrollIntoView();
        setSelectedKey(key);
        selectedIndex.current = currIndex;
      }
    });
  }

  function scrollCallback() {
    if (tableOfContents.length === 0) {
      selectedIndex.current = 0;
      return;
    }

    /**
     * Updates the selected index based on a condition function and an increment value.
     *
     * This function navigates through the `tableOfContents` array, starting from the current
     * `selectedIndex`, and updates the `selectedIndex` to the next valid heading that satisfies
     * the provided `conditionFn`. The navigation is done in the direction specified by the
     * `increment` value.
     *
     * @param conditionFn - A function that takes an `HTMLElement` and returns a boolean.
     *                      This function is used to determine if a heading element meets
     *                      the required condition.
     * @param increment - A number that specifies the direction and step size for navigating
     *                    through the `tableOfContents`. A positive value moves forward,
     *                    while a negative value moves backward.
     */
    const updateSelectedIndex = (
      conditionFn: (element: HTMLElement) => boolean,
      increment: number
    ) => {
      let currentHeading = editor.getElementByKey(
        tableOfContents[selectedIndex.current] &&
          tableOfContents[selectedIndex.current][0]
      );
      while (currentHeading !== null && conditionFn(currentHeading)) {
        const nextIndex = selectedIndex.current + increment;
        const nextHeading = editor.getElementByKey(
          tableOfContents[nextIndex] && tableOfContents[nextIndex][0]
        );
        if (nextHeading && conditionFn(nextHeading)) {
          selectedIndex.current = nextIndex;
        }
        currentHeading = nextHeading;
      }
      setSelectedKey(tableOfContents[selectedIndex.current][0]);
    };

    const currentHeading = editor.getElementByKey(
      tableOfContents[selectedIndex.current][0]
    );
    if (currentHeading !== null) {
      if (isHeadingBelowTheTopOfThePage(currentHeading)) {
        updateSelectedIndex(isHeadingBelowTheTopOfThePage, -1);
      } else if (isHeadingAboveViewport(currentHeading)) {
        updateSelectedIndex(isHeadingAboveViewport, 1);
      }
    }
  }

  useEffect(() => {
    const debouncedScrollCallback = debounce(scrollCallback, 10);
    function onScroll(): void {
      debouncedScrollCallback();
    }
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [tableOfContents, editor]);

  return (
    <div className="rte-table-of-contents" data-testid="rte-table-of-contents">
      <ul className="headings">
        <label className="first-heading">Page Contents</label>
        {tableOfContents.map(([key, text], index) => {
          return (
            <div
              className={`normal-heading-wrapper ${
                selectedKey === key ? 'selected-heading-wrapper' : ''
              }`}
              key={key}
            >
              <div>
                <li
                  onClick={() => scrollToNode(key, index)}
                  role="button"
                  tabIndex={0}
                  className={`normal-heading ${
                    selectedKey === key ? 'selected-heading' : ''
                  }
                    `}
                >
                  {truncate(text, { length: MAX_HEADING_CHAR_LENGTH })}
                </li>
              </div>
            </div>
          );
        })}
      </ul>
    </div>
  );
}

export default function TableOfContentsPlugin(): JSX.Element {
  return (
    <LexicalTableOfContentsPlugin>
      {tableOfContents => {
        return <TableOfContentsList tableOfContents={tableOfContents} />;
      }}
    </LexicalTableOfContentsPlugin>
  );
}
