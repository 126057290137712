import { Icon } from 'link-ui-react';
import React, { useState } from 'react';
import styled from 'styled-components';

interface EventListProps {
  eventList: EventData[];
  removeEvent: (eventId: number) => Promise<void>;
  onEditEvent: (event: EventData) => void;
}

const EventRow = styled.div`
  border-top: 1px solid rgba(211, 211, 211, 1);
  border-bottom: 1px solid rgba(211, 211, 211, 1);
  position: relative;
  padding: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const EventName = styled.span`
  flex-grow: 1;
`;
const EventListContainer = styled.div`
  min-height: 120px;
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto;
`;
const DropdownMenu = styled.div`
  position: absolute;
  top: 50%;
  width: 167px;
  right: -150px;
  transform: translateX(-100%);
  background: white;
  border: 1px solid rgba(211, 211, 211, 1);
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 111;
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
`;

const EventList: React.FC<EventListProps> = ({
  eventList,
  removeEvent,
  onEditEvent,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState<number | null>(null);

  const handleEventClick = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(dropdownVisible === index ? null : index);
  };
  const handleEdit = (event: EventData) => {
    onEditEvent(event);
    setDropdownVisible(null);
  };

  const handleDelete = (eventId: EventData) => {
    removeEvent(eventId.id);
    setDropdownVisible(null);
  };
  return (
    <EventListContainer>
      {eventList.map((event, index) => {
        const startDate = new Date(event.startTime);
        const dateOptions: Intl.DateTimeFormatOptions = {
          year: '2-digit',
          month: 'numeric',
          day: 'numeric',
        };
        const timeOptions: Intl.DateTimeFormatOptions = {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
        const formattedStartDate = startDate.toLocaleDateString(
          undefined,
          dateOptions
        );
        const formattedStartTime = startDate.toLocaleTimeString(
          undefined,
          timeOptions
        );

        return (
          <EventRow key={index}>
            <EventName>
              {event.name} on {formattedStartDate} {formattedStartTime}
            </EventName>
            <Icon
              icon="MoreVertical"
              height={16}
              width={5}
              style={{ position: 'relative' }}
              onClick={e => handleEventClick(e, index)}
            />
            {dropdownVisible === index && (
              <DropdownMenu>
                <DropdownItem onClick={() => handleEdit(event)}>
                  Edit
                </DropdownItem>
                <DropdownItem onClick={() => handleDelete(event)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            )}
          </EventRow>
        );
      })}
    </EventListContainer>
  );
};

export default EventList;
