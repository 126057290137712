import { Fragment, PureComponent } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import ThemeContext from '../../../shared/util/ThemeContext';
import SiteLandingPage from 'builder/scenes/SiteBuilder/SiteLandingPageContainer';
import Preview from 'builder/scenes/Preview';
import Loading from 'builder/scenes/Loading';
import SiteEditContext from 'shared/util/SiteEditContext';
import env from 'builder/config';
import Feedback from 'shared/components/Feedback/Feedback';
import { User } from 'shared/state/misc/oidc';
import SearchWidgetContainer from 'shared/widgets/Search/SearchWidgetContainer';

interface SiteRouterProps extends RouteComponentProps<any> {
  siteId: number;
  site: Site;
  user: User;
  siteMeta: StateMeta;
  fetchPages: () => void;
  isEditingTemplate: boolean;
}

class SiteRouter extends PureComponent<SiteRouterProps> {
  componentDidMount() {
    const { fetchPages, isEditingTemplate } = this.props;
    if (!isEditingTemplate) {
      fetchPages();
    }
  }

  render() {
    const {
      match: { path },
      siteId,
      siteMeta,
      site,
      user,
      history,
    } = this.props;

    if (!siteMeta.pending && siteMeta.successful) {
      return (
        <Fragment>
          <ThemeContext.Provider
            value={{
              theme: site.theme,
            }}
          >
            <SiteEditContext.Provider
              value={{
                siteEditPermission: !env.permissionsEnabled || site.access.edit,
              }}
            >
              <Route
                path={path}
                exact
                render={() => (
                  <SiteLandingPage
                    siteId={siteId}
                    pageId={site.horizonhomepageId}
                    parentWidgetId={'root'}
                    history={history}
                  />
                )}
              />
              <Route
                path="/sites/:siteId/preview/"
                render={({ match: { url: basePath } }) => {
                  return <Preview basePath={basePath} siteId={siteId} />;
                }}
              />
              <Route
                path="/sites/:siteId/pages/:pageId"
                render={({
                  match: {
                    params: { pageId: rawPageId },
                  },
                }) => {
                  const pageId = parseInt(rawPageId, 10);

                  if (isNaN(pageId)) {
                    return <Redirect to={path} />;
                  }

                  return (
                    <>
                      <SiteLandingPage
                        siteId={siteId}
                        pageId={pageId}
                        parentWidgetId={'root'}
                        history={history}
                      />
                      <Feedback
                        value={site.header.feedback}
                        siteId={siteId}
                        siteName={site.name}
                        user={user}
                      />
                    </>
                  );
                }}
              />
              <Route
                path="/sites/:siteId/search-results"
                render={() => {
                  return <SearchWidgetContainer site={site} />;
                }}
              />
            </SiteEditContext.Provider>
          </ThemeContext.Provider>
        </Fragment>
      );
    } else {
      return <Loading message="Loading site" />;
    }
  }
}

export default SiteRouter;
