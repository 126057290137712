import { TreeRenderProps } from 'react-complex-tree';
import { Classes, Collapse, Colors, Icon } from '@blueprintjs/core';
import { Icon as ToolkitIcon } from 'link-ui-react';
import { useEventPublisher } from 'use-event-emitter-hook';
import { StyledRequiredLabel } from 'builder/components/WidgetConfigurations/DragDropBeautifulDnD';

const cx = (...classNames: Array<string | undefined | false>) =>
  classNames.filter(cn => !!cn).join(' ');

export const navRenderers: TreeRenderProps = {
  renderTreeContainer: props => (
    <div className={cx(Classes.TREE)}>
      <ul
        className={cx(Classes.TREE_ROOT, Classes.TREE_NODE_LIST)}
        {...props.containerProps}
      >
        {props.children}
      </ul>
    </div>
  ),

  renderItemsContainer: props => (
    <ul className={cx(Classes.TREE_NODE_LIST)} {...props.containerProps}>
      {props.children}
    </ul>
  ),

  renderItem: props => (
    <li
      className={cx(
        Classes.TREE_NODE,
        (props.context.isSelected || props.context.isDraggingOver) &&
          Classes.TREE_NODE_SELECTED
      )}
      {...(props.context.itemContainerWithChildrenProps as any)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontFamily: 'OptumSans',
      }}
    >
      <div
        className={cx(
          Classes.TREE_NODE_CONTENT,
          `${Classes.TREE_NODE_CONTENT}-${props.depth}`
        )}
        {...(props.context.itemContainerWithoutChildrenProps as any)}
        {...(props.context.interactiveElementProps as any)}
        style={
          !props.item.isFolder && props.depth == 0
            ? {
                marginLeft: '16px',
              }
            : props.item.isFolder
            ? { paddingLeft: `${props.depth * 5}px` }
            : { paddingLeft: '25px' }
        }
      >
        {props.item.isFolder ? (
          props.arrow
        ) : (
          <span className={Classes.TREE_NODE_CARET_NONE} />
        )}
        {props.item.data.icon !== undefined ? (
          props.item.data.icon === null ? null : (
            <Icon
              icon={
                !props.item.isFolder
                  ? 'document'
                  : props.context.isExpanded
                  ? 'folder-open'
                  : 'folder-close'
              }
              className={Classes.TREE_NODE_ICON}
              size={18}
            />
          )
        ) : (
          (() => {
            const icon = !props.item.isFolder
              ? 'document'
              : props.context.isExpanded
              ? 'folder-open'
              : 'folder-close';
            return (
              <>
                <span>{'  '}</span>
                <Icon
                  icon={icon}
                  size={18}
                  className={Classes.TREE_NODE_ICON}
                />
              </>
            );
          })()
        )}
        {'  '}
        {'  '}
        {props.title}

        <ToolkitIcon
          id="nav-context-menu"
          data-test-id="nav-context-menu"
          style={{ position: 'relative' }}
          icon={'MoreVertical'}
          width={15}
          height={15}
          onClick={e => {
            e.stopPropagation();
            let data = {
              itemData: props.item.data,
              isEditClicked: true,
              isSection: props.item.isFolder,
              eventTarget: e.currentTarget,
            };

            const publisher = useEventPublisher();
            publisher('editClick', data);
          }}
        />
        {props.item.data.required && (
          <StyledRequiredLabel>*Required</StyledRequiredLabel>
        )}
      </div>
      <div
        className={cx(Classes.COLLAPSE)}
        style={
          props.context.isExpanded
            ? {
                height: 'auto',
                overflowY: 'visible',
                transition: 'none 0s ease 0s',
                marginLeft: props.arrow ? '20px' : '45px',
              }
            : {}
        }
      >
        <Collapse isOpen={props.context.isExpanded} transitionDuration={0}>
          {props.children}
        </Collapse>
      </div>
    </li>
  ),

  renderItemArrow: props => (
    <Icon
      icon={props.context.isExpanded ? 'chevron-down' : 'chevron-right'}
      className={cx(
        Classes.TREE_NODE_CARET,
        props.context.isExpanded
          ? Classes.TREE_NODE_CARET_OPEN
          : Classes.TREE_NODE_CARET_CLOSED
      )}
      {...(props.context.arrowProps as any)}
    />
  ),

  renderDragBetweenLine: ({ draggingPosition, lineProps }) => (
    <div
      {...lineProps}
      style={{
        position: 'absolute',
        right: '0',
        top:
          draggingPosition.targetType === 'between-items' &&
          draggingPosition.linePosition === 'top'
            ? '0px'
            : draggingPosition.targetType === 'between-items' &&
              draggingPosition.linePosition === 'bottom'
            ? '-4px'
            : '-2px',
        left: `${draggingPosition.depth * 23}px`,
        height: '4px',
        backgroundColor: Colors.BLUE3,
      }}
    />
  ),

  renderDepthOffset: 1,
};
