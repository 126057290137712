import { useEffect } from 'react';
import { LexicalEditor, LexicalNode } from 'lexical';
import { $isTableCellNode } from '@lexical/table';
import { $isAdmonitionNode } from '../nodes/AdmonitionNode';
import isSelectionInNodeType from '../utils/isSelectionInNodeType';

export function useToolbarDisabler(
  editor: LexicalEditor,
  setToolbarDisabled: (disabled: boolean) => void,
  // Optional node type to check if the selection is in a specific node type
  nodeType?: (node: LexicalNode) => boolean
) {
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        let isInNodeType: boolean;
        if (nodeType) {
          isInNodeType = isSelectionInNodeType(nodeType);
        } else {
          const isInAdmonition = isSelectionInNodeType($isAdmonitionNode);
          const isInTableNode = isSelectionInNodeType($isTableCellNode);
          isInNodeType = isInAdmonition || isInTableNode;
        }
        setToolbarDisabled(isInNodeType);
      });
    });
  }, [editor, setToolbarDisabled, nodeType]);
}
