import { useState } from 'react';
import debounce from 'lodash/debounce';
import { $isCodeNode } from '@lexical/code';
import {
  $getNearestNodeFromDOMNode,
  $getSelection,
  $setSelection,
  LexicalEditor,
} from 'lexical';
import { IconCopy, IconCopyCheck } from '@tabler/icons-react';
import { useTheme } from 'styled-components';

interface Props {
  editor: LexicalEditor;
  getCodeDOMNode: () => HTMLElement | null;
}

export function CopyButton({ editor, getCodeDOMNode }: Props) {
  const [isCopyCompleted, setCopyCompleted] = useState<boolean>(false);
  const theme = useTheme() as Theme;
  const removeSuccessIcon = debounce(() => {
    setCopyCompleted(false);
  }, 1000);

  async function handleClick(): Promise<void> {
    const codeDOMNode = getCodeDOMNode();
    if (!codeDOMNode) {
      return;
    }
    let content = '';
    editor.update(() => {
      const codeNode = $getNearestNodeFromDOMNode(codeDOMNode);
      if ($isCodeNode(codeNode)) {
        content = codeNode.getTextContent();
      }
      const selection = $getSelection();
      $setSelection(selection);
    });
    try {
      await navigator.clipboard.writeText(content);
      setCopyCompleted(true);
      removeSuccessIcon();
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  return (
    <button
      data-testid="rte-copy-button"
      className="menu-item"
      onClick={handleClick}
      aria-label="copy"
      title="Copy"
    >
      {isCopyCompleted ? (
        <IconCopyCheck style={{ color: theme?.colors?.aux.green }} />
      ) : (
        <IconCopy />
      )}
    </button>
  );
}
