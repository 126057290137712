import { $isElementNode, LexicalNode } from 'lexical';

/**
 * A helper that recursively traverses the Lexical node tree.
 */
export function traverseDescendants(
  node: LexicalNode,
  callback: (node: LexicalNode) => void
): void {
  callback(node);
  if ($isElementNode(node)) {
    node.getChildren().forEach(child => traverseDescendants(child, callback));
  }
}
