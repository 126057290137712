import {
  LexicalEditor,
  $createParagraphNode,
  $createTextNode,
  LexicalCommand,
  createCommand,
  $getRoot,
  $isRangeSelection,
  $getSelection,
  KEY_BACKSPACE_COMMAND,
  COMMAND_PRIORITY_LOW,
  KEY_ENTER_COMMAND,
} from 'lexical';
import {
  $createAdmonitionNode,
  $isAdmonitionNode,
  AdmonitionType,
} from '../../nodes/AdmonitionNode';
import React, { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  IconAlertTriangle,
  IconBulb,
  IconHandStop,
  IconInfoSquare,
  IconPencilExclamation,
  IconQuestionMark,
} from '@tabler/icons-react';
import { $createIconNode } from '../../nodes/AdmonitionNode/AdmonitionIconNode';

type InsertAdmonitionPayload = {
  type: AdmonitionType;
  customTitle?: string;
};

export const INSERT_ADMONITION_NODE_COMMAND: LexicalCommand<InsertAdmonitionPayload> = createCommand(
  'INSERT_ADMONITION_NODE_COMMAND'
);

export const TYPE_ICON_MAP: Record<AdmonitionType, React.ComponentType<any>> = {
  note: IconPencilExclamation,
  info: IconInfoSquare,
  tip: IconBulb,
  question: IconQuestionMark,
  danger: IconHandStop,
  warning: IconAlertTriangle,
};

function createAdmonitionNode(type: AdmonitionType, customTitle?: string) {
  const defaultTitleText = customTitle
    ? `${capitalizeFirstLetter(type)}: ${customTitle}`
    : `${capitalizeFirstLetter(type)}`;
  const admonitionNode = $createAdmonitionNode(type, defaultTitleText);
  const titleParagraph = $createParagraphNode();
  const IconComponent = TYPE_ICON_MAP[type];
  const titleNode = $createTextNode(defaultTitleText);
  const iconNode = $createIconNode(IconComponent, type);
  titleParagraph.append(iconNode);
  titleParagraph.append($createTextNode(' ')); // Add space before the title
  titleParagraph.append(titleNode);
  admonitionNode.append(titleParagraph);
  const bodyParagraph = $createParagraphNode();
  bodyParagraph.append(
    $createTextNode(
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ' +
        'Nulla et euismod nulla. Curabitur feugiat, tortor non consequat finibus...'
    )
  );
  admonitionNode.append(bodyParagraph);

  return admonitionNode;
}

export function insertAdmonition(
  editor: LexicalEditor,
  type: AdmonitionType,
  customTitle?: string
) {
  editor.update(() => {
    editor.dispatchCommand(INSERT_ADMONITION_NODE_COMMAND, {
      type,
      customTitle,
    });
  });
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function handleEnterCommand(event: KeyboardEvent): boolean {
  if (!event.shiftKey) {
    return false;
  }
  const selection = $getSelection();
  if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
    return false;
  }
  const anchorNode = selection.anchor.getNode();
  const parentNode = anchorNode.getParent();
  let admonitionNode = anchorNode;
  if (parentNode && $isAdmonitionNode(parentNode)) {
    admonitionNode = parentNode;
  }
  if (admonitionNode) {
    event.preventDefault();
    const emptyParagraphNode = $createParagraphNode();
    const topLevelElement = admonitionNode.getTopLevelElementOrThrow();
    topLevelElement.insertAfter(emptyParagraphNode);
    emptyParagraphNode.selectStart();
    return true;
  }
  return false;
}

function handleBackspaceCommand(event: KeyboardEvent): boolean {
  const selection = $getSelection();
  if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
    return false;
  }
  const anchorNode = selection.anchor.getNode();
  const parentNode = anchorNode.getParent();
  let admonitionNode = null;
  if ($isAdmonitionNode(anchorNode)) {
    admonitionNode = anchorNode;
  } else if (parentNode && $isAdmonitionNode(parentNode)) {
    admonitionNode = parentNode;
  }
  if (admonitionNode && admonitionNode.isEmpty()) {
    event.preventDefault();
    admonitionNode.remove();
    return true;
  }
  return false;
}

function handleInsertAdmonitionCommand(
  payload: InsertAdmonitionPayload
): boolean {
  const { type, customTitle } = payload;
  const admonitionNode = createAdmonitionNode(type, customTitle);
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const emptyParagraphNode = $createParagraphNode();
    selection.insertNodes([admonitionNode]);
    admonitionNode.insertAfter(emptyParagraphNode);
  } else {
    const root = $getRoot();
    root.append(admonitionNode);
    const emptyParagraphNode = $createParagraphNode();
    admonitionNode.insertAfter(emptyParagraphNode);
    admonitionNode.selectEnd();
  }
  return true;
}

export default function AdmonitionPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Insert Admonition node
    const unregisterInsertAdmonition = editor.registerCommand(
      INSERT_ADMONITION_NODE_COMMAND,
      handleInsertAdmonitionCommand,
      COMMAND_PRIORITY_LOW
    );

    // Shift + Enter to insert a new paragraph after the admonition and exit the admonition node
    const unregisterEnter = editor.registerCommand(
      KEY_ENTER_COMMAND,
      handleEnterCommand,
      COMMAND_PRIORITY_LOW
    );

    // Handle backspace to remove an empty admonition node
    const unregisterBackspace = editor.registerCommand(
      KEY_BACKSPACE_COMMAND,
      handleBackspaceCommand,
      COMMAND_PRIORITY_LOW
    );

    return () => {
      unregisterInsertAdmonition();
      unregisterBackspace();
      unregisterEnter();
    };
  }, [editor]);

  return null;
}
