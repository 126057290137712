import React, { ChangeEvent, useEffect, useState } from 'react';
import { menuClasses, SubMenu } from 'react-pro-sidebar';
import styled from 'styled-components';
import { linkDeveloperTheme as theme, Icon } from 'link-ui-react';
import { Label, Panel, TextInput, Button, Card, TextArea } from '@uitk/react';
import { Arrow, StyledPanelTitle } from '../SettingsPanel/SettingsPanel';
import { getWidget } from 'shared/widgets';
import Tooltip from 'shared/components/Tooltip';
import uuidv4 from 'uuid/v4';
import {
  DEFAULT_CUSTOM_WIDGET_MESSAGE,
  HEADER_PLACEHOLDER_HEIGHT,
  HEADER_SECTION_ID,
  bannerWidgetDefaultBodyText,
  cardWidgetDefaultBodyText,
  slideShowWidgetDefaultBodyText,
  tabsCardBodyText,
  widgetTypes,
} from 'builder/util/constants';
import config from 'builder/config';
import { getLeftTopPosition } from '../SiteBuilderHelper';
import {
  IconBolt,
  IconLetterCase,
  IconPhoto,
  IconLayersLinked,
  IconLink,
  IconSeparator,
  IconCode,
  IconSection,
  IconPhotoPlus,
  IconListDetails,
  IconApps,
  IconCards,
  IconSlideshow,
  IconFlagSpark,
  IconNotebook,
  IconMap2,
  IconCalendarMonth,
  IconTextCaption,
  IconForms,
  IconCheckbox,
  IconGradienter,
  IconSelect,
  IconFileUpload,
  IconFileTextSpark,
  IconVideo,
  IconSearch,
} from '@tabler/icons-react';
// import ListItemIcon from '@mui/material/ListItemIcon';
import {
  StyledCard,
  StyledNewPage,
  StyledPage,
  StyledSettingName,
  StyledSettingsCard,
  StyledTextName,
  StyledTitle,
  iconStyle,
} from '../PagesPanel/PagesPanel';
import { Popover } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { addChild as addNewChild } from 'shared/state/ducks/sites';

const { Group } = Panel;

const StyledMenuItem = styled(SubMenu)`
  margin-top: 16px;
`;

StyledMenuItem.displayName = 'submenu';

const StyledPanel = styled.div`
  & button[class^='Panelcomponent__HeaderSection'] {
    height: 2.5em;
    background: rgba(141, 145, 160, 0.07);
    border-radius: 0;
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
  & span[class^='Panelcomponent__HeaderMainContent'] {
    height: 2.5em;
  }
  & div[class^='uitk-panel Panelcomponent__Panel'] {
    margin-bottom: 0;
    border: none;
  }
  & div[class^='Panelcomponent__Content'] {
    padding: 0;
  }
`;

const StyledHeading = styled.p`
  color: ${theme.colors.aux.black};
  font-weight: 900;
  font-size: 21px;
  font-family: OptumSans, sans-serif;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  padding: 1.125rem 0 0.5rem 1.25rem;
`;

TopSection.displayName = 'topsection';

const IconGroupWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const IconWrapper = styled.div`
  padding: 1em 0;
  cursor: pointer;
  border-width: 0.5px 1px 0.5px 0;
  border-style: solid;
  border-color: ${theme.colors.aux.lightGrey};
  font-family: OptumSans, sans-serif;
  font-size: 0.9rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledPopover = styled(Popover)`
  margin-top: -14px;
  .MuiPaper-root {
    overflow-x: unset;
    overflow-y: unset;
    left: 350px !important;
    box-shadow: 1px 1px 5px -2px grey;
  }
`;

export const StyledTextInput = styled(TextInput)`
  font-family: OptumSans, sans-serif;
  padding: 0.5rem;
  font-size: 14px;
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: flex;
  .uitk-button {
    margin-right: 7px;
    margin-top: 50px;
    font-family: OptumSans, sans-serif;
    height: 10px;
  }
`;

const StyledWidgetMessage = styled.div`
  font-family: OptumSans, sans-serif;
  font-size: 12px;
  padding: 1.5rem;
  font-style: italic;
`;

const CustomWidgetPanelWrapper = styled.div`
  width: 250px;
  & > label + div + div {
    font-weight: 400;
    :hover {
      font-weight: 400;
    }
  }
`;

const CustomWidgetPanelInnerWrapper = styled.div`
  padding-left: 33px;
  display: flex;
  flex-direction: row;
`;

interface WidgetsPanelProps<T> {
  site: Site;
  pages: Array<Page>;
  updateSite: (site: Site) => void;
  getLocationData?: any;
  currentPage?: PageVersion;
  setResetToFalse: () => void;
  addChild?: (
    type: string,
    initialConfig: T,
    location: number,
    widgetId: string,
    newParentWidgetId: string,
    tabIndex: number
  ) => void;
  updateWidgetPosition?: (
    bannerWidgetId: string,
    height: number,
    oldHeight: number
  ) => void;
  widgetState: Widget<T>;
  onUpdateConfig: (config: any) => void;
  removeWidget?: (widgetId: string) => void;
  isEditingTemplate?: boolean;
}

export const WidgetsPanel: React.FC<WidgetsPanelProps<any>> = (
  props: WidgetsPanelProps<any>
) => {
  const {
    addChild,
    currentPage,
    isEditingTemplate,
    setResetToFalse,
    site,
  } = props;
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [locData, setLocData] = useState(undefined);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<SVGElement | null>(
    null
  );
  const [openEditWidget, setOpenEditWidgetFlag] = useState(false);
  const [activeId, setActiveId] = useState('');
  const [widgetNames, setWidgetNames] = useState([]);
  const [parentWidgets, setParentWidgets] = useState([]);
  const [updatedCustomWidgets, setUpdatedCustomWidgets] = useState([]);
  const widgetOpen = Boolean(settingsAnchorEl);
  const widgetsId = widgetOpen ? 'widget-popover' : undefined;
  const dispatch = useDispatch();

  const layoutLocked = !isEditingTemplate && currentPage?.layoutLocked;

  const headerHeight =
    props.currentPage?.content[HEADER_SECTION_ID]?.config.height ||
    HEADER_PLACEHOLDER_HEIGHT;

  const reset = () => {
    setSettingsAnchorEl(null);
    setSettingsOpen(false);
    setOpenEditWidgetFlag(false);
  };

  const toggleWidgetsPanelOpen = (isOpen: boolean) => {
    if (layoutLocked && isOpen) return;
    setIsPanelOpen(isOpen);
  };

  const handleSettingsClick = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    setSettingsOpen(!isSettingsOpen);
    setSettingsAnchorEl(e.currentTarget);
    setActiveId(item);
  };

  const handleInputClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
  };

  const handleClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    reset();
  };

  const handleDeleteClick = widgetId => {
    const widgetToDelete = props.site.customWidgets.find(
      widget => widget.widgetId === widgetId
    );
    const childWidgets = widgetToDelete?.widgetConfig.children || [];

    let updatedCustomWidgets = props.site.customWidgets.filter(
      widget => widget.widgetId !== widgetId
    );

    if (childWidgets.length > 0) {
      childWidgets.forEach(
        childId =>
          (updatedCustomWidgets = updatedCustomWidgets.filter(
            widget => widget.widgetId !== childId
          ))
      );
    }
    reset();
    dispatch(
      props.updateSite({
        ...props.site,
        customWidgets: updatedCustomWidgets,
      })
    );
  };

  const handleItemNameChange = (e: ChangeEvent<HTMLInputElement>, index) => {
    const updatedItems = widgetNames.map(item =>
      item.id === index ? { ...item, name: e.target.value } : item
    );

    setWidgetNames(updatedItems);

    const updatedWidgets = props.site.customWidgets.map(widget =>
      widget.widgetId === index
        ? { ...widget, widgetName: e.target.value }
        : widget
    );

    setUpdatedCustomWidgets(updatedWidgets);
  };

  const handleItemDescriptionChange = (
    e: ChangeEvent<HTMLInputElement>,
    index
  ) => {
    const updatedItems = widgetNames.map(item =>
      item.id === index ? { ...item, description: e.target.value } : item
    );

    setWidgetNames(updatedItems);

    // Update the widget name in the local state
    const updatedWidgets = props.site.customWidgets.map(widget =>
      widget.widgetId === index
        ? { ...widget, widgetDescription: e.target.value }
        : widget
    );

    setUpdatedCustomWidgets(updatedWidgets);
  };

  const handleSaveCustomWidgets = () => {
    reset();
    dispatch(
      props.updateSite({
        ...props.site,
        customWidgets: updatedCustomWidgets,
      })
    );
  };

  const complexWidgetTypes = [
    widgetTypes.Section,
    widgetTypes.Card,
    widgetTypes.AccordionWidget,
    widgetTypes.Tabs,
    widgetTypes.Form,
    widgetTypes.Banner,
    widgetTypes.SlideShow,
  ];

  useEffect(() => {
    props.getLocationData(locData);
  }, [locData]);

  useEffect(() => {
    if (!props.site?.customWidgets) {
      return;
    }

    const complexWidgetChildren = props.site.customWidgets
      .filter(widget => complexWidgetTypes.includes(widget.widgetConfig.type))
      .flatMap(widget => widget.widgetConfig.children || []);

    const parentWidgets = props.site.customWidgets
      .filter(widget => !complexWidgetChildren.includes(widget.widgetId))
      .map(widget => widget.widgetId);

    setParentWidgets(parentWidgets);
  }, [props.site?.customWidgets]);

  useEffect(() => {
    const updatedWidgetNames = parentWidgets.map(widgetId => ({
      id: widgetId,
      name: props.site.customWidgets.find(
        widget => widget.widgetId === widgetId
      )?.widgetName,
      description: props.site.customWidgets.find(
        widget => widget.widgetId === widgetId
      )?.widgetDescription,
    }));

    setWidgetNames(prevWidgetNames => [
      ...prevWidgetNames,
      ...updatedWidgetNames,
    ]);
  }, [parentWidgets]);

  function createChildWidgets(
    parentWidgetId,
    childConfigs,
    e,
    isBannerWidgetChild: boolean = false,
    visibility: boolean = true
  ) {
    const elementLeftPosition = getLeftTopPosition('editingContainerId')[0];
    const elementTopPosition = getLeftTopPosition('editingContainerId')[1];
    childConfigs.forEach(childWidget => {
      const widget = getWidget(childWidget.widgetType, true);
      const yLocOffset = e.clientY - elementTopPosition;
      const yLoc = yLocOffset > 0 ? yLocOffset : 0;
      let defaultConfig = {
        ...widget.initialConfig,
        visible: visibility,
        xLocation: e.clientX + childWidget.x - elementLeftPosition,
        yLocation: yLoc + childWidget.y,
        originalYLocation: yLoc + childWidget.y,
        width: childWidget.width,
        height: childWidget.height,
        minWidth: childWidget.minWidth,
        minHeight: childWidget.minHeight,
        parentId: childWidget.parentId,
        font: site.theme.font.family.base.value,
      };
      if (isBannerWidgetChild) {
        const bannerchildYLoc = headerHeight
          ? headerHeight + childWidget.y
          : 0 + childWidget.y;
        defaultConfig = {
          ...widget.initialConfig,
          xLocation: childWidget.x,
          yLocation: bannerchildYLoc,
          originalYLocation: bannerchildYLoc,
          width: childWidget.width,
          height: childWidget.height,
          minWidth: childWidget.minWidth,
          minHeight: childWidget.minHeight,
          parentId: childWidget.parentId,
        };
      }
      let widgetConfig;
      switch (childWidget.widgetType) {
        case widgetTypes.Text:
          widgetConfig = {
            ...defaultConfig,
            text: childWidget.text,
            bold: childWidget.bold,
            align: childWidget.align,
            fontSize: childWidget.fontSize,
            backgroundData: childWidget?.backgroundData,
            textColor: childWidget?.textColor,
            lineSpacing: childWidget.lineSpacing,
          };
          break;
        case widgetTypes.Image:
          widgetConfig = {
            ...defaultConfig,
            backgroundImage: childWidget.backgroundImage,
          };
          break;
        case widgetTypes.Button:
          widgetConfig = {
            ...defaultConfig,
            displayText: childWidget.displayText,
            bold: childWidget.bold,
            align: childWidget.align,
            paddingData: childWidget.paddingData,
            backgroundData: childWidget.backgroundData,
            textColor: childWidget.textColor,
            borderData: childWidget.borderData,
            lineColor: childWidget.lineColor,
            action: childWidget.action,
            site: childWidget.site ? childWidget.site : site,
            hoverData: childWidget.hoverData,
          };
          break;
        case widgetTypes.TextInput:
          widgetConfig = {
            ...defaultConfig,
            label: childWidget.label,
            required: childWidget.required,
          };
          break;
        case widgetTypes.RadioButton:
          widgetConfig = {
            ...defaultConfig,
            label: childWidget.label,
            value: childWidget.value,
            required: childWidget.required,
          };
          break;
        case widgetTypes.Divider:
          widgetConfig = {
            ...defaultConfig,
            text: childWidget.text,
            bold: childWidget.bold,
            align: childWidget.align,
            lineColor: childWidget.lineColor,
            direction: childWidget.direction,
            thickness: childWidget.thickness,
          };
          break;
        case widgetTypes.Card:
          widgetConfig = {
            ...defaultConfig,
          };
          break;
        case widgetTypes.RichText:
          widgetConfig = {
            ...defaultConfig,
          };
          break;
        case widgetTypes.Link:
          widgetConfig = {
            ...defaultConfig,
            displayText: childWidget.displayText,
            url: childWidget.url,
            iconColor: childWidget.iconColor,
            newTab: childWidget.newTab,
            bold: childWidget.bold,
            underline: childWidget.underline,
            fontSize: childWidget.fontSize,
            textColor: childWidget.textColor,
            backgroundData: childWidget.backgroundData,
            hoverData: childWidget.hoverData,
          };
          break;
        case widgetTypes.LoginButton:
          widgetConfig = {
            ...defaultConfig,
          };
          break;
        default:
          widgetConfig = { ...defaultConfig };
          break;
      }
      const widgetId = uuidv4();
      addChild(
        childWidget.widgetType,
        widgetConfig,
        0,
        widgetId,
        parentWidgetId,
        childWidget.tabIndex
      );
      setLocData({
        x: e.clientX + childWidget.x - elementLeftPosition,
        y: yLoc + childWidget.y,
        originalYLocation: yLoc + childWidget.y,
        widgetType: childWidget.widgetType,
      });
    });
  }

  // Add and update widget config when dragged
  const onDragItem = (e, widgetType: string, widgetState = null) => {
    const eleLeft = getLeftTopPosition('editingContainerId')[0];
    const eleTop = getLeftTopPosition('editingContainerId')[1];
    const xLoc = e.clientX - eleLeft;
    const yLocOrig = e.clientY - eleTop;
    const yLoc = yLocOrig > 0 ? yLocOrig : 0;
    e.preventDefault();
    setResetToFalse();
    if (widgetState?.config?.isCustomWidget === true) {
      const widgetId = uuidv4();
      const newWidgetConfig = {
        ...widgetState.config,
        xLocation: xLoc,
        yLocation: yLoc,
        isCustomWidget: false,
        font: site.theme.font.family.base.value,
        originalYLocation: yLoc,
      };
      addChild(widgetType, newWidgetConfig, 0, widgetId, 'root', -1);
      setLocData({
        x: xLoc,
        y: yLoc,
        originalYLocation: yLoc,
        widgetType: widgetType,
      });
      if (complexWidgetTypes.includes(widgetType)) {
        const childWidgetIds = [];

        widgetState.children.map(child => {
          const childWidgetId = uuidv4();
          childWidgetIds.push(childWidgetId);

          const childWidget = props.site.customWidgets.find(
            widget => widget.widgetId === child
          )?.widgetConfig;

          const childXLoc =
            xLoc +
            (childWidget.config.xLocation - widgetState.config.xLocation);
          const childYLoc =
            yLoc +
            (childWidget.config.yLocation - widgetState.config.yLocation);

          const customChildWidget = {
            ...childWidget,
            config: {
              ...childWidget.config,
              xLocation: childXLoc,
              yLocation: childYLoc,
              originalYLocation: childYLoc,
              isCustomWidget: false,
            },
          };

          dispatch(
            addNewChild(
              site.id,
              props.currentPage.pageId,
              widgetId,
              customChildWidget.type,
              customChildWidget.config,
              0,
              childWidgetId,
              customChildWidget.tabIndex
            )
          );
        });
        newWidgetConfig.children = childWidgetIds;
      }
    } else {
      const widget = getWidget(widgetType, true);
      // Create Parent widget
      const newParentWidgetId = uuidv4();
      let widgetConfig = {
        ...widget.initialConfig,
        xLocation: xLoc,
        yLocation: yLoc,
        font: site.theme.font.family.base.value,
        originalYLocation: yLoc,
      };
      if (widgetType === widgetTypes.Banner) {
        widgetConfig = {
          ...widget.initialConfig,
          xLocation: 0,
          yLocation: headerHeight ?? HEADER_PLACEHOLDER_HEIGHT,
        };
      }
      if (widgetType === widgetTypes.Application) {
        widgetConfig = {
          ...widgetConfig,
          uniqueId: newParentWidgetId,
          siteId: site.id,
          pageId: props.currentPage.pageId,
        };
      }
      addChild(widgetType, widgetConfig, 0, newParentWidgetId, 'root', -1);
      setLocData({
        x: xLoc,
        y: yLoc,
        originalYLocation: yLoc,
        widgetType: widgetType,
      });
      if (widgetType === widgetTypes.Tabs) {
        const tabsWidgets = [
          {
            widgetType: widgetTypes.Image,
            x: 5,
            y: 95,
            originalYLocation: 95,
            width: 1190,
            height: 498,
            minWidth: 100,
            minHeight: 40,
            backgroundImage: {
              url:
                'https://cdn-stage.linkhealth.com/site-builder/horizon/Tab1Background.png',
              alt: '',
            },
            tabIndex: 0,
          },
          {
            widgetType: widgetTypes.Text,
            x: 44,
            y: 190,
            originalYLocation: 190,
            text: 'Get the support you need in person, virtually or digitally',
            bold: true,
            width: 260,
            height: 110,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            fontSize: 20,
            textColor: '#414445',
            tabIndex: 0,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Card,
            x: 44,
            y: 360,
            originalYLocation: 360,
            width: 350,
            height: 194,
            minWidth: 180,
            minHeight: 40,
            tabIndex: 0,
          },
          {
            widgetType: widgetTypes.Text,
            x: 54,
            y: 374,
            originalYLocation: 374,
            text: 'Text Category 1',
            bold: true,
            width: 200,
            textColor: '#414445',
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 0,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 54,
            y: 420,
            originalYLocation: 420,
            text: tabsCardBodyText,
            bold: false,
            textColor: '#414445',
            width: 300,
            height: 75,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 0,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 54,
            y: 506,
            originalYLocation: 506,
            text: 'Getting Started >',
            bold: true,
            width: 200,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 0,
            textColor: '#196ece',
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Card,
            x: 421,
            y: 360,
            originalYLocation: 360,
            width: 350,
            height: 194,
            minWidth: 180,
            minHeight: 40,
            tabIndex: 0,
          },
          {
            widgetType: widgetTypes.Text,
            x: 431,
            y: 374,
            originalYLocation: 374,
            text: 'Text Category 1',
            bold: true,
            textColor: '#414445',
            width: 200,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 0,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 431,
            y: 420,
            originalYLocation: 420,
            text: tabsCardBodyText,
            bold: false,
            textColor: '#414445',
            width: 300,
            height: 75,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 0,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 431,
            y: 506,
            originalYLocation: 506,
            text: 'Getting Started >',
            bold: true,
            width: 200,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 0,
            textColor: '#196ece',
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Card,
            x: 800,
            y: 360,
            originalYLocation: 360,
            width: 350,
            height: 194,
            minWidth: 180,
            minHeight: 40,
            tabIndex: 0,
          },
          {
            widgetType: widgetTypes.Text,
            x: 810,
            y: 374,
            originalYLocation: 374,
            text: 'Text Category 1',
            bold: true,
            textColor: '#414445',
            width: 200,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 0,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 810,
            y: 420,
            originalYLocation: 420,
            text: tabsCardBodyText,
            bold: false,
            textColor: '#414445',
            width: 300,
            height: 75,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 0,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 810,
            y: 506,
            originalYLocation: 506,
            text: 'Getting Started >',
            bold: true,
            width: 200,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 0,
            textColor: '#196ece',
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Image,
            x: 5,
            y: 95,
            originalYLocation: 95,
            width: 1190,
            height: 498,
            minWidth: 100,
            minHeight: 40,
            backgroundImage: {
              url:
                'https://cdn-stage.linkhealth.com/site-builder/horizon/Tab2Background.png',
              alt: '',
            },
            tabIndex: 1,
          },
          {
            widgetType: widgetTypes.Text,
            x: 44,
            y: 190,
            originalYLocation: 190,
            text: 'Helping you prepare and save for health care expenses',
            bold: true,
            textColor: '#414445',
            width: 300,
            height: 110,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            fontSize: 20,
            tabIndex: 1,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Card,
            x: 44,
            y: 360,
            originalYLocation: 360,
            width: 350,
            height: 194,
            minWidth: 180,
            minHeight: 40,
            tabIndex: 1,
          },
          {
            widgetType: widgetTypes.Text,
            x: 54,
            y: 374,
            originalYLocation: 374,
            text: 'Text Category 1',
            bold: true,
            textColor: '#414445',
            width: 200,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 1,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 54,
            y: 420,
            originalYLocation: 420,
            text: tabsCardBodyText,
            bold: false,
            textColor: '#414445',
            width: 300,
            height: 75,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 1,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 54,
            y: 506,
            originalYLocation: 506,
            text: 'Getting Started >',
            bold: true,
            width: 200,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 1,
            textColor: '#196ece',
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Card,
            x: 421,
            y: 360,
            originalYLocation: 360,
            width: 350,
            height: 194,
            minWidth: 180,
            minHeight: 40,
            tabIndex: 1,
          },
          {
            widgetType: widgetTypes.Text,
            x: 431,
            y: 374,
            originalYLocation: 374,
            text: 'Text Category 1',
            bold: true,
            textColor: '#414445',
            width: 200,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 1,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 431,
            y: 420,
            originalYLocation: 420,
            text: tabsCardBodyText,
            bold: false,
            textColor: '#414445',
            width: 300,
            height: 75,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 1,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 431,
            y: 506,
            originalYLocation: 506,
            text: 'Getting Started >',
            bold: true,
            width: 200,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 1,
            textColor: '#196ece',
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Card,
            x: 800,
            y: 360,
            originalYLocation: 360,
            width: 350,
            height: 194,
            minWidth: 180,
            minHeight: 40,
            tabIndex: 1,
          },
          {
            widgetType: widgetTypes.Text,
            x: 810,
            y: 374,
            originalYLocation: 374,
            text: 'Text Category 1',
            bold: true,
            textColor: '#414445',
            width: 200,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 1,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 810,
            y: 420,
            originalYLocation: 420,
            text: tabsCardBodyText,
            bold: false,
            textColor: '#414445',
            width: 300,
            height: 75,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 1,
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 810,
            y: 506,
            originalYLocation: 506,
            text: 'Getting Started >',
            bold: true,
            width: 200,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            tabIndex: 1,
            textColor: '#196ece',
            backgroundData: {
              style: 'None',
              color: 'transparent',
            },
          },
        ];
        createChildWidgets(newParentWidgetId, tabsWidgets, e);
      }
      if (widgetType === widgetTypes.Card) {
        const childWidgets = [
          {
            widgetType: widgetTypes.Text,
            x: 10,
            y: 20,
            originalYLocation: 20,
            text: 'Heading',
            bold: true,
            textColor: '#414445',
            width: 400,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            backgroundData: { style: 'Color', color: 'white' },
            fontSize: 20,
            font: 'UHCSans',
          },
          {
            widgetType: widgetTypes.Image,
            x: 10,
            y: 70,
            originalYLocation: 70,
            width: 400,
            height: 215,
            minWidth: 100,
            minHeight: 40,
            backgroundImage: {
              url:
                'https://cdn-stage.linkhealth.com/site-builder/horizon/CardTemplateImage.png',
              alt: '',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 10,
            y: 292,
            originalYLocation: 292,
            text: 'Subheading',
            textColor: '#414445',
            bold: true,
            width: 400,
            height: 30,
            minWidth: 180,
            minHeight: 30,
            align: 'none',
            backgroundData: { style: 'Color', color: 'white' },
          },
          {
            widgetType: widgetTypes.Text,
            x: 10,
            y: 329,
            originalYLocation: 329,
            text: cardWidgetDefaultBodyText,
            bold: false,
            textColor: '#414445',
            width: 400,
            height: 95,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            backgroundData: { style: 'Color', color: 'white' },
          },
          {
            widgetType: widgetTypes.Button,
            x: 10,
            y: 433,
            originalYLocation: 433,
            displayText: 'My Button Text',
            bold: true,
            width: 400,
            height: 60,
            minWidth: 180,
            minHeight: 60,
            align: 'center',
            paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
            textColor: 'white',
            backgroundData: { style: 'Color', color: '#002677' },
            borderData: { thickness: 0, radius: 50 },
            lineColor: 'white',
            hoverData: {
              font: 'OptumSans',
              textColor: 'white',
              fontSize: 12,
              bold: true,
              align: 'center',
              italic: false,
              underline: false,
              strikeThrough: false,
              characterSpacing: 0,
              lineSpacing: 1,
              lineColor: 'white',
              borderData: { thickness: 0, radius: 50 },
              backgroundData: { style: 'Color', color: '#002677' },
              iconStyle: 'Without Icon',
              icon: {
                url: '',
                alt: '',
              },
              iconPlacement: 'Left',
            },
          },
          {
            widgetType: widgetTypes.Button,
            x: 10,
            y: 502,
            originalYLocation: 502,
            displayText: 'My Button Text',
            bold: true,
            width: 400,
            height: 60,
            minWidth: 180,
            minHeight: 60,
            align: 'center',
            paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
            backgroundData: { style: 'Color', color: 'white' },
            textColor: '#002677',
            borderData: { thickness: 2, radius: 50 },
            lineColor: '#002677',
            hoverData: {
              font: 'OptumSans',
              textColor: '#002677',
              fontSize: 12,
              bold: true,
              align: 'center',
              italic: false,
              underline: false,
              strikeThrough: false,
              characterSpacing: 0,
              lineSpacing: 1,
              lineColor: '#002677',
              borderData: { thickness: 2, radius: 50 },
              backgroundData: { style: 'Color', color: 'white' },
              iconStyle: 'Without Icon',
              icon: {
                url: '',
                alt: '',
              },
              iconPlacement: 'Left',
            },
          },
        ];
        createChildWidgets(newParentWidgetId, childWidgets, e);
      }
      if (widgetType === widgetTypes.Form) {
        const childWidgets = [
          {
            widgetType: widgetTypes.Text,
            x: 10,
            y: 20,
            originalYLocation: 20,
            text: 'Your Form Title',
            fontSize: 24,
            bold: true,
            textColor: '#414445',
            width: 400,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            backgroundData: { style: 'Color', color: 'white' },
          },
          {
            widgetType: widgetTypes.Text,
            x: 10,
            y: 70,
            originalYLocation: 70,
            text: 'All fields with an asterisk (*) are required',
            fontSize: 12,
            bold: false,
            textColor: '#414445',
            width: 400,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            backgroundData: { style: 'Color', color: 'white' },
          },
          {
            widgetType: widgetTypes.TextInput,
            x: 10,
            y: 120,
            originalYLocation: 120,
            label: 'Name',
            required: true,
            width: 400,
            height: 85,
            minWidth: 100,
            minHeight: 40,
          },
          {
            widgetType: widgetTypes.TextInput,
            x: 10,
            y: 220,
            originalYLocation: 220,
            label: 'E-Mail Address',
            required: true,
            width: 400,
            height: 85,
            minWidth: 180,
            minHeight: 30,
          },
          {
            widgetType: widgetTypes.TextInput,
            x: 10,
            y: 320,
            originalYLocation: 320,
            label: 'Contact Number',
            required: false,
            width: 400,
            height: 85,
            minWidth: 180,
            minHeight: 30,
          },
          {
            widgetType: widgetTypes.RadioButton,
            x: 10,
            y: 420,
            originalYLocation: 420,
            label: 'Preferred Contact Method',
            required: false,
            width: 400,
            height: 85,
            minWidth: 180,
            minHeight: 30,
            value: 'Call, Text, E-Mail',
          },
          {
            widgetType: widgetTypes.Button,
            x: 95,
            y: 520,
            originalYLocation: 520,
            displayText: 'Submit',
            action: 'submit',
            bold: true,
            width: 240,
            height: 60,
            minWidth: 180,
            minHeight: 60,
            align: 'center',
            paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
            textColor: 'white',
            backgroundData: { style: 'Color', color: '#002677' },
            borderData: { thickness: 0, radius: 50 },
            lineColor: '',
            site: site,
            hoverData: {
              font: 'OptumSans',
              textColor: 'white',
              fontSize: 12,
              bold: true,
              align: 'center',
              italic: false,
              underline: false,
              strikeThrough: false,
              characterSpacing: 0,
              lineSpacing: 1,
              lineColor: '',
              borderData: { thickness: 0, radius: 50 },
              backgroundData: { style: 'Color', color: '#002677' },
              iconStyle: 'Without Icon',
              icon: {
                url: '',
                alt: '',
              },
              iconPlacement: 'Left',
            },
          },
        ];
        createChildWidgets(newParentWidgetId, childWidgets, e);
      }
      if (widgetType === widgetTypes.Banner) {
        const childWidgets = [
          {
            widgetType: widgetTypes.Image,
            x: 36,
            y: 14,
            width: 24,
            height: 24,
            originalYLocation: 30,
            minWidth: 10,
            minHeight: 10,
            backgroundImage: {
              url:
                'https://cdn-stage.linkhealth.com/site-builder/horizon/Flag.png',
              alt: '',
            },
          },
          {
            widgetType: widgetTypes.Text,
            x: 76,
            y: 12,
            originalYLocation: 10,
            text: bannerWidgetDefaultBodyText,
            bold: false,
            textColor: '#414445',
            width: 1160,
            height: 80,
            minWidth: 180,
            minHeight: 40,
            align: 'none',
            backgroundData: { style: 'Color', color: '#EEF4FF' },
          },
          {
            widgetType: widgetTypes.Divider,
            x: 76,
            y: 64,
            originalYLocation: 60,
            bold: false,
            width: 1160,
            height: 31,
            minWidth: 31,
            minHeight: 31,
            align: 'none',
            lineColor: '#224AA1',
          },
        ];
        props.updateWidgetPosition(newParentWidgetId, widgetConfig.height, 0);
        createChildWidgets(newParentWidgetId, childWidgets, e, true);
      }
      if (widgetType === widgetTypes.SlideShow) {
        const childWidgets = [
          {
            widgetType: widgetTypes.Image,
            x: 79,
            y: 36,
            originalYLocation: 36,
            width: 413,
            height: 232,
            minWidth: 10,
            minHeight: 10,
            backgroundImage: {
              url:
                'https://cdn-stage.linkhealth.com/site-builder/horizon/image-placeholder.png',
              alt: '',
            },
            tabIndex: 0,
          },

          {
            widgetType: widgetTypes.Text,
            x: 532,
            y: 36,
            originalYLocation: 36,
            text: 'Slideshow Heading 1',
            bold: true,
            textColor: '#414445',
            width: 600,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            fontSize: 18,
            align: 'none',
            tabIndex: 0,
          },

          {
            widgetType: widgetTypes.Text,
            x: 532,
            y: 78,
            originalYLocation: 78,
            text: slideShowWidgetDefaultBodyText,
            bold: false,
            textColor: '#414445',
            width: 600,
            height: 135,
            minWidth: 180,
            minHeight: 40,
            fontSize: 11,
            align: 'none',
            tabIndex: 0,
          },

          {
            widgetType: widgetTypes.Button,
            x: 531,
            y: 217,
            originalYLocation: 217,
            displayText: 'My Button Text',
            bold: true,
            width: 240,
            height: 50,
            minWidth: 100,
            minHeight: 10,
            align: 'center',
            paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
            textColor: 'white',
            backgroundData: { style: 'Color', color: '#002677' },
            borderData: { thickness: 0, radius: 50 },
            lineColor: 'white',
            tabIndex: 0,
            hoverData: {
              font: 'OptumSans',
              textColor: 'white',
              fontSize: 12,
              bold: true,
              align: 'center',
              italic: false,
              underline: false,
              strikeThrough: false,
              characterSpacing: 0,
              lineSpacing: 1,
              lineColor: 'white',
              borderData: { thickness: 0, radius: 50 },
              backgroundData: { style: 'Color', color: '#002677' },
              iconStyle: 'Without Icon',
              icon: {
                url: '',
                alt: '',
              },
              iconPlacement: 'Left',
            },
          },

          {
            widgetType: widgetTypes.Image,
            x: 79,
            y: 36,
            originalYLocation: 36,
            width: 413,
            height: 232,
            minWidth: 10,
            minHeight: 10,
            backgroundImage: {
              url:
                'https://cdn-stage.linkhealth.com/site-builder/horizon/image-placeholder.png',
              alt: '',
            },
            tabIndex: 1,
          },

          {
            widgetType: widgetTypes.Text,
            x: 532,
            y: 36,
            originalYLocation: 36,
            text: 'Slideshow Heading 2',
            bold: true,
            textColor: '#414445',
            width: 600,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            fontSize: 18,
            align: 'none',
            tabIndex: 1,
          },

          {
            widgetType: widgetTypes.Text,
            x: 532,
            y: 78,
            originalYLocation: 78,
            text: slideShowWidgetDefaultBodyText,
            bold: false,
            textColor: '#414445',
            width: 600,
            height: 135,
            minWidth: 180,
            minHeight: 40,
            fontSize: 11,
            align: 'none',
            tabIndex: 1,
          },

          {
            widgetType: widgetTypes.Button,
            x: 531,
            y: 217,
            originalYLocation: 217,
            displayText: 'My Button Text',
            bold: true,
            width: 240,
            height: 50,
            minWidth: 100,
            minHeight: 10,
            align: 'center',
            paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
            textColor: 'white',
            backgroundData: { style: 'Color', color: '#002677' },
            borderData: { thickness: 0, radius: 50 },
            lineColor: 'white',
            tabIndex: 1,
            hoverData: {
              font: 'OptumSans',
              textColor: 'white',
              fontSize: 12,
              bold: true,
              align: 'center',
              italic: false,
              underline: false,
              strikeThrough: false,
              characterSpacing: 0,
              lineSpacing: 1,
              lineColor: 'white',
              borderData: { thickness: 0, radius: 50 },
              backgroundData: { style: 'Color', color: '#002677' },
              iconStyle: 'Without Icon',
              icon: {
                url: '',
                alt: '',
              },
              iconPlacement: 'Left',
            },
          },

          {
            widgetType: widgetTypes.Image,
            x: 79,
            y: 36,
            originalYLocation: 36,
            width: 413,
            height: 232,
            minWidth: 10,
            minHeight: 10,
            backgroundImage: {
              url:
                'https://cdn-stage.linkhealth.com/site-builder/horizon/image-placeholder.png',
              alt: '',
            },
            tabIndex: 2,
          },

          {
            widgetType: widgetTypes.Text,
            x: 532,
            y: 36,
            originalYLocation: 36,
            text: 'Slideshow Heading 3',
            textColor: '#414445',
            bold: true,
            width: 600,
            height: 40,
            minWidth: 180,
            minHeight: 40,
            fontSize: 18,
            align: 'none',
            tabIndex: 2,
          },

          {
            widgetType: widgetTypes.Text,
            x: 532,
            y: 78,
            originalYLocation: 78,
            text: slideShowWidgetDefaultBodyText,
            bold: false,
            textColor: '#414445',
            width: 600,
            height: 135,
            minWidth: 180,
            minHeight: 40,
            fontSize: 11,
            align: 'none',
            tabIndex: 2,
          },

          {
            widgetType: widgetTypes.Button,
            x: 531,
            y: 217,
            originalYLocation: 217,
            displayText: 'My Button Text',
            bold: true,
            width: 240,
            height: 50,
            minWidth: 100,
            minHeight: 10,
            align: 'center',
            paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
            textColor: 'white',
            backgroundData: { style: 'Color', color: '#002677' },
            borderData: { thickness: 0, radius: 50 },
            lineColor: 'white',
            tabIndex: 2,
            hoverData: {
              font: 'OptumSans',
              textColor: 'white',
              fontSize: 12,
              bold: true,
              align: 'center',
              italic: false,
              underline: false,
              strikeThrough: false,
              characterSpacing: 0,
              lineSpacing: 1,
              lineColor: 'white',
              borderData: { thickness: 0, radius: 50 },
              backgroundData: { style: 'Color', color: '#002677' },
              iconStyle: 'Without Icon',
              icon: {
                url: '',
                alt: '',
              },
              iconPlacement: 'Left',
            },
          },
        ];
        createChildWidgets(newParentWidgetId, childWidgets, e);
      }
    }
  };

  const handleToggledNav = e => {
    if (config.navWidgetEnabled) {
      onDragItem(e, widgetTypes.Navigation);
    }
  };

  const handleItemDescriptionValue = (widgetId: string) => {
    const currentItemDescription = widgetNames.find(
      widget => widget.id === widgetId
    )?.description;
    if (currentItemDescription === DEFAULT_CUSTOM_WIDGET_MESSAGE) {
      return 'Maximum of 120 characters.';
    }
    return currentItemDescription;
  };

  const WidgetsIcon = layoutLocked ? (
    <Tooltip
      title="This page has a locked layout, you cannot add widgets to the page"
      arrow
      placement="right-start"
      leaveDelay={100}
    >
      <IconBolt
        size={36}
        stroke={2}
        color="#999899"
        style={{ cursor: 'not-allowed' }}
      />
    </Tooltip>
  ) : (
    <IconBolt size={36} stroke={2} color="#2E3034" />
  );

  return (
    <StyledMenuItem
      data-test-id="widgets-menu-icon"
      title="Widgets"
      icon={WidgetsIcon}
      onClick={() => toggleWidgetsPanelOpen(true)}
      rootStyles={{
        ['& > .' + menuClasses.button]: {
          color: theme.colors.aux.white,
          '&:hover': {
            backgroundColor: theme.colors.aux.white,
          },
          '&:focus': {
            backgroundColor: '#D9F6FA',
          },
        },
        ['.' + menuClasses.subMenuContent]: {
          backgroundColor: theme.colors.aux.white,
          width: isPanelOpen ? '264px' : '0px',
          margin: '-10px',
        },
        [`.${menuClasses.icon}`]: {
          width: '34px',
          minWidth: '34px',
          height: '34px',
          color: '#2E3034',
          '&:hover': {
            backgroundColor: theme.colors.aux.skyBlue,
            fill: theme.colors.aux.darkNavyBlue,
          },
        },
      }}
    >
      <div
        style={{ height: '775px', overflowY: 'scroll' }}
        onClick={handleInputClick}
      >
        <TopSection data-test-id="widgets-heading-section">
          <StyledHeading>Widgets</StyledHeading>
          <Icon
            icon="Collapse"
            onClick={() => toggleWidgetsPanelOpen(false)}
            width="22px"
            style={{ marginLeft: '114px' }}
          />
        </TopSection>
        <Group>
          <StyledPanel>
            <Panel
              data-test-id="widget-visual-elements-panel"
              title={<Label style={StyledPanelTitle}>Visual Elements</Label>}
              initiallyClosed
              icon={Arrow}
            >
              <IconGroupWrapper
                style={
                  !site.access.edit
                    ? { pointerEvents: 'none', opacity: '0.4' }
                    : {}
                }
              >
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Text)}
                  data-test-id="text-widget-icon"
                >
                  <IconLetterCase stroke={2} />
                  Text
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Image)}
                  id="image-widget-icon"
                  data-test-id="image-widget-icon"
                >
                  <IconPhoto stroke={2} />
                  Image
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.VideoUpload)}
                >
                  <IconVideo stroke={2} />
                  VideoUpload
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Button)}
                  data-test-id="button-widget-icon"
                >
                  <IconLayersLinked />
                  Button
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Link)}
                  data-test-id="link-widget-icon"
                >
                  <IconLink />
                  Link
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.RichText)}
                  data-test-id="richtexteditor-widget-icon"
                >
                  <IconFileTextSpark />
                  Rich Text Editor
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Divider)}
                  data-test-id="divider-widget-icon"
                >
                  <IconSeparator />
                  Divider
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Html)}
                  data-test-id="html-widget-icon"
                >
                  <IconCode stroke={2} />
                  HTML
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Section)}
                  data-test-id="section-widget-icon"
                >
                  <IconSection />
                  Section
                </IconWrapper>

                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.UploadWidget)}
                  data-test-id="upload-widget-icon"
                >
                  <IconPhotoPlus />
                  Upload a widget
                </IconWrapper>
              </IconGroupWrapper>
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              data-test-id="widget-components-panel"
              title={<Label style={StyledPanelTitle}>Components</Label>}
              initiallyClosed
              icon={Arrow}
            >
              <IconGroupWrapper
                style={
                  !site.access.edit
                    ? { pointerEvents: 'none', opacity: '0.4' }
                    : {}
                }
              >
                <IconWrapper
                  data-test-id="accordion-widget-icon-wrapper"
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.AccordionWidget)}
                >
                  <IconListDetails />
                  Accordion
                </IconWrapper>
                <IconWrapper
                  data-test-id="application-widget-icon"
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Application)}
                >
                  <IconApps />
                  Application
                </IconWrapper>
                <IconWrapper
                  data-test-id="card-widget-icon"
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Card)}
                >
                  <IconCards />
                  Card
                </IconWrapper>
                <IconWrapper
                  data-test-id="slideshow-widget-icon"
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.SlideShow)}
                >
                  <IconSlideshow />
                  Slideshow
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Banner)}
                  data-test-id="banner-widget-icon"
                >
                  <IconFlagSpark />
                  Banner
                </IconWrapper>
                <IconWrapper
                  data-test-id="tabs-widget-icon"
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Tabs)}
                >
                  <IconNotebook />
                  Tabs
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => handleToggledNav(e)}
                  data-test-id="navbar-widget-icon"
                >
                  <IconMap2 />
                  Navbar
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.CalendarWidget)}
                  data-test-id="calendar-widget-icon"
                >
                  <IconCalendarMonth />
                  Calendar
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Search)}
                  data-test-id="search-widget-icon"
                >
                  <IconSearch />
                  Search
                </IconWrapper>
              </IconGroupWrapper>
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              data-test-id="widget-forms-panel"
              title={<Label style={StyledPanelTitle}>Forms</Label>}
              initiallyClosed
              icon={Arrow}
            >
              <IconGroupWrapper
                style={
                  !site.access.edit
                    ? { pointerEvents: 'none', opacity: '0.4' }
                    : {}
                }
              >
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Form)}
                  data-test-id="form-block-widget-icon"
                >
                  <IconTextCaption />
                  Form Block
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.TextInput)}
                  data-test-id="text-input-widget-icon"
                >
                  <IconForms />
                  Text Input
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Checkbox)}
                  data-test-id="checkbox-widget-icon"
                >
                  <IconCheckbox />
                  Checkbox
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.RadioButton)}
                  data-test-id="radio-widget-icon"
                >
                  <IconGradienter />
                  Radio
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.Dropdown)}
                  data-test-id="dropdown-widget-icon"
                >
                  <IconSelect />
                  Dropdown
                </IconWrapper>
                <IconWrapper
                  draggable={true}
                  onDragEnd={e => onDragItem(e, widgetTypes.FileUploader)}
                  data-test-id="file-uploader-widget-icon"
                >
                  <IconFileUpload />
                  File Uploader
                </IconWrapper>
              </IconGroupWrapper>
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              data-test-id="my-widgets"
              title={
                <Label style={StyledPanelTitle}>
                  My Widgets ({parentWidgets.length})
                </Label>
              }
              initiallyClosed
              icon={Arrow}
            >
              {parentWidgets.length === 0 && (
                <StyledWidgetMessage>
                  You have no custom widgets saved. Create one by right-clicking
                  on an existing widget and selecting "Save to My Widgets".
                </StyledWidgetMessage>
              )}
              {parentWidgets.map(item => {
                return (
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e =>
                      onDragItem(
                        e,
                        props.site.customWidgets.find(
                          widget => widget.widgetId === item
                        )?.widgetConfig.type,
                        props.site.customWidgets.find(
                          widget => widget.widgetId === item
                        )?.widgetConfig
                      )
                    }
                  >
                    <CustomWidgetPanelWrapper>
                      <CustomWidgetPanelInnerWrapper>
                        <Label
                          style={{
                            color: 'black',
                            fontSize: '13.5px',
                            marginLeft: '20px',
                          }}
                        >
                          {widgetNames.find(widget => widget.id === item)?.name}
                        </Label>
                        <Icon
                          data-test-id="settings-icon"
                          icon="MoreVertical"
                          height={18}
                          width={15}
                          style={{ marginLeft: '33px' }}
                          onClick={e => handleSettingsClick(e, item)}
                        ></Icon>
                      </CustomWidgetPanelInnerWrapper>
                      <div
                        style={{
                          fontSize: '12px',
                          paddingLeft: '9px',
                          paddingRight: '12px',
                          marginTop: '5px',
                          color: 'gray',
                        }}
                      >
                        {
                          widgetNames.find(widget => widget.id === item)
                            ?.description
                        }
                      </div>
                      <StyledPopover
                        id={widgetsId}
                        open={widgetOpen}
                        anchorEl={settingsAnchorEl}
                        onClose={handleClose}
                        anchorReference="anchorEl"
                        anchorPosition={{ top: 0, left: 350 }}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        data-test-id="my-widgets-popover"
                        PaperProps={{
                          style: {
                            left: '350px !important',
                          },
                        }}
                      >
                        {!openEditWidget && (
                          <StyledPage
                            style={StyledNewPage}
                            onClick={handleInputClick}
                            data-test-id="settings-page"
                          >
                            <StyledSettingsCard data-test-id="advanced-settings-card">
                              <StyledSettingName
                                data-test-id="edit-page-panel"
                                onClick={() => {
                                  setOpenEditWidgetFlag(true);
                                }}
                              >
                                <Icon
                                  icon="Edit"
                                  width={15}
                                  style={iconStyle}
                                ></Icon>
                                <StyledTextName>Edit Widget</StyledTextName>
                              </StyledSettingName>
                              <StyledSettingName
                                data-test-id="delete-page"
                                onClick={() => {
                                  handleDeleteClick(activeId);
                                }}
                              >
                                <Icon
                                  icon="Delete"
                                  width={15}
                                  style={iconStyle}
                                ></Icon>
                                <StyledTextName>
                                  Remove from My Widgets
                                </StyledTextName>
                              </StyledSettingName>
                            </StyledSettingsCard>
                          </StyledPage>
                        )}
                        {openEditWidget && (
                          <StyledPage
                            style={StyledNewPage}
                            onClick={handleInputClick}
                          >
                            <StyledCard
                              onClick={handleInputClick}
                              data-test-id="advanced-styledcard"
                            >
                              <Card
                                header={
                                  <Label style={StyledTitle}>Edit Widget</Label>
                                }
                                data-test-id="advanced-card"
                              >
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      marginTop: '20px',
                                    }}
                                  >
                                    <Label
                                      style={{
                                        color: 'black',
                                        fontSize: '13px',
                                        fontFamily: 'OptumSans',
                                        fontWeight: 'normal',
                                      }}
                                    >
                                      Name
                                    </Label>
                                    <StyledTextInput
                                      value={
                                        widgetNames.find(
                                          widget => widget.id === activeId
                                        )?.name
                                      }
                                      onChange={e => {
                                        handleItemNameChange(e, activeId);
                                      }}
                                      data-test-id="widget-name-input"
                                      style={{
                                        marginLeft: '30px',
                                        padding: '0.3rem',
                                        marginTop: '-1px',
                                      }}
                                    />
                                  </div>
                                  <Label
                                    style={{
                                      color: 'black',
                                      fontSize: '13px',
                                      fontFamily: 'OptumSans',
                                      fontWeight: 'normal',
                                      marginTop: '25px',
                                    }}
                                  >
                                    Description
                                  </Label>
                                  <TextArea
                                    value={handleItemDescriptionValue(activeId)}
                                    onChange={e => {
                                      handleItemDescriptionChange(e, activeId);
                                    }}
                                    data-test-id="widget-name-input"
                                    style={{
                                      padding: '0.3rem',
                                      marginTop: '5px',
                                      height: '100px',
                                      whiteSpace: 'no-wrap',
                                      width: '100%',
                                      fontFamily: 'OptumSans',
                                      fontSize: '14px',
                                      display: 'flex',
                                      color: 'black',
                                    }}
                                  />
                                  <ButtonWrapper>
                                    <Button
                                      onPress={handleSaveCustomWidgets}
                                      data-test-id="advanced-save-button"
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      variant="tertiary"
                                      onClick={() => {
                                        setOpenEditWidgetFlag(false);
                                        reset();
                                      }}
                                      block
                                      className={'mt-base'}
                                      data-test-id="advanced-popover-cancel-button"
                                    >
                                      Cancel
                                    </Button>
                                  </ButtonWrapper>
                                </>
                              </Card>
                            </StyledCard>
                          </StyledPage>
                        )}
                      </StyledPopover>
                    </CustomWidgetPanelWrapper>
                  </IconWrapper>
                );
              })}
            </Panel>
          </StyledPanel>
        </Group>
      </div>
    </StyledMenuItem>
  );
};
