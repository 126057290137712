import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import { fetchSearchResults } from 'shared/state/ducks/sites';
import SearchResults from './SearchResults';

interface SearchWidgetContainerProps {
  site: Site;
}
const mapStateToProps = (state: RootState) => ({
  searchResults: state.searchResults,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: SearchWidgetContainerProps
) => ({
  fetchSearchResults: (query: string) =>
    dispatch(fetchSearchResults(query, ownProps.site)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
