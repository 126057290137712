import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';
import styled from 'styled-components';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowProps,
} from '@material-ui/core';
import {
  fetchTemplateById,
  PageTemplate,
  selectIsEditingTemplate,
  selectSelectedPageTemplate,
  setIsEditingTemplate,
  setSelectedPageTemplate,
  updatePageTemplate,
} from 'shared/state/ducks/pageTemplates';
import { RootState } from '../../../shared/state';
import { selectUserTenancy } from '../../../shared/state/ducks/tenancies';
import { setSiteDataForTemplateEditing } from '../../../shared/state/ducks/sites';
import { PageTemplateErrorMessages } from '../../../shared/api/pageTemplate';
import Loading from 'shared/components/Loading';
import PageTemplatesTableHeader from './PageTemplatesHeader';
import PageTemplateTableRow from './PageTemplateTableRow';
import NoPageTemplatesMessage from './NoPageTemplates';
import Message from '../../../shared/components/Message';
import { DeleteTemplateDialog, TemplateDialog } from './TemplateDialogs';
import { pageSkeleton, siteSkeleton, TEMPLATE_STATUS } from './constants';

const StyledHeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    border: 1px solid #d3d3d3;
    padding: 8px;
    text-align: left;
    font-weight: bold;
    font-family: OptumSans;
    color: #002677;
    font-size: 25px;
  }
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 450px;
  justify-content: center;
  padding: 16px;
`;

const StyledTable = styled(Table)`
  border: 1px solid #d3d3d3;
  border-collapse: collapse;
  width: 98%;
`;

const StyledTableRow = styled(TableRow)`
  border: 1px solid #d3d3d3;
`;

const StyledTableCell = styled(TableCell)`
  border: 1px solid #d3d3d3;
  padding: 8px;
  text-align: center;
`;

type PageTemplateListTableProps = {
  error: string;
  pageTemplates: PageTemplate[];
  pending: boolean;
};

export default function PageTemplateList(props: PageTemplateListTableProps) {
  const { error, pageTemplates = [], pending } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const selectedTemplate = useSelector((state: RootState) =>
    selectSelectedPageTemplate()(state)
  );
  const isEditingPageTemplate = useSelector((state: RootState) =>
    selectIsEditingTemplate()(state)
  );
  const userTenancy = useSelector((state: RootState) =>
    selectUserTenancy()(state)
  );

  const [templateDialogOpen, setTemplateDialogOpen] = React.useState(false);
  const [
    deleteTemplateDialogOpen,
    setDeleteTemplateDialogOpen,
  ] = React.useState(false);

  const pageTemplateCount = pageTemplates.length;

  const onCreateTemplateClick = () => {
    if (selectedTemplate) {
      dispatch(setSelectedPageTemplate(null));
    }
    setTemplateDialogOpen(true);
  };

  const onEditPageTemplateClick = (template: PageTemplate) => {
    dispatch(fetchTemplateById(template.id));
    dispatch(setIsEditingTemplate(true));
  };

  const onPublishTemplateClick = (template: PageTemplate) => {
    const status =
      template.status === TEMPLATE_STATUS.PUBLISHED
        ? TEMPLATE_STATUS.DRAFT
        : TEMPLATE_STATUS.PUBLISHED;
    dispatch(
      updatePageTemplate({
        ...template,
        status,
      })
    );
  };

  useEffect(() => {
    if (selectedTemplate && !isEditingPageTemplate) {
      dispatch(setSelectedPageTemplate(null));
    }
  }, []);

  useEffect(() => {
    if (selectedTemplate?.layoutDefinitions && isEditingPageTemplate) {
      // To edit a template in the drag and drop editor we need to set up a temporary site and page in Redux.
      // Set the layoutDefinitions to the page we will insert into redux state.
      // Set a fake site in redux with pageIds matching the page with the template layoutDefinitions.
      // Redirect to the editor using our template id to build the url.
      const pages = [
        {
          ...pageSkeleton,
          current: {
            ...pageSkeleton.current,
            content: JSON.parse(selectedTemplate.layoutDefinitions),
          },
        },
      ];
      dispatch(setSiteDataForTemplateEditing(siteSkeleton, pages));
      history.push(`/page-templates/${selectedTemplate.id}/`);
    }
  }, [selectedTemplate, isEditingPageTemplate]);

  const data = pageTemplates.map(pageTemplate => {
    return {
      header: 'Your page templates with count zero',
      content: (
        <PageTemplateTableRow
          pageTemplate={pageTemplate}
          onDeleteTemplateClick={setDeleteTemplateDialogOpen}
          onDuplicateTemplateClick={setTemplateDialogOpen}
          onEditDetailsClick={setTemplateDialogOpen}
          onEditPageTemplateClick={onEditPageTemplateClick}
          onPublishTemplateClick={onPublishTemplateClick}
        />
      ),
    };
  });

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <PageTemplatesTableHeader
            templateCount={pageTemplateCount}
            onCreateTemplateClick={onCreateTemplateClick}
          />
        ),
        accessor: 'content',
        Cell: ({ cell: { value } }) => value,
      },
    ],
    [pageTemplateCount]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });
  // NOTE: Paging is done in redux state and API calls
  // This provides server-side paging which does not
  // require all templates to be downloaded to the client

  if (isEditingPageTemplate) {
    return null;
  }

  return (
    <>
      {templateDialogOpen && (
        <TemplateDialog
          templateDialogOpen={templateDialogOpen}
          setTemplateDialogOpen={setTemplateDialogOpen}
        />
      )}
      {deleteTemplateDialogOpen && (
        <DeleteTemplateDialog
          deleteTemplateDialogOpen={deleteTemplateDialogOpen}
          setDeleteTemplateDialogOpen={setDeleteTemplateDialogOpen}
        />
      )}
      {pending || !userTenancy ? (
        <Loading message="Loading" />
      ) : (
        <StyledTable
          {...getTableProps()}
          data-testid="page-template-list-table"
        >
          <TableHead>
            {headerGroups.map(
              (headerGroup: {
                getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                  TableRowProps;
                headers: any[];
              }) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <StyledHeaderCell {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </StyledHeaderCell>
                  ))}
                </TableRow>
              )
            )}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row: {
                getRowProps: () => JSX.IntrinsicAttributes & TableRowProps;
                cells: any[];
              }) => {
                prepareRow(row);
                return (
                  <StyledTableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <StyledTableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              }
            )}
          </TableBody>
        </StyledTable>
      )}
      {!pending && pageTemplateCount === 0 && !error && userTenancy && (
        <NoPageTemplatesMessage />
      )}
      {error === PageTemplateErrorMessages.FetchTemplatesFailed && (
        <ErrorWrapper>
          <Message error>{error}</Message>
        </ErrorWrapper>
      )}
    </>
  );
}
