import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Panel,
  Label,
  FormControl,
  Checkbox,
  Button,
  Card,
  TextInput,
  RadioButton,
  Dropdown,
  IDropdownItem,
  Select,
} from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import { Config } from './types';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import {
  BackgroundData,
  Background,
  ColorButtonWrapper,
} from 'builder/components/WidgetConfigurations/Background/Background';
import { StyledLabel } from 'builder/components/WidgetConfigurations/TextInput/TextInputPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from 'builder/components/WidgetSizeLocation';
import { changeWidgetVisible } from 'shared/state/ducks/sites';
import { useDispatch } from 'react-redux';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import {
  ButtonWrapperFolder,
  StyledAddItemButton,
  StyledAlternativeButton,
  StyledSettingName,
  StyledSettingsCard,
  StyledTextName,
  StyledTitle,
  dividerStyles,
  iconStyle,
  labelStyles,
  settingsDividerStyles,
  ButtonWrapper,
  StyledIcon,
} from 'builder/scenes/SiteBuilder/PagesPanel/PagesPanel';
import { Icon } from 'link-ui-react';
import { AddIcon } from '@uitk/react-icons';
import { Divider, Popover } from '@material-ui/core';
import {
  UncontrolledTreeEnvironment,
  Tree,
  StaticTreeDataProvider,
} from 'react-complex-tree';
import 'react-complex-tree/lib/style-modern.css';
import { useEventSubscriber } from 'use-event-emitter-hook';
import { navRenderers } from 'builder/scenes/SiteBuilder/navRenderers';
import { NavLink } from '../shared/types';
import S3Image from 'builder/components/S3Image';
import LogoPreview from 'builder/components/WidgetConfigurations/Background/LogoPreview';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import { ColorLabelStyles } from 'builder/components/WidgetConfigurations/Dropdown/DropdownPanel';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import {
  IDP_VALUES_TO_USE,
  lineDirectionOptions,
  widgetTypes,
} from 'builder/util/constants';
import EnableFullScreenWidth from 'builder/components/WidgetConfigurations/EnableFullScreenWidth';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import { StyledPanel, StyledPanelTitle } from '../Text/TextWidgetControl';
import { MainLabelStyles } from 'builder/components/WidgetConfigurations/Typography/Typography';
import { NavigationTypography } from './NavigationTypography';
import { getWidget } from '..';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import { toast } from 'react-toastify';

export const DividerLabelStyle = {
  color: '#1d1929',
  fontSize: '14px',
  fontFamily: 'OptumSans',
  fontWeight: '500',
};

const StyledTextInput = styled(TextInput)`
  padding: 0.5rem;
  color: #4b4d4f;
  font-family: OptumSans, sans-serif;
  font-size: 12pt;
`;

const OrientationSelect = styled(Select)`
  border-radius: 0.375rem;
  margin-left: 1em;
  #orientation-select {
    width: 10em;
    font-family: OptumSans, sans-serif;
    line-height: 1.25rem;
    box-shadow: 0 0 0 1px ${theme.colors.aux.black};
    font-size: 14px;
    margin-right: -0.5em;
  }
`;

const LineDirectionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
`;

const Form = <StyledIcon icon="Form" width={15} />;

const StyledDiv = styled.div`
  display: ruby;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
const StyledPage = styled.div``;

const StyledCheckBox = styled(Checkbox)`
  font-family: OptumSans, sans-serif;
  font-size: 11pt;
`;

const StyledRadioButton = styled(RadioButton)`
  font-family: OptumSans, sans-serif;
  color: #000000;
  font-size: 12pt;
  font-weight: 700;
  width: 100%;
  display: block;
`;

const ColorButtonAndLabelWrapper = styled.div`
  display: inline-flex;
`;

const CheckboxWrapper = styled.div`
  margin-top: 0.5rem;
  align-items: center;
  display: flex;
`;

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
  & > label {
    margin: 0;
  }
`;

const StyledCard = styled.div`
  & div[class^='Cardcomponent__Content'] {
    margin-top: -5px;
    display: grid;
  }
  & .uitk-card {
    width: inherit;
  }
`;

const StyledPopover = styled(Popover)`
  margin-top: -14px;
  .MuiPaper-root {
    overflow-x: unset;
    overflow-y: unset;
  }
`;

const StyledEditPopover = styled(Popover)`
  margin-top: -14px;
  .MuiPaper-root {
    overflow-x: unset;
    overflow-y: unset;
    margin-left: -150px;
  }
`;

const StyledFormControl = styled(FormControl)`
  border: none;
`;

const StyledHeader = styled.div`
  padding-bottom: 0.5rem;
  font-weight: 700;
  font-family: OptumSans, sans-serif;
`;

const SecondaryFormControl = styled(FormControl)`
  width: 15em;
  border: none;
  .IliJP
    .RadioButtoncomponent__Input-fhy7qo-1:checked
    + .RadioButtoncomponent__Label-fhy7qo-2:after {
    top: 0.4rem;
  }
  .RadioButtoncomponent__Input-fhy7qo-1
    + .RadioButtoncomponent__Label-fhy7qo-2:before {
    display: inline-flex;
  }
`;

const StyledDropdown = styled(Dropdown)`
  #controlled-select {
    padding: 0.4rem 0.5rem;
    width: 11.65em;
  }
  #controlled-page-select {
    padding: 0.4rem 0.5rem;
  }
  & > button > span {
    font-family: OptumSans, sans-serif;
  }
  & > button + ul {
    font-family: OptumSans, sans-serif;
  }
  & > div > button + div {
    font-family: OptumSans, sans-serif;
  }
  & > div > button + div > fieldset {
    font-family: OptumSans, sans-serif;
  }
  & > div > button > span {
    font-family: OptumSans, sans-serif;
  }
`;

const ButtonWrapper1 = styled.div`
  display: flex;
  .uitk-button {
    margin-right: 7px;
    margin-top: 2rem;
    font-family: OptumSans, sans-serif;
  }
  .mt-base {
    width: auto;
  }
`;

const SizeLocationPanel = styled(Panel)`
  > div {
    padding: 1rem;
  }
`;

const StyledSubtext = styled.div`
  font-size: 11pt;
  font-weight: 400;
  padding: 0.5rem 0;
`;

const SecondaryLabelStyle = {
  color: '#1d1929',
  'font-size': '14px',
  'font-family': 'OptumSans',
  'font-weight': '700',
  'padding-right': '.5rem',
};

const TertiaryLabelStyles = {
  color: '#333333',
  'font-size': '0.875em',
  'font-family': 'OptumSans',
  'line-height': '1.25em',
  'padding-top': '7px',
};

const S3ImageControlsWrapper = styled.div`
  font-family: OptumSans, sans-serif;
  font-size: 9pt;
  padding-top: 4px;
  display: flex;
  & .MUIButtonBase-root {
    font-family: OptumSans, sans-serif;
  }
  & > div > div > label > button {
    font-family: OptumSans, sans-serif;
  }
`;

const S3ImagePreviewWrapper = styled.div`
  padding-top: 10px;
`;

const StyledNewLink = {
  width: 'inherit',
};

const StyledForm = styled(FormControl)`
  width: fit-content;
`;
const StyledRequiredCheckbox = styled(Checkbox)`
  padding-top: 5px;
`;

const StyledInnerDiv = styled.div`
  display: flex;
  align-items: center;
`;

const WebAddressWrapper = styled.div`
  display: grid;
`;

export function sortItems(items, treeOrder: number[]) {
  items.sort((a, b) => {
    const positionA = treeOrder.indexOf(a.id);
    const positionB = treeOrder.indexOf(b.id);
    const assignedPositionA =
      positionA === -1 ? Number.MAX_SAFE_INTEGER : positionA;
    const assignedPositionB =
      positionB === -1 ? Number.MAX_SAFE_INTEGER : positionB;
    return assignedPositionA - assignedPositionB;
  });
}

const NavWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const dispatch = useDispatch();
  const {
    value,
    onChange,
    widgetState,
    pages,
    addChild,
    removeWidget,
    isEditingTemplate,
    currentPage: { layoutLocked },
  } = props;

  const typeDropdown: IDropdownItem[] = [
    { id: 'none', label: 'None', value: 'none' },
    { id: 'page', label: 'Page', value: 'page' },
    { id: 'url', label: 'URL', value: 'url' },
  ];
  const pageDropdown: IDropdownItem[] = pages
    ?.filter(page => page.current.slug !== 'defaultContentPage')
    .map(page => ({
      id: page.id,
      label: page.current.title,
      value: page.id,
    }));

  const [idpDropdown, setIdpDropdown] = useState<IDropdownItem[]>([]);

  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const createLinkOpen = Boolean(anchorEl);
  const id = createLinkOpen ? 'simple-popover' : undefined;
  const [itemType, setItemType] = useState('');
  const [checked, setChecked] = useState('');
  const [_editTreeOpen, setEditTreeOpen] = useState(false);
  const [isEditingSection, setIsEditingSection] = useState(false);
  const [isEditingLink, setIsEditingLink] = useState(false);
  const [changed, setChanged] = useState(0);
  const [
    editSectionAnchorEl,
    setEditSectionAnchorEl,
  ] = useState<SVGElement | null>(null);
  const editSectionOpen = Boolean(editSectionAnchorEl);
  const editItemId = editSectionOpen ? 'edit-link-popover' : undefined;
  const [openEditLink, setEditLinkFlag] = useState(false);
  const [selectedType, setSelectedType] = useState<IDropdownItem>(null);
  const [currentItem, setCurrentItem] = useState<NavLink>({
    id: null,
    displayText: '',
    url: '',
    icon: { alt: '', url: '' },
    visible: true,
    selectedIdps: [],
    type: 'none',
    pageSelected: null,
    isSection: false,
    parentSectionId: null,
    childOrder: [],
    required: false,
  });
  const [menuItems, setMenuItems] = useState<NavLink[]>(
    widgetState.config.menuItems
  );
  const [pageValue, setPageValue] = useState(null);
  const [selectedIdps, setSelectedIdps] = useState<string[]>([]);
  const [formValues, setFormValues] = useState({
    newDisplayText: '',
    newIcon: { url: '', alt: '' },
    newUrl: '',
    newVisible: true,
    newIdps: [],
    newPageSelected: null,
    newType: 'none',
    newRequired: false,
  });
  const [openPopover, setOpenPopover] = useState({
    linkForm: false,
    sectionForm: false,
    optionsMenu: false,
  });

  const resetForm = () => {
    setFormValues({
      newDisplayText: '',
      newIcon: { url: '', alt: '' },
      newUrl: '',
      newVisible: true,
      newIdps: [],
      newPageSelected: null,
      newType: 'none',
      newRequired: false,
    });
    setSelectedType(typeDropdown[2]);
  };

  const resetPopovers = () => {
    setOpenPopover({
      linkForm: false,
      sectionForm: false,
      optionsMenu: false,
    });
    setAnchorEl(null);
    setEditSectionAnchorEl(null);
    setEditLinkFlag(false);
    setChecked('');
  };

  useEventSubscriber('editClick', value => {
    setEditTreeOpen(value.isEditClicked);
    setEditSectionAnchorEl(value.eventTarget);
    setIsEditingSection(value.isSection);
    setIsEditingLink(!value.isSection);
    setCurrentItem({
      id: value.itemData.id,
      displayText: value.itemData.displayText,
      url: value?.itemData?.url,
      type: value?.itemData?.type,
      pageSelected: value?.itemData?.pageSelected,
      icon: {
        alt: value?.itemData?.icon?.alt,
        url: value?.itemData?.icon?.url,
      },
      visible: value.itemData.visible,
      parentSectionId: value?.itemData?.parentSectionId,
      childOrder: value?.itemData?.childOrder,
      selectedIdps: value?.itemData?.selectedIdps,
      required: value?.itemData?.required,
    });
  });

  useEffect(() => {}, [widgetState.config.style]);

  useEffect(() => {
    setFormValues({
      newDisplayText: currentItem.displayText,
      newUrl: currentItem.url,
      newVisible: currentItem.visible,
      newIcon: { alt: currentItem.icon.alt, url: currentItem.icon.url },
      newPageSelected: currentItem.pageSelected,
      newIdps: currentItem.selectedIdps,
      newType: currentItem.type,
      newRequired: currentItem.required,
    });
    setSelectedType(
      typeDropdown[
        typeDropdown.findIndex(type => type.value === currentItem.type)
      ]
    );
    setPageValue(currentItem.pageSelected || null);
  }, [currentItem]);

  useEffect(() => {
    if (pageValue) {
      const pageIdps = pages.find(
        page => pageValue.value === page.current.pageId
      ).current.allowedIdps;
      const authEnabled = pages.find(
        page => pageValue.value === page.current.pageId
      ).current.authentication;
      if (authEnabled && !pageIdps) {
        setIdpDropdown(
          IDP_VALUES_TO_USE.map(idp => ({
            id: idp.alias,
            label: idp.displayName,
            value: idp.alias,
          })).filter(idp => idp.value !== '')
        );
      } else if (pageIdps && pageIdps.length > 0) {
        setIdpDropdown(
          pageIdps.map(idp => ({ id: idp, label: idp, value: idp }))
        );
      }
    }
  }, [pageValue]);

  const updateWidgetSizeLocation = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
      menuItems: widgetState.config.menuItems,
    });
  };

  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };

  const backgroundData = (data: BackgroundData) => {
    onChange({
      ...value,
      backgroundData: data,
    });
  };

  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };

  const updateBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };

  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };

  const handleColorEditClick = (
    e: MouseEvent<HTMLDivElement>,
    type: string
  ) => {
    if (layoutLocked) return;
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
    setItemType(type);
  };

  const handlePickerClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorChange = newColor => {
    if (pickerAnchorEl === document.querySelector('#icon-color')) {
      if (newColor.startsWith('linear') || newColor.startsWith('radial')) {
        toast('Gradient cannot be used for icon color', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        onChange({
          ...value,
          iconColor: newColor,
        });
      }
    }
    if (pickerAnchorEl === document.querySelector('#divider-color')) {
      if (newColor.startsWith('linear') || newColor.startsWith('radial')) {
        toast('Gradient cannot be used for divider color', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        onChange({
          ...value,
          dividerLineColor: newColor,
        });
      }
    }
    if (pickerAnchorEl === document.querySelector('#text-color')) {
      if (newColor.startsWith('linear') || newColor.startsWith('radial')) {
        toast('Gradient cannot be used for text color', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        onChange({
          ...value,
          textColor: newColor,
        });
      }
    }
  };

  const handleDividerCheck = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      showDividerLines: e.target.checked,
    });
  };

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    resetForm();
    resetPopovers();
  };

  const handleInputClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const setValue = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.value);
  };

  const openPanel = (e: MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenPopover({
      ...openPopover,
      linkForm: true,
    });
  };

  const closePanel = (e: MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    resetForm();
    setOpenPopover({
      ...openPopover,
      linkForm: false,
    });
  };

  const handleUrl = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedType?.value === 'url') {
      setFormValues({
        ...formValues,
        newUrl: e.target.value,
      });
    }
    if (selectedType?.value === 'none') {
      setFormValues({
        ...formValues,
        newUrl: '',
      });
    }
  };

  const handleBackgroundColor = (data: string) => {
    onChange({
      ...value,
      backgroundData: {
        ...value.backgroundData,
        color: data,
      },
    });
  };

  const handleFullScreen = (data: boolean) => {
    onChange({
      ...value,
      enableFullScreen: data,
    });
  };

  const transformedData: Record<string, any> = {
    root: {
      index: 'root',
      isFolder: true,
      children: menuItems
        .filter(
          item =>
            item.parentSectionId === undefined || item.parentSectionId === null
        )
        .map(item => item.id),
      data: 'Root item',
    },
  };

  menuItems.forEach(item => {
    transformedData[item.id] = {
      index: item.id,
      isFolder: item.isSection ? true : false,
      children: widgetState.config.menuItems
        .filter(childItem => childItem.parentSectionId === item.id)
        .map(childItem => childItem.id),
      data: item,
      canMove: true,
    };
  });

  const dataProvider = new StaticTreeDataProvider(
    transformedData,
    (item, newName) => ({
      ...item,
      data: newName,
    })
  );

  const addNewLink = () => {
    const newNavLink: NavLink = {
      id: widgetState.config.menuItems.length + 1,
      displayText: formValues.newDisplayText,
      visible: formValues.newVisible,
      url: formValues.newUrl,
      icon: formValues.newIcon,
      type: selectedType?.value,
      isSection: false,
      pageSelected: pageValue,
      required: formValues.newRequired,
    };
    const widget = getWidget('Section', true);
    let defaultConfig = {
      ...widget.initialConfig,
      xLocation: widgetState.config.xLocation + 120,
      yLocation: widgetState.config.yLocation + 10,
      width: 300,
      height: 250,
      visible: false,
    };
    const updatedMenuItems = [...widgetState.config.menuItems, newNavLink];
    sortItems(updatedMenuItems, widgetState.config.treeOrder);
    onChange({
      ...value,
      menuItems: updatedMenuItems,
    });
    setMenuItems(updatedMenuItems);
    setChanged(newNavLink.id);
    resetForm();
    resetPopovers();
    addChild(
      widgetTypes.Section,
      defaultConfig,
      0,
      'section_menu_' + newNavLink.id,
      widgetState.id,
      -1
    );
  };

  const editLink = () => {
    const newArray = widgetState.config.menuItems.filter(
      item => currentItem.id !== item.id
    );
    const editedLink: NavLink = {
      id: currentItem.id,
      displayText: formValues.newDisplayText,
      visible: formValues.newVisible,
      url: formValues.newUrl,
      icon: formValues.newIcon,
      type: formValues.newType,
      isSection: false,
      pageSelected: pageValue,
      parentSectionId: currentItem.parentSectionId,
      required: formValues.newRequired,
    };
    const updatedMenuItems = [...newArray, editedLink];
    sortItems(updatedMenuItems, widgetState.config.treeOrder);
    onChange({
      ...value,
      menuItems: updatedMenuItems,
    });
    setMenuItems(updatedMenuItems);
    setChanged(editedLink.id);
    resetForm();
    resetPopovers();
  };

  const addNewSection = () => {
    const newSection: NavLink = {
      id: widgetState.config.menuItems.length + 1,
      displayText: formValues.newDisplayText,
      visible: formValues.newVisible,
      isSection: true,
      icon: formValues.newIcon,
      childOrder: [],
      required: formValues.newRequired,
    };
    const updatedMenuItems = [...widgetState.config.menuItems, newSection];
    sortItems(updatedMenuItems, widgetState.config.treeOrder);
    onChange({
      ...value,
      menuItems: updatedMenuItems,
    });
    setMenuItems(updatedMenuItems);
    setChanged(newSection.id);
    resetForm();
    resetPopovers();
  };

  const editSection = () => {
    const newArray = widgetState.config.menuItems.filter(
      item => currentItem.id !== item.id
    );
    const editedLink: NavLink = {
      id: currentItem.id,
      displayText: formValues.newDisplayText,
      visible: formValues.newVisible,
      isSection: true,
      icon: formValues.newIcon,
      childOrder: currentItem.childOrder,
      required: formValues.newRequired,
    };
    const updatedSectionItems = [...newArray, editedLink];
    sortItems(updatedSectionItems, widgetState.config.treeOrder);
    onChange({
      ...value,
      menuItems: updatedSectionItems,
    });
    setMenuItems(updatedSectionItems);
    setChanged(editedLink.id);
    resetForm();
    resetPopovers();
  };
  const handleDropRequiredItem = (items, target) => {
    if (isEditingTemplate) {
      return true;
    }
    const sourceIndex = items[0].index;
    const destinationIndex = target.linearIndex;

    // Get indexes of required items
    const requiredIndexes = props.widgetState.config.menuItems.reduce(
      (indexes, item, index) => {
        if (item.required) indexes.push(index);
        return indexes;
      },
      []
    );

    // Disable reordering if it affects any required item's position
    if (
      requiredIndexes.some(
        index =>
          (sourceIndex < index && destinationIndex >= index) ||
          (sourceIndex > index && destinationIndex <= index) ||
          sourceIndex === index ||
          destinationIndex === index
      )
    ) {
      return false;
    }

    return true; // Allow reordering if it doesn't affect required items
  };
  const handleDrop = (items, target) => {
    if (!items[0].isFolder) {
      const isTargetItem = target.targetType === 'item';
      const isTargetFolder =
        target.targetType === 'between-items' && target.depth === 1;
      const childId = items[0].data.id;
      const childIndex = props.widgetState.config.menuItems.findIndex(
        item => item.id === childId
      );
      const newParentId = isTargetItem
        ? target.targetItem
        : isTargetFolder
        ? target.parentItem
        : null;
      const didParentIdChange = items[0].data.parentSectionId !== newParentId;
      const editedChild: NavLink = {
        ...props.widgetState.config.menuItems[childIndex],
        parentSectionId: newParentId,
      };
      if (didParentIdChange) {
        const parentIndex = props.widgetState.config.menuItems.findIndex(
          item => item.id === newParentId
        );
        const data = Object.values(dataProvider)[1];
        const treeOrder = data.items['root'].children;
        const editedParent = {
          ...props.widgetState.config.menuItems[parentIndex],
          childOrder: data.items[newParentId]?.children,
        };
        const updatedArray = props.widgetState.config.menuItems.filter(
          item => item.id !== editedChild.id && item.id !== editedParent.id
        );
        const updatedItems = [...updatedArray, editedChild, editedParent];
        sortItems(updatedItems, treeOrder);
        onChange({
          ...value,
          menuItems: updatedItems,
          treeOrder: treeOrder,
        });
      } else {
        const updatedArray = props.widgetState.config.menuItems.filter(
          item => item.id !== editedChild.id && item.id
        );
        const data = Object.values(dataProvider)[1];
        const updatedItems = [...updatedArray, editedChild];
        const treeOrder = data.items['root'].children;
        sortItems(updatedItems, treeOrder);
        onChange({
          ...value,
          menuItems: updatedItems,
          treeOrder: treeOrder,
        });
      }
    } else {
      const data = Object.values(dataProvider)[1];
      const treeOrder = data.items['root'].children;
      const sortedMenuItems = props.widgetState.config.menuItems;
      sortItems(sortedMenuItems, treeOrder);
      onChange({
        ...value,
        menuItems: sortedMenuItems,
        treeOrder: treeOrder,
      });
    }
  };

  const handleVisibleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      newVisible: e.target.checked,
    });
  };

  const handleRequiredCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      newRequired: e.target.checked,
    });
  };

  const handleDelete = () => {
    const updatedArray = widgetState.config.menuItems.filter(
      item => item.id !== currentItem.id
    );
    onChange({
      ...value,
      menuItems: updatedArray,
    });
    setMenuItems(updatedArray);
    resetPopovers();
    setChanged(currentItem.id);
  };

  function handleIconChange(icon: Image) {
    setFormValues({
      ...formValues,
      newIcon: icon,
    });
  }

  const handleStyleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    onChange({
      ...value,
      height: e.target.value === 'Vertical' ? 300 : 90,
      style: e.target.value,
      enableFullScreen:
        e.target.value === 'Vertical' ? false : value.enableFullScreen,
    });
    if (e.target.value === 'Vertical') {
      menuItems.forEach(item => {
        const widget = getWidget('Section', true);
        let defaultConfig = {
          ...widget.initialConfig,
          xLocation: widgetState.config.xLocation + 120,
          yLocation: widgetState.config.yLocation + 10,
          width: 300,
          height: 250,
          visible: false,
        };
        {
          addChild(
            widgetTypes.Section,
            defaultConfig,
            0,
            'section_menu_' + item.id,
            widgetState.id,
            -1
          );
        }
        dispatch(
          changeWidgetVisible(
            props.site.id,
            props.currentPage.pageId,
            props.widgetState.id,
            'section_menu_' + menuItems.at(0).id,
            true
          )
        );
      });
    }
    if (e.target.value === 'Horizontal') {
      menuItems.forEach(item => {
        removeWidget('section_menu_' + item.id);
        const childWidgets =
          props.currentPage.content['section_menu_' + item.id].children;
        if (childWidgets.length > 0) {
          childWidgets.forEach(childId => removeWidget(childId));
        }
      });
    }
  };

  const handlePageChange = (e: IDropdownItem) => {
    setPageValue(e);
    const pageSlug = pages.find(page => page.current.title.includes(e.label))
      .current.slug;
    setFormValues({
      ...formValues,
      newType: 'page',
      newPageSelected: e,
      newUrl: `https://${props.site.domain}/${pageSlug ? pageSlug : 'home'}`,
    });
  };

  const handleTextColor = (data: string) => {
    if (data.startsWith('linear') || data.startsWith('radial')) {
      toast('Gradient cannot be used for text color', {
        type: 'warning',
        theme: 'colored',
      });
    } else {
      onChange({
        ...value,
        textColor: data,
      });
    }
  };

  const handleHoverTextColor = (data: string) => {
    if (data.startsWith('linear') || data.startsWith('radial')) {
      toast('Gradient cannot be used for text color', {
        type: 'warning',
        theme: 'colored',
      });
    } else {
      onChange({
        ...value,
        hoverTextColor: data,
      });
    }
  };

  return (
    <Panel.Group data-test-id="nav-widget-control">
      <StyledPanel>
        <Panel
          title={<Label style={StyledPanelTitle}>General</Label>}
          icon={Arrow}
          data-test-id="general-panel"
        >
          <LineDirectionWrapper>
            <Label style={DividerLabelStyle}>Style</Label>
            <FormControl
              data-test-id="orientation-select-dropdown"
              disabled={layoutLocked}
              id="orientation-select"
            >
              <OrientationSelect
                name="navorientation"
                id="nav-orientation-select"
                data-test-id="nav-orientation"
                value={widgetState.config.style}
                onChange={handleStyleChange}
              >
                {lineDirectionOptions.map(d => {
                  return (
                    <option value={d} key={d}>
                      {d}
                    </option>
                  );
                })}
              </OrientationSelect>
            </FormControl>
          </LineDirectionWrapper>
          <ColorButtonAndLabelWrapper>
            <StyledLabel style={ColorLabelStyles}>
              Dropdown icon color
            </StyledLabel>
            <ColorButtonWrapper
              data-test-id="icon-color"
              id="icon-color"
              onClick={e => handleColorEditClick(e, 'icon')}
            >
              <ColorButton
                color={widgetState.config.iconColor}
                disabled={layoutLocked}
              />
            </ColorButtonWrapper>
          </ColorButtonAndLabelWrapper>
          <CheckboxWrapper>
            <MainLabelStyles>Include divider lines</MainLabelStyles>
            <StyledFormControl id="button-checkbox" disabled={layoutLocked}>
              <StyledCheckbox
                data-test-id="divider-check"
                checked={widgetState.config.showDividerLines}
                onChange={handleDividerCheck}
              />
            </StyledFormControl>
            {widgetState.config.showDividerLines && (
              <ColorButtonWrapper
                data-test-id="divider-color"
                id="divider-color"
                onClick={e => handleColorEditClick(e, 'divider')}
              >
                <ColorButton
                  color={widgetState.config.dividerLineColor}
                  disabled={layoutLocked}
                />
              </ColorButtonWrapper>
            )}
            <StyledPickerPopover
              data-test-id="widget-color-picker-popover"
              id={pickerId}
              open={pickerOpen}
              onClick={handlePickerClick}
              onClose={handlePickerClose}
              anchorEl={pickerAnchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
            >
              <StyledPickerWrapper>
                <StyledPickerHeader data-test-id="styled-picker-header">
                  Color Selector
                </StyledPickerHeader>
                <StyledClose
                  data-test-id="close-icon"
                  onClick={handlePickerClose}
                />
              </StyledPickerWrapper>
              <GradientColorPicker
                value={
                  itemType === 'icon'
                    ? widgetState.config.iconColor
                    : widgetState.config.dividerLineColor
                }
                site={props.site}
                handleColorChange={handleColorChange}
                onChange={handleColorChange}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                anchorEl={pickerAnchorEl}
              />
            </StyledPickerPopover>
          </CheckboxWrapper>
          {widgetState.config.style === 'Horizontal' && (
            <EnableFullScreenWidth
              disabled={layoutLocked}
              handleEnableFullScreen={handleFullScreen}
              enableFullScreenWidth={widgetState.config.enableFullScreen}
            />
          )}
          <div style={{ padding: '1rem 0' }}>
            <StyledHeader>Navigation Items</StyledHeader>
            <UncontrolledTreeEnvironment
              data-test-id="nav-tree"
              key={changed}
              viewState={{}}
              dataProvider={dataProvider}
              getItemTitle={item => item.data.displayText}
              canDropAt={handleDropRequiredItem}
              onDrop={handleDrop}
              canDragAndDrop={true}
              canReorderItems={true}
              canDropOnFolder={true}
              canDrag={items =>
                isEditingTemplate
                  ? true
                  : items.every(item => !item.data.required)
              }
              {...navRenderers}
            >
              <Tree treeId="tree-1" rootItem="root" />
            </UncontrolledTreeEnvironment>
          </div>
          <Divider style={dividerStyles} />
          <StyledEditPopover
            id={editItemId}
            open={editSectionOpen}
            anchorEl={editSectionAnchorEl}
            onClose={handleClose}
            anchorReference="anchorEl"
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            data-test-id="edit-link-popover"
          >
            {!openEditLink && (
              <>
                <StyledPage style={StyledNewLink} onClick={handleInputClick}>
                  <StyledSettingsCard data-test-id="edit-link-card">
                    <StyledSettingName
                      data-test-id="edit-link-name"
                      onClick={() => {
                        setEditLinkFlag(true);
                      }}
                      disabled={!isEditingTemplate && currentItem.required}
                    >
                      <Icon icon="Edit" width={15} style={iconStyle}></Icon>
                      <StyledTextName>
                        Edit {currentItem.displayText}
                      </StyledTextName>
                    </StyledSettingName>

                    <Divider style={settingsDividerStyles}></Divider>
                    <StyledSettingName
                      data-test-id="delete-link"
                      onClick={handleDelete}
                      disabled={!isEditingTemplate && currentItem.required}
                    >
                      <Icon icon="Delete" width={15} style={iconStyle}></Icon>
                      <StyledTextName>
                        Delete {currentItem.displayText}
                      </StyledTextName>
                    </StyledSettingName>
                  </StyledSettingsCard>
                </StyledPage>
              </>
            )}
            {openEditLink && isEditingLink && (
              <StyledPage style={StyledNewLink} onClick={handleInputClick}>
                <StyledCard
                  onClick={handleInputClick}
                  data-test-id="editlink-styledcard"
                >
                  <Card
                    header={
                      <Label style={StyledTitle}>
                        Edit '{currentItem.displayText}'
                      </Label>
                    }
                    data-test-id="edit-link-card"
                  >
                    <StyledDiv>
                      <StyledInnerDiv>
                        <Label
                          style={SecondaryLabelStyle}
                          data-test-id="type-dropdown-label"
                        >
                          Type:
                        </Label>
                        <StyledFormControl id="controlled-select">
                          <StyledDropdown
                            type="single"
                            onChange={setSelectedType}
                            value={selectedType}
                            data-test-id="edit-type-dropdown"
                            items={typeDropdown}
                          />
                        </StyledFormControl>
                      </StyledInnerDiv>
                      <div
                        style={{
                          width: '50%',
                          display: 'inherit',
                          alignItems: 'center',
                          marginLeft: '2rem',
                        }}
                      >
                        <Label
                          style={SecondaryLabelStyle}
                          data-test-id="visible-checkbox-label"
                        >
                          Visibility:
                        </Label>
                        <StyledForm id="visible-checkbox">
                          <StyledCheckBox
                            checked={formValues.newVisible}
                            onChange={handleVisibleCheck}
                          >
                            Visible
                          </StyledCheckBox>
                        </StyledForm>
                      </div>
                      {isEditingTemplate && (
                        <StyledForm id="required-checkbox">
                          <StyledRequiredCheckbox
                            checked={formValues.newRequired || false}
                            onChange={handleRequiredCheck}
                          >
                            Required
                          </StyledRequiredCheckbox>
                        </StyledForm>
                      )}
                    </StyledDiv>
                    <div style={{ width: '100%', display: 'inline-flex' }}>
                      <div
                        style={{
                          width: '50%',
                          padding: '0 .5rem .5rem 0',
                        }}
                      >
                        <Label
                          style={labelStyles}
                          data-test-id="display-text-input-label"
                        >
                          Display Text:
                        </Label>
                        <StyledTextInput
                          name="display-text"
                          style={{
                            padding: '0.5rem',
                            color: '#4b4d4f',
                            'font-family': 'OptumSans',
                            'font-size': '12pt',
                          }}
                          value={formValues.newDisplayText}
                          onChange={e =>
                            setFormValues({
                              ...formValues,
                              newDisplayText: e.target.value,
                            })
                          }
                          data-test-id="web-address"
                        />
                      </div>
                      <div
                        style={{
                          width: '50%',
                          padding: '0 0 .5rem 0',
                          display: 'block',
                        }}
                      >
                        <Label
                          style={labelStyles}
                          data-test-id="icon-upload-label"
                        >
                          Icon:
                        </Label>
                        <S3ImageControlsWrapper>
                          <S3Image
                            image={formValues.newIcon}
                            label="Click to Upload"
                            data-test-id="icon-image"
                            onUpload={handleIconChange}
                          />
                          <S3ImagePreviewWrapper>
                            {formValues.newIcon && (
                              <LogoPreview
                                url={formValues.newIcon.url}
                                maxWidth={50}
                                maxHeight={50}
                              />
                            )}
                          </S3ImagePreviewWrapper>
                        </S3ImageControlsWrapper>
                      </div>
                    </div>
                    {selectedType?.value === 'page' ? (
                      <>
                        <div
                          style={{
                            width: '100%',
                            padding: '0 0 .5rem 0',
                          }}
                        >
                          <Label
                            style={labelStyles}
                            data-test-id="page-dropdown-label"
                          >
                            Select a page:
                          </Label>
                          <StyledFormControl id="controlled-page-select">
                            <StyledDropdown
                              type="single"
                              onChange={handlePageChange}
                              value={pageValue}
                              data-test-id="edit-page-dropdown"
                              items={pageDropdown}
                            ></StyledDropdown>
                          </StyledFormControl>
                          {idpDropdown.length > 0 && (
                            <>
                              <Label
                                style={labelStyles}
                                data-test-id="page-dropdown-label"
                              >
                                Allowed IDPs:
                              </Label>
                              <StyledFormControl id="controlled-page-select">
                                <StyledDropdown
                                  type="multi"
                                  onChange={setSelectedIdps}
                                  value={selectedIdps}
                                  data-test-id="page-select-dropdown"
                                  items={idpDropdown}
                                />
                              </StyledFormControl>
                            </>
                          )}
                        </div>
                      </>
                    ) : selectedType?.value === 'url' ? (
                      <WebAddressWrapper
                        style={{
                          width: '30rem',
                          padding: '0 0 .5rem 0',
                        }}
                      >
                        <Label
                          style={labelStyles}
                          data-test-id="page-dropdown-label"
                        >
                          Enter a URL:
                        </Label>
                        <StyledTextInput
                          name="webaddress"
                          value={formValues.newUrl}
                          onChange={handleUrl}
                          placeholder="https://..."
                          data-test-id="web-address"
                        />
                      </WebAddressWrapper>
                    ) : (
                      ''
                    )}
                    <ButtonWrapper1>
                      <StyledAlternativeButton
                        variant="secondary"
                        onClick={() => {
                          setEditLinkFlag(false);
                        }}
                        size="s"
                        data-test-id="new-link-previous-button"
                      >
                        Previous
                      </StyledAlternativeButton>
                      <Button
                        onPress={editLink}
                        data-test-id="new-link-save-button"
                      >
                        Apply changes
                      </Button>
                      <Button
                        variant="tertiary"
                        onClick={resetPopovers}
                        className="mt-base"
                        data-test-id="advanced-popover-cancel-button"
                      >
                        Cancel
                      </Button>
                    </ButtonWrapper1>
                  </Card>
                </StyledCard>
              </StyledPage>
            )}
            {openEditLink && isEditingSection && (
              <StyledPage style={StyledNewLink} onClick={handleInputClick}>
                <StyledCard
                  onClick={handleInputClick}
                  data-test-id="edit-section-styledcard"
                >
                  <Card
                    header={
                      <Label style={StyledTitle}>
                        Edit {currentItem.displayText}
                      </Label>
                    }
                    data-test-id="edit-section-card"
                  >
                    <StyledDiv>
                      <div
                        style={{
                          width: '60%',
                          display: 'inherit',
                          padding: '.5rem .5rem .5rem 0',
                        }}
                      >
                        <Label
                          style={TertiaryLabelStyles}
                          data-test-id="edit-section-display-text-input-label"
                        >
                          Display Text:
                        </Label>
                        <StyledTextInput
                          name="display-text"
                          value={formValues.newDisplayText}
                          onChange={e =>
                            setFormValues({
                              ...formValues,
                              newDisplayText: e.target.value,
                            })
                          }
                          data-test-id="edit-section-web-address"
                        />
                      </div>
                      <div
                        style={{
                          width: '40%',
                          display: 'inherit',
                          paddingTop: '.5rem',
                        }}
                      >
                        <Label
                          style={labelStyles}
                          data-test-id="edit-section-visible-checkbox-label"
                        >
                          Visibility:
                        </Label>
                        <StyledForm id="visible-checkbox">
                          <StyledCheckBox
                            checked={formValues.newVisible}
                            onChange={handleVisibleCheck}
                          >
                            Visible
                          </StyledCheckBox>
                        </StyledForm>
                      </div>
                      {isEditingTemplate && (
                        <StyledForm id="required-checkbox">
                          <StyledRequiredCheckbox
                            checked={formValues.newRequired || false}
                            onChange={handleRequiredCheck}
                          >
                            Required
                          </StyledRequiredCheckbox>
                        </StyledForm>
                      )}
                    </StyledDiv>
                    <StyledDiv>
                      <div style={{ width: '40%' }}>
                        <Label
                          style={labelStyles}
                          data-test-id="icon-upload-label"
                        >
                          Icon:
                        </Label>

                        <S3ImageControlsWrapper>
                          <S3Image
                            image={formValues.newIcon}
                            label="Click to Upload"
                            data-test-id="icon-image"
                            onUpload={handleIconChange}
                          />
                          <S3ImagePreviewWrapper>
                            {formValues.newIcon && (
                              <LogoPreview
                                url={formValues.newIcon.url}
                                maxWidth={50}
                                maxHeight={50}
                              />
                            )}
                          </S3ImagePreviewWrapper>
                        </S3ImageControlsWrapper>
                      </div>
                    </StyledDiv>
                    <ButtonWrapperFolder>
                      <StyledAlternativeButton
                        variant="secondary"
                        onClick={closePanel}
                        size="s"
                        data-test-id="previous-button"
                      >
                        Previous
                      </StyledAlternativeButton>
                      <Button onPress={editSection} data-test-id="save-button">
                        Apply changes
                      </Button>
                      <Button
                        variant="tertiary"
                        onClick={handleClose}
                        size="m"
                        className="mt-base"
                        data-test-id="popover-cancel-button"
                      >
                        Cancel
                      </Button>
                    </ButtonWrapperFolder>
                  </Card>
                </StyledCard>
              </StyledPage>
            )}
          </StyledEditPopover>
          <StyledAddItemButton
            variant="secondary"
            onClick={handleClick}
            icon={<AddIcon />}
            data-test-id="add-link-button"
          >
            Add an Item
          </StyledAddItemButton>
          <StyledPopover
            id={id}
            open={createLinkOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorPosition={{ top: 0, left: 0 }}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            data-test-id="add-link-popover"
          >
            {openPopover.linkForm === false ? (
              <StyledPage style={StyledNewLink} onClick={handleInputClick}>
                <StyledCard
                  onClick={handleInputClick}
                  data-test-id="add-link-card"
                >
                  <Card
                    header={<Label style={StyledTitle}>Add New Nav Item</Label>}
                    data-test-id="add-item-card"
                  >
                    <SecondaryFormControl id="radio-groups-controlled">
                      <StyledRadioButton
                        name="radio-groups-controlled"
                        value="New Link"
                        onChange={setValue}
                        checked={checked === 'New Link'}
                        data-test-id="new-page-radiobutton"
                      >
                        {Form}
                        Add Link
                        <StyledSubtext>
                          Add a link to an internal page or an external URL.
                        </StyledSubtext>
                      </StyledRadioButton>

                      <StyledRadioButton
                        name="radio-groups-controlled"
                        value="New Section"
                        onChange={setValue}
                        checked={checked === 'New Section'}
                        data-test-id="new-section-radiobutton"
                      >
                        {/* {Folder} */}
                        Add Section
                        <StyledSubtext>
                          Create a scaffolding section that can contain multiple
                          links.
                        </StyledSubtext>
                      </StyledRadioButton>
                    </SecondaryFormControl>
                    <ButtonWrapper>
                      <Button onClick={openPanel} data-test-id="next-button">
                        Next
                      </Button>
                      <Button
                        variant="tertiary"
                        onClick={handleClose}
                        className="mt-base"
                        data-test-id="cancel-button"
                      >
                        Cancel
                      </Button>
                    </ButtonWrapper>
                  </Card>
                </StyledCard>
              </StyledPage>
            ) : checked === 'New Link' ? (
              <StyledPage style={StyledNewLink} onClick={handleInputClick}>
                <StyledCard
                  onClick={handleInputClick}
                  data-test-id="add-newpage-styledcard"
                >
                  <Card
                    header={<Label style={StyledTitle}>Add a new link</Label>}
                    data-test-id="add-newpage-card"
                  >
                    <StyledDiv>
                      <StyledInnerDiv>
                        <Label
                          style={SecondaryLabelStyle}
                          data-test-id="type-dropdown-label"
                        >
                          Type:
                        </Label>
                        <StyledFormControl id="controlled-select">
                          <StyledDropdown
                            type="single"
                            onChange={setSelectedType}
                            value={selectedType}
                            data-test-id="add-type-dropdown"
                            items={typeDropdown}
                          />
                        </StyledFormControl>
                      </StyledInnerDiv>
                      <StyledInnerDiv>
                        <Label
                          style={labelStyles}
                          data-test-id="visible-checkbox-label"
                        >
                          Visibility:
                        </Label>
                        <StyledForm id="visible-checkbox">
                          <StyledCheckBox
                            checked={formValues.newVisible}
                            onChange={handleVisibleCheck}
                          >
                            Visible
                          </StyledCheckBox>
                        </StyledForm>
                        {isEditingTemplate && (
                          <StyledForm id="required-checkbox">
                            <StyledRequiredCheckbox
                              checked={formValues.newRequired || false}
                              onChange={handleRequiredCheck}
                            >
                              Required
                            </StyledRequiredCheckbox>
                          </StyledForm>
                        )}
                      </StyledInnerDiv>
                    </StyledDiv>
                    <div style={{ width: '100%', display: 'inline-flex' }}>
                      <div
                        style={{
                          width: '50%',
                          padding: '0 .5rem .5rem 0',
                        }}
                      >
                        <Label
                          style={labelStyles}
                          data-test-id="display-text-input-label"
                        >
                          Display Text:
                        </Label>
                        <StyledTextInput
                          name="display-text"
                          value={formValues.newDisplayText}
                          onChange={e =>
                            setFormValues({
                              ...formValues,
                              newDisplayText: e.target.value,
                            })
                          }
                          data-test-id="web-address"
                        />
                      </div>
                      <div
                        style={{
                          width: '50%',
                          padding: '0 0 .5rem 0',
                          display: 'block',
                        }}
                      >
                        <Label
                          style={labelStyles}
                          data-test-id="icon-upload-label"
                        >
                          Icon:
                        </Label>
                        <S3ImageControlsWrapper>
                          <S3Image
                            image={formValues.newIcon}
                            label="Click to Upload"
                            data-test-id="icon-image"
                            onUpload={handleIconChange}
                          />
                          <S3ImagePreviewWrapper>
                            {formValues.newIcon && (
                              <LogoPreview
                                url={formValues.newIcon.url}
                                maxWidth={50}
                                maxHeight={50}
                              />
                            )}
                          </S3ImagePreviewWrapper>
                        </S3ImageControlsWrapper>
                      </div>
                    </div>
                    {selectedType?.value === 'page' ? (
                      <>
                        <div
                          style={{
                            width: '100%',
                            padding: '0 0 .5rem 0',
                          }}
                        >
                          <Label
                            style={labelStyles}
                            data-test-id="page-dropdown-label"
                          >
                            Select a page:
                          </Label>
                          <StyledFormControl id="controlled-select">
                            <StyledDropdown
                              type="single"
                              onChange={handlePageChange}
                              value={pageValue}
                              data-test-id="new-page-dropdown"
                              items={pageDropdown}
                            ></StyledDropdown>
                          </StyledFormControl>
                          {idpDropdown.length > 0 && (
                            <>
                              <Label
                                style={labelStyles}
                                data-test-id="page-dropdown-label"
                              >
                                Allowed IDPs:
                              </Label>
                              <StyledFormControl id="controlled-select">
                                <StyledDropdown
                                  type="multi"
                                  onChange={setSelectedIdps}
                                  value={selectedIdps}
                                  data-test-id="page-select-dropdown"
                                  items={idpDropdown}
                                />
                              </StyledFormControl>
                            </>
                          )}
                        </div>
                      </>
                    ) : selectedType?.value === 'url' ? (
                      <>
                        <div
                          style={{
                            width: '30rem',
                            padding: '0 0 .5rem 0',
                          }}
                        >
                          <Label
                            style={labelStyles}
                            data-test-id="page-dropdown-label"
                          >
                            Enter a URL:
                          </Label>
                          <StyledTextInput
                            name="webaddress"
                            value={formValues.newUrl}
                            onChange={handleUrl}
                            placeholder="https://..."
                            data-test-id="web-address"
                          />
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    <ButtonWrapper1>
                      <StyledAlternativeButton
                        variant="secondary"
                        onClick={closePanel}
                        size="s"
                        data-test-id="previous-button"
                      >
                        Previous
                      </StyledAlternativeButton>
                      <Button
                        onPress={addNewLink}
                        data-test-id="save-link-button"
                      >
                        Apply changes
                      </Button>
                      <Button
                        variant="tertiary"
                        onClick={handleClose}
                        className="mt-base"
                        data-test-id="popover-cancel-button"
                      >
                        Cancel
                      </Button>
                    </ButtonWrapper1>
                  </Card>
                </StyledCard>
              </StyledPage>
            ) : (
              <StyledPage style={StyledNewLink} onClick={handleInputClick}>
                <StyledCard
                  onClick={handleInputClick}
                  data-test-id="add-new-section-styledcard"
                >
                  <Card
                    header={
                      <Label style={StyledTitle}>Add a new section</Label>
                    }
                    data-test-id="add-new-section-card"
                  >
                    <StyledDiv>
                      <div
                        style={{
                          width: '60%',
                          display: 'inherit',
                          padding: '.5rem .5rem .5rem 0',
                        }}
                      >
                        <Label
                          style={TertiaryLabelStyles}
                          data-test-id="display-text-input-label"
                        >
                          Display Text:
                        </Label>
                        <StyledTextInput
                          name="display-text"
                          value={formValues.newDisplayText}
                          onChange={e =>
                            setFormValues({
                              ...formValues,
                              newDisplayText: e.target.value,
                            })
                          }
                          data-test-id="web-address"
                        />
                      </div>
                      <div
                        style={{
                          width: '40%',
                          display: 'inherit',
                          paddingTop: '.5rem',
                        }}
                      >
                        <Label
                          style={labelStyles}
                          data-test-id="visible-checkbox-label"
                        >
                          Visibility:
                        </Label>
                        <StyledForm id="visible-checkbox">
                          <StyledCheckBox
                            checked={formValues.newVisible}
                            onChange={handleVisibleCheck}
                          >
                            Visible
                          </StyledCheckBox>
                        </StyledForm>
                      </div>
                      {isEditingTemplate && (
                        <StyledForm id="required-checkbox">
                          <StyledRequiredCheckbox
                            checked={formValues.newRequired || false}
                            onChange={handleRequiredCheck}
                          >
                            Required
                          </StyledRequiredCheckbox>
                        </StyledForm>
                      )}
                    </StyledDiv>
                    <StyledDiv>
                      <div style={{ width: '40%' }}>
                        <Label
                          style={labelStyles}
                          data-test-id="icon-upload-label"
                        >
                          Icon:
                        </Label>

                        <S3ImageControlsWrapper>
                          <S3Image
                            image={formValues.newIcon}
                            label="Click to Upload"
                            data-test-id="icon-image"
                            onUpload={handleIconChange}
                          />
                          <S3ImagePreviewWrapper>
                            {formValues.newIcon && (
                              <LogoPreview
                                url={formValues.newIcon.url}
                                maxWidth={50}
                                maxHeight={50}
                              />
                            )}
                          </S3ImagePreviewWrapper>
                        </S3ImageControlsWrapper>
                      </div>
                    </StyledDiv>
                    <ButtonWrapperFolder>
                      <StyledAlternativeButton
                        variant="secondary"
                        onClick={closePanel}
                        size="s"
                        data-test-id="previous-button"
                      >
                        Previous
                      </StyledAlternativeButton>
                      <Button
                        onPress={currentItem.id ? editSection : addNewSection}
                        data-test-id="save-button"
                      >
                        Apply changes
                      </Button>
                      <Button
                        variant="tertiary"
                        onClick={handleClose}
                        size="m"
                        className="mt-base"
                        data-test-id="popover-cancel-button"
                      >
                        Cancel
                      </Button>
                    </ButtonWrapperFolder>
                  </Card>
                </StyledCard>
              </StyledPage>
            )}
          </StyledPopover>
        </Panel>
      </StyledPanel>
      <StyledPanel data-testid="styles-config-panel">
        <Panel
          title={<Label style={StyledPanelTitle}>Typography</Label>}
          initiallyClosed
          icon={Arrow}
          data-test-id="typography-panel"
        >
          <NavigationTypography
            data-test-id="nav-typography-component"
            disabled={layoutLocked}
            color={widgetState.config.textColor}
            widgetState={props.widgetState}
            handleFontSize={data => {
              onChange({
                ...value,
                fontSize: Number(data),
              });
            }}
            handleFont={data => {
              onChange({
                ...value,
                font: data,
              });
            }}
            handleTextColor={handleTextColor}
            handleHoverTextColor={handleHoverTextColor}
            handleHoverTextColorCheckbox={data => {
              onChange({
                ...value,
                isDifferentTextColorOnHover: data,
              });
            }}
            pages={pages}
            site={props.site}
            updateSite={props.updateSite}
            saveSite={props.saveSite}
          />
        </Panel>
      </StyledPanel>
      <StyledPanel>
        <SizeLocationPanel
          title={<Label style={StyledPanelTitle}>Size and Location</Label>}
          initiallyClosed
          icon={Arrow}
          data-testid="sizeandlocation-panel"
        >
          <WidgetSizeLocation
            data-test-id="widget-size-location"
            disabled={layoutLocked}
            activeWidgetId={props.widgetState.id}
            sizeLocation={{
              xLocation: value.xLocation,
              yLocation: value.yLocation,
              width: value.width,
              height: value.height,
              uniqueId: value.uniqueId,
            }}
            updateWidgetSizeLocation={updateWidgetSizeLocation}
          />
        </SizeLocationPanel>
      </StyledPanel>
      <StyledPanel>
        <Panel
          title={<Label style={StyledPanelTitle}>Background</Label>}
          initiallyClosed
          icon={Arrow}
          data-test-id="background-panel"
        >
          <Background
            data-test-id="background-component"
            disabled={layoutLocked}
            backgroundData={backgroundData}
            backgroundImage={updateBackgroundImage}
            handleBackgroundColor={handleBackgroundColor}
            saveSite={props.saveSite}
            updateSite={props.updateSite}
            site={props.site}
            widgetState={widgetState}
          />
        </Panel>
      </StyledPanel>
      <StyledPanel>
        <Panel
          title={<Label style={StyledPanelTitle}>Border</Label>}
          initiallyClosed
          icon={Arrow}
          data-test-id="border-panel"
        >
          <Border
            data-test-id="border-component"
            disabled={layoutLocked}
            borderData={updateBorderData}
            handleLineColor={handleLineColor}
            widgetState={widgetState}
            pages={props.pages}
            site={props.site}
            updateSite={props.updateSite}
            saveSite={props.saveSite}
          />
        </Panel>
      </StyledPanel>
      <StyledPanel>
        <Panel
          title={<Label style={StyledPanelTitle}>Padding</Label>}
          initiallyClosed
          icon={Arrow}
        >
          <Padding
            data-test-id="padding-data"
            disabled={layoutLocked}
            paddingData={updatePaddingData}
            widgetState={widgetState}
          />
        </Panel>
      </StyledPanel>
    </Panel.Group>
  );
};

export default NavWidgetControl;
