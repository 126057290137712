import MyAppsControl from './MyAppsControl';
import MyAppsWidget from './MyAppsWidget';
// import MyAppsControl from './MyAppsControl';
import { Config } from './types';
import {
  defaultBorderData,
  defaultPaddingData,
  defaultMyappsSizeLocationData,
} from 'builder/util/constants';

export default {
  type: 'Application',
  displayName: 'Applications ',
  icon: 'AppMenu',
  initialConfig: {
    ...defaultMyappsSizeLocationData,
    ...defaultPaddingData,
    ...defaultBorderData,
    enableRegister: false,
    enableAppEnvironment: false,
    appsEnvironmentList: '',
    appRegistration: {
      businessKey: '',
    },
    uniqueId: '123abc',
    lineColor: 'red',
    enableFullScreen: false,
    siteId: 123,
    pageId: 456,
    visible: true,
    font: 'OptumSans',
  },
  components: {
    render: MyAppsWidget,
    control: MyAppsControl,
  },
  copyable: false,
} as HorizonWidgetDefinition<Config>;
