import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Label, Checkbox, FormControl } from '@uitk/react';

export const LabelAndTextInputWrapper = styled.div`
  display: block;
  align-items: baseline;
`;

export const RequiredFieldWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const LabelWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const StyledLabel = styled(Label)`
  color: #1d1929;
  font-size: 14px;
  font-family: OptumSans, sans-serif;
  padding-right: 10px;
  font-weight: 500;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 0;
`;

export interface EnableFullScreenWidthProps {
  disabled?: boolean;
  enableFullScreenWidth: boolean;
  handleEnableFullScreen: (data: boolean) => void;
}

const EnableFullScreenWidth: React.FC<EnableFullScreenWidthProps> = (
  props: EnableFullScreenWidthProps
) => {
  const { disabled, enableFullScreenWidth, handleEnableFullScreen } = props;
  const [checked, setChecked] = useState(enableFullScreenWidth);
  useEffect(() => {
    setChecked(enableFullScreenWidth);
  }, [enableFullScreenWidth]);

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    handleEnableFullScreen(e.target.checked);
  };

  return (
    <>
      <RequiredFieldWrapper>
        <StyledLabel>Enable full screen width</StyledLabel>
        <FormControl id="checkbox-form-control" disabled={disabled}>
          <StyledCheckbox
            data-test-id="required-checkbox"
            checked={checked}
            onChange={handleCheck}
          />
        </FormControl>
      </RequiredFieldWrapper>
    </>
  );
};

export default EnableFullScreenWidth;
