import SearchBarWidget from './SearchBarWidget';
import { Config } from './types';
import SearchBarWidgetControl from './SearchBarWidgetControl';
import {
  defaultBackgroundData,
  defaultBackgroundImageData,
  defaultBorderData,
  defaultSizeLocationData,
} from 'builder/util/constants';

export default {
  type: 'Search',
  displayName: 'Search',
  icon: 'Search',
  initialConfig: {
    ...defaultSizeLocationData,
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    ...defaultBorderData,
    fontSize: 12,
    font: 'OptumSans',
    fontFamily: 'OptumSans',
    lineSpacing: 1,
    textHighlightColor: '',
    iconBackgroundColor: 'white',
    uniqueId: '123abc',
    lineColor: 'red',
    visible: true,
    width: 400,
    textColor: '#fff',
    paddingData: { top: 12, bottom: 12, left: 40, right: 40 },
    defaultResults: [
      {
        category: 'Identity & Access Management',
        title: 'Authentication How to Guide',
        description: 'Step-by-step instructions for OAuth 2.0 integration.',
        link: '/auth-guide',
      },
      {
        category: 'Data & Analytics Platform',
        title: 'Top System Errors',
        description: 'See top system errors in the last 7 days.',
        link: '/system-errors',
      },
      {
        category: 'Data & Analytics Platform',
        title: 'Token Request Success Rate',
        description: 'Percentage of successful token requests.',
        link: '/token-success-rate',
      },
    ],
  },
  components: {
    render: SearchBarWidget,
    control: SearchBarWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
