import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTemplateList from './PageTemplateList';
import {
  fetchPageTemplates,
  selectPageTemplates,
  selectPageTemplatesMeta,
  selectLimit,
  selectOffset,
} from 'shared/state/ducks/pageTemplates';
import { RootState } from '../../../shared/state';
import { selectUserTenancy } from '../../../shared/state/ducks/tenancies';
import PageTemplatesPagingFooter from './PageTemplatesPagingFooter';

const PageTemplates = () => {
  const dispatch = useDispatch();
  const pageTemplates = useSelector((state: RootState) =>
    selectPageTemplates()(state)
  );
  const pageTemplatesMeta = useSelector((state: RootState) =>
    selectPageTemplatesMeta()(state)
  );
  const userTenancy = useSelector((state: RootState) =>
    selectUserTenancy()(state)
  );
  const limit = useSelector((state: RootState) => selectLimit()(state));
  const offset = useSelector((state: RootState) => selectOffset()(state));

  const { error, pending } = pageTemplatesMeta;

  useEffect(() => {
    if (userTenancy) {
      const tenancies = [userTenancy];
      dispatch(fetchPageTemplates(tenancies, limit, offset));
    }
  }, [userTenancy, limit, offset, dispatch]);

  return (
    <>
      <PageTemplateList
        pageTemplates={pageTemplates}
        error={error}
        pending={pending}
      />
      <PageTemplatesPagingFooter />
    </>
  );
};

export default PageTemplates;
