import React from 'react';
import styled from 'styled-components';
import { Panel, Label } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import {
  BackgroundData,
  Background,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  DragDropBeautifulDnD,
  DragDropItemData,
} from 'builder/components/WidgetConfigurations/DragDropBeautifulDnD';
import TabsColorSelector from 'builder/components/WidgetConfigurations/Tabs/TabsColorSelector';
import { StyledPanel, StyledPanelTitle } from '../Text/TextWidgetControl';
import { TabsTypography } from 'builder/components/WidgetConfigurations/Tabs/TabsTypography';
import { toast } from 'react-toastify';

const SizeLocationPanel = styled(Panel)`
  > div {
    padding: 1rem;
  }
`;

const TabsWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const {
    value,
    onChange,
    widgetState,
    addChild,
    currentPage,
    updateCurrentPage,
    removeWidget,
    isEditingTemplate,
    currentPage: { layoutLocked },
  } = props;

  const updateWidgetSizeLocation = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };

  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };

  const updateBackgroundData = (data: BackgroundData) => {
    onChange({
      ...value,
      backgroundData: data,
    });
  };

  const updateBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };

  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };

  const updateTabsData = (
    data: Array<DragDropItemData>,
    currentTabIndex?: number
  ) => {
    const currentTabIndexAfterDelete = currentTabIndex
      ? currentTabIndex
      : currentTabIndex === 0
      ? 0
      : value.currentTabIndex;
    onChange({
      ...value,
      tabs: data,
      currentTabIndex: currentTabIndexAfterDelete,
    });
  };

  const handleHoverFocusColor = (data: string) => {
    if (data) {
      if (data.startsWith('linear') || data.startsWith('radial')) {
        toast('Gradient cannot be used for hover and focus color', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        onChange({
          ...value,
          hoverFocusColor: data,
        });
      }
    }
  };

  const handleBackgroundColor = (data: string) => {
    onChange({
      ...value,
      backgroundData: { ...value.backgroundData, color: data },
    });
  };

  const handleTabClick = (panelId: number) => {
    onChange(
      {
        ...value,
        currentTabIndex: panelId,
      },
      widgetState.id
    );
  };

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
            data-test-id="General-panel"
          >
            <TabsColorSelector
              data-test-id="hover-focus-panel"
              disabled={layoutLocked}
              handleHoverFocusColor={handleHoverFocusColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
            <DragDropBeautifulDnD
              dragDropItemsData={updateTabsData}
              itemsState={widgetState.config.tabs}
              itemName={'Tab'}
              heading={'Tabs'}
              addChild={addChild}
              widgetState={widgetState}
              currentPage={currentPage}
              updateCurrentPage={updateCurrentPage}
              updateCurrentTab={handleTabClick}
              removeWidget={removeWidget}
              isEditingTemplate={isEditingTemplate}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Typography</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="tabs-typography-panel"
          >
            <TabsTypography
              data-test-id="tabs-typography-component"
              disabled={layoutLocked}
              widgetState={props.widgetState}
              handleFontSize={data => {
                onChange({
                  ...value,
                  fontSize: Number(data),
                });
              }}
              handleFont={data => {
                onChange({
                  ...value,
                  font: data,
                });
              }}
              handleTextColor={data => {
                onChange({
                  ...value,
                  tabsTextColor: data,
                });
              }}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="sizeandlocation-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              disabled={layoutLocked}
              activeWidgetId={props.widgetState.id}
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLocation={updateWidgetSizeLocation}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Background</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="provisionig-service-panel"
          >
            <Background
              data-test-id="background-component"
              disabled={layoutLocked}
              backgroundData={updateBackgroundData}
              backgroundImage={updateBackgroundImage}
              widgetState={widgetState}
              handleBackgroundColor={handleBackgroundColor}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Border</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="border-panel"
          >
            <Border
              data-test-id="border-component"
              disabled={layoutLocked}
              borderData={updateBorderData}
              handleLineColor={handleLineColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default TabsWidgetControl;
