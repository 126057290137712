import {
  PaginationActionButtonsWrapper,
  PaginationButtonsWrapper,
  TemplatesPaginationWrapper,
  RawActionButton,
  TextWrapper,
  createButtons,
} from '../../../shared/components/SiteDataTable/Style';
import { IconWrapper } from '../../../shared/components/SiteDataTable/SiteDataTable';
import InfoIcon from '../../../shared/components/SiteDataTable/InfoIcon';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../shared/state';
import {
  selectLimit,
  selectOffset,
  setLimit,
  setOffset,
  selectPageTemplatesMeta,
  selectSize,
} from 'shared/state/ducks/pageTemplates';

export default function PageTemplatesPagingFooter() {
  const dispatch = useDispatch();
  const limit = useSelector((state: RootState) => selectLimit()(state));
  const offset = useSelector((state: RootState) => selectOffset()(state));
  const pageTemplatesMeta = useSelector((state: RootState) =>
    selectPageTemplatesMeta()(state)
  );
  const size = useSelector((state: RootState) => selectSize()(state));

  const { pending, error } = pageTemplatesMeta;

  const handleSetPageSize = (newLimit: number) => {
    dispatch(setLimit(newLimit));
  };

  // Template API is a Springboot API using JPA
  // Offset does not need to be calculated
  const handleNextPage = () => {
    dispatch(setOffset(offset + 1));
  };

  const handlePreviousPage = () => {
    dispatch(setOffset(Math.max(0, offset - 1)));
  };

  // Do not show during loading or if there is an error
  if (pending || error || size === 0) {
    return null;
  }

  return (
    <TemplatesPaginationWrapper>
      <PaginationButtonsWrapper>
        <TextWrapper isBold={true} reducePadding={true}>
          Entries per page
        </TextWrapper>
        <IconWrapper
          iconActive={false}
          tabIndex={0}
          data-test-id="info-wrapper"
        >
          <InfoIcon />
        </IconWrapper>
        {createButtons(3, handleSetPageSize)}
      </PaginationButtonsWrapper>
      <PaginationActionButtonsWrapper>
        <RawActionButton
          onClick={handlePreviousPage}
          disabled={offset === 0}
          isPreviousButton={true}
          tertiary={false}
        >
          <TextWrapper reducePadding={true} isBold={false}>
            Previous
          </TextWrapper>
        </RawActionButton>
        <RawActionButton
          onClick={handleNextPage}
          disabled={size < limit}
          tertiary={false}
          isPreviousButton={false}
        >
          <TextWrapper reducePadding={true} isBold={false}>
            Next
          </TextWrapper>
        </RawActionButton>
      </PaginationActionButtonsWrapper>
    </TemplatesPaginationWrapper>
  );
}
