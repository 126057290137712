import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { StylesProvider } from '@material-ui/core';
import PageTemplates from './PageTemplates';
import Preview from '../Preview';
import SiteLandingPage from '../SiteBuilder/SiteLandingPageContainer';
import { selectSelectedPageTemplate } from '../../../shared/state/ducks/pageTemplates';
import { RootState } from '../../../shared/state';
import { TEMPLATE_EDITOR_PAGE_ID, TEMPLATES_SITE_ID } from './constants';

const PageTemplatesRoutes = () => {
  const history = useHistory();
  const selectedPageTemplate = useSelector((state: RootState) =>
    selectSelectedPageTemplate()(state)
  );
  const pageTemplateMatch = useRouteMatch('/page-templates/:templateId');
  const pageTemplatePreviewMatch = useRouteMatch(
    '/page-templates/:templateId/preview'
  );

  useEffect(() => {
    if (
      (pageTemplateMatch?.isExact || pageTemplatePreviewMatch?.isExact) &&
      !selectedPageTemplate
    ) {
      history.push('/page-templates');
    }
  }, [pageTemplateMatch, selectedPageTemplate]);

  const renderContent = () => {
    switch (true) {
      case pageTemplateMatch?.isExact && !!selectedPageTemplate:
        return (
          <SiteLandingPage
            siteId={TEMPLATES_SITE_ID}
            pageId={TEMPLATE_EDITOR_PAGE_ID}
            parentWidgetId={'root'}
            history={history}
          />
        );
      case pageTemplatePreviewMatch?.isExact && !!selectedPageTemplate:
        return (
          <Preview
            basePath={`page-templates/${selectedPageTemplate.id}/preview`}
            siteId={TEMPLATES_SITE_ID}
          />
        );
      default:
        return (
          <Scrollbars
            autoHeight
            // 70vh brings the footer closest to the bottom at 1080p with empty or error state
            autoHeightMin="70vh"
            style={{ padding: '0em 1.5em' }}
          >
            <StylesProvider injectFirst>
              <PageTemplates />
            </StylesProvider>
          </Scrollbars>
        );
    }
  };

  return renderContent();
};

export default PageTemplatesRoutes;
