/*
The MyApps.tsx file is a React component that displays a list of applications (apps) to the user. It uses TypeScript and is styled with styled-components.
The file starts by defining an interface MyAppsProps which describes the properties that the MyApps component expects. These properties include arrays of applications, organizations, user accounts, and errors, among other things.
The MyApps component uses useState to manage the state of the app registration process and useEffect to fetch the status of the registration when the component mounts or when certain props change.
The component also defines several helper functions. For example, getTiles is used to collect and mutate the appropriate tiles from the apps, getErrorMessage is used to generate an error message based on the errors and apps passed to it, and getWidgetHeight is used to calculate the height of the widget based on the number of apps.
The component's render method returns a JSX structure that includes a wrapper, a header, a select wrapper for selecting an organization, a button for requesting access, and a list of apps. The list of apps is either a RichAppList or a StandardAppListDummy, depending on whether the apps are loaded or not.
The file also includes several styled components, such as Wrapper, Header, SelectWrapper, ButtonWrapper, Apps, TextWrapper, and others. These are used to style the different parts of the component.
*/

import React, { useState, ReactElement, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import api, { TilesResponse } from 'shared/api/myapps';
import Div from 'shared/components/Div';
import { Organization, User, UserAccount } from 'shared/state/misc/oidc';
import styled from 'styled-components';
import { AppRegistrationStatus } from 'types/types';
import { Button, Link } from 'link-ui-react';
import { Config, EnhancedTile } from './types';
import alphasort from 'shared/util/alphasort';
import Typography from 'shared/components/Typography';
import URI from 'urijs';
import {
  SELF_REGISTRATION_SUPPORTED_TENANCIES,
  UserAccessRequest,
} from 'client/constants';
import AccessRequestDialogContainer from './AccessRequestDialog/AccessRequestDialogContainer';
import env from 'client/config';
import builderenv from 'builder/config';
import Message from 'shared/components/Message';
import Loading from 'shared/components/Loading';
import RichAppList from './components/RichAppList';
import StandardAppListDummy from './components/StandardAppList';
import { Select } from '@mui/material';
import { MenuItem } from '@material-ui/core';
import { defaultTheme as theme } from 'link-ui-react';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
`;

const StyledDialog = styled.h3`
  display: flex;
  font-size: larger;
  color: ${theme.colors.aux.black};
  align-items: center;
  font-family: OptumSans, sans-serif;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
`;

const SelectWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > div > div {
    font-family: OptumSans, sans-serif;
  }
`;

const CustomLink = styled(Link)`
  margin-right: 5px;
  font-size: larger;
  color: ${theme.colors.aux.darkBlue};
  & span {
    font-size: inherit;
  }
`;

const SelectLabel = styled(Typography)`
  color: ${theme.colors.aux.darkGrey};
`;

const FlexSelect = styled(Select)<{ width?: number }>`
  div {
    cursor: default;
  }
  width: ${p => p.width}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Apps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 400px;
`;

const TextWrapper = styled.div`
  padding-bottom: 10px;
  font-family: OptumSans, sans-serif;
`;

const capitalizeFirstLetter = (str: String) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const filterEnvironment = (
  enableAppEnvironment: boolean,
  envName: string,
  tile: Tile
) => {
  if (enableAppEnvironment) {
    return tile.envName
      ? tile.envName.toLowerCase() === envName.toLowerCase()
      : false;
  }
  return true;
};

const getTiles = (
  apps: Array<Application>,
  tileType: string,
  siteDomain: string,
  enableAppEnvironment: boolean,
  envName: string,
  orgId?: string
): Array<EnhancedTile> => {
  /**
   * Collect the appropriate tiles and mutate them:
   * - Add a linkOrgId query param if an orgId is present
   * - Add the app's theme color to each
   */
  return apps
    .reduce((acc, app) => {
      return acc.concat(
        app.tiles
          .filter(tile => tile.type === tileType)
          .filter(tile =>
            filterEnvironment(enableAppEnvironment, envName, tile)
          )
          .map(origTile => {
            const tile: EnhancedTile = {
              ...origTile,
              emulationEnabled: app.emulationEnabled,
              themeColor: `#${app.themeColor}` || '#d3d3d3',
            };
            if (tile.targetURL) {
              const url = URI(tile.targetURL);
              if (tile.assistant) {
                url.addQuery({ domain: siteDomain });
              }
              if (orgId) {
                url.addQuery({ linkOrgId: orgId });
              }
              tile.targetURL = url.toString();
            }
            return tile;
          })
      );
    }, [] as Array<EnhancedTile>)
    .sort((a, b) => alphasort(a.internalName, b.internalName));
};

const getErrorMessage = (errors: Array<string>, apps: Array<Application>) => {
  if (apps.length > 0) {
    if (
      errors.includes('OPServiceFailed') ||
      errors.includes('LSServiceFailed')
    ) {
      return 'There was a system error.  If you are not able to locate your app, try closing your browser and sign-in with a new session.';
    } else {
      return 'There was a system error due to an invalid user identifier. If you are not able to locate your app, please contact your administrator or support.';
    }
  } else if (errors.includes('Invalid User Identifier')) {
    return 'We are unable to locate your account. Please contact your administrator or support.';
  } else {
    return 'We are facing a technical issue. Please try again.';
  }
};

export interface MyAppsProps extends WidgetRenderProps<Config> {
  myapps: Array<Application>;
  myappsErrors: Array<string>;
  myappsMeta: StateMeta;
  domain: string;
  published: boolean;
  orgs: Array<Organization>;
  userAccounts: Array<UserAccount>;
  activeOrgId: string;
  fetchMyApps: (published: boolean) => Promise<TilesResponse>;
  setActiveOrgId: (id: string) => void;
  onClose?: () => void;
  openAccessRequestDialog?: () => void;
  siteDomain: string;
  user: User;
  enableRegister: boolean;
  appRegistration?: AppRegistration;
  noAppsMessage?: string;
  tenancy?: string;
  siteProvisioningStores?: Array<number>;
  enableAppEnvironment?: boolean;
  appsEnvironmentList?: string;
  config?: Config;
  uniqueId?: string;
  onUpdateConfig?: (
    config: any,
    widgetId: string,
    defaultWidget: boolean,
    siteId: number,
    pageId: number
  ) => void;
}

export const MyApps: React.FC<MyAppsProps> = (
  props: MyAppsProps
): ReactElement => {
  const [registration, setRegistration] = useState({
    resume: false,
    isFetching: false,
    error: false,
    isSaving: false,
  });

  const {
    paddingData,
    borderData,
    lineColor,
    cardStyle,
    visible,
    isCustomWidget,
    font,
  } = props.widgetState.config;

  const environment = props.widgetState.config.appsEnvironmentList
    ? capitalizeFirstLetter(
        props.widgetState.config.appsEnvironmentList.split(',')[0]
      )
    : '';
  const [appEnv, setAppEnv] = useState(environment);

  useEffect(() => {
    if (props.myapps.length === 0 && props.enableRegister && props.published) {
      fetchStepupStatus();
    }
  }, [props.myapps, props.enableRegister, props.published]);

  const fetchStepupStatus = () => {
    setRegistration(prevState => ({ ...prevState, isFetching: true }));
    api
      .getStepupStatus(props.appRegistration.businessKey)
      .then(appReq => {
        if (appReq.status === AppRegistrationStatus.Pending) {
          setRegistration(prevState => ({
            ...prevState,
            resume: true,
            isFetching: false,
          }));
        } else {
          setRegistration(prevState => ({ ...prevState, isFetching: false }));
        }
      })
      .catch(e => {
        if (
          e.response.status === 400 &&
          e.response.data.message.includes('No Request found')
        ) {
          setRegistration(prevState => ({ ...prevState, isFetching: false }));
        } else {
          toast('Error fetching registration status', {
            type: 'error',
            theme: 'colored',
          });
          setRegistration(prevState => ({
            ...prevState,
            error: true,
            isFetching: false,
          }));
        }
      });
  };

  const handleRequestAccessClick = () => {
    const { openAccessRequestDialog } = props;
    openAccessRequestDialog();
  };

  // the below code repeatedly calling widgetWrapping which is causing issue
  const {
    fetchMyApps,
    published,
    enableRegister,
    // TODO: refactor to use consistent state shape successful does not appear in props until after a request is made
    myappsMeta: { pending, successful: appsRequestHappened = null },
  } = props;
  useEffect(() => {
    if (pending === false && appsRequestHappened === null) {
      fetchMyApps(published).then(response => {
        if (response.appTiles.length === 0) {
          // if no apps is provisioned to user and registration is enabled for the site
          // then get the registration status
          enableRegister && published && fetchStepupStatus();
        }
      });
    }
  }, [published, props]);

  const getWidgetHeight = myApps => {
    let newHeight = 0;
    for (let i = 0; i < myApps.length; i++) {
      if (i % 3 === 0) {
        if (myApps.length <= 3) {
          newHeight = newHeight + 600;
        } else if (myApps.length > 3 && myApps.length <= 6) {
          newHeight = newHeight + 450;
        } else if (myApps.length > 6 && myApps.length <= 9) {
          newHeight = newHeight + 480;
        }
      }
    }
    return newHeight;
  };

  const prevCountRef = useRef<number>(props.myapps.length);

  useEffect(() => {
    if (props.myapps.length >= 3) {
      const newHeight = getWidgetHeight(props.myapps);
      const { config } = props.widgetState;

      // Only update if the height has changed
      if (newHeight > config.height && config.height !== newHeight) {
        prevCountRef.current = props.myapps.length;
        const { onUpdateConfig } = props;
        const { uniqueId } = props.widgetState.config;
        onUpdateConfig(
          {
            ...config,
            height: newHeight,
          },
          uniqueId,
          false,
          config.siteId,
          config.pageId
        );
      }
    }
  }, [props.myapps.length]);

  const setOrgSelectWidth = (): number => {
    const { orgs } = props;
    const charSizes = orgs.map(org => org.name.length).sort((a, b) => a - b);
    const maxLength = Math.max(...charSizes);
    return maxLength > 18 && maxLength < 24 ? 250 : maxLength > 24 ? 300 : 200;
  };

  const handleOrgChange = e => {
    const { activeOrgId, setActiveOrgId, fetchMyApps, published } = props;
    if (activeOrgId !== e.target.value) {
      setActiveOrgId(e.target.value);
      fetchMyApps(published);
    }
  };

  const handleEnvironmentChange = e => {
    setAppEnv(e.target.value);
  };

  const handleRetry = () => {
    const { fetchMyApps, published, enableRegister, myapps } = props;
    fetchMyApps(published).then(() => {
      if (myapps.length === 0) {
        enableRegister && published && fetchStepupStatus();
      }
    });
  };
  const handleClick = (e: MouseEvent, tile: EnhancedTile) => {
    console.log('Tile clicked', tile, e);
    // props.tracking.trackEvent({
    //   action: 'myapps_launchapp',
    //   tileId: tile.tileId,
    //   tileLabel: tile.label,
    //   tileDate: tile.activeDate,
    //   pageX: e.pageX,
    //   pageY: e.pageY,
    // });
  };
  const initiateRegistration = () => {
    const { appRegistration } = props;
    useState(prevState => ({
      ...prevState,
      isSaving: true,
    }));
    api
      .postStepup({ businessKey: appRegistration.businessKey })
      .then(res => {
        appRegistration.registerUrl &&
          window.location.assign(
            URI(appRegistration.registerUrl)
              .addQuery({ requestId: res.requestId })
              .toString()
          );
        useState(prevState => ({
          ...prevState,
          isSaving: false,
        }));
      })
      .catch(() => {
        toast('Error updating registration status', { type: 'error' });
        useState(prevState => ({
          ...prevState,
          isSaving: false,
        }));
      });
  };

  const renderSelfRegistration = () => {
    return (
      <>
        <TextWrapper>
          You are not authorized to access applications on this site. Please
          click on "Self Registration" to request access.
        </TextWrapper>
        <Button
          data-test-id="self-registration-button"
          loading={registration.isSaving}
          onClick={forwardToSelfRegistration}
        >
          {' '}
          Self Registration
        </Button>
      </>
    );
  };
  const renderNoSelfRegistration = () => {
    const { siteDomain } = props;
    return (
      <StyledDialog data-test-id="self-registration-popup">
        <>
          You are not authorized to access applications on this site. Please
          contact your administrator to get access.
        </>
        <AccessRequestDialogContainer
          dialogTitle={UserAccessRequest.NEW_ACCESS_POPUP_TITLE}
          accessReason={UserAccessRequest.DEFAULT_NEW_ACCESS_REASON}
          orgUrl={siteDomain}
          templateName={UserAccessRequest.NEW_ACCESS_TEMPLATE_NAME}
          shouldShowInstructionsPopUp={false}
        />
      </StyledDialog>
    );
  };

  const getSelfRegistrationUrl = () => {
    const { tenancy, siteDomain } = props;
    if (tenancy === UserAccessRequest.SMAP_DOMAIN) {
      return env.smapSelfRegistrationUrl;
    } else if (tenancy === UserAccessRequest.MBM_DOMAIN) {
      return env.mbmSelfRegistrationUrl;
    } else if (tenancy === UserAccessRequest.LD_DOMAIN) {
      return builderenv.requestAccessUrl;
    } else {
      return siteDomain;
    }
  };

  const forwardToSelfRegistration = () => {
    const selfRegistrationUrl = getSelfRegistrationUrl();
    window.location.assign(selfRegistrationUrl);
  };
  const isSelfRegistration = () => {
    const { tenancy } = props;
    if (SELF_REGISTRATION_SUPPORTED_TENANCIES.includes(tenancy)) {
      return true;
    } else {
      return false;
    }
  };

  const renderResumeRegistration = () => {
    const {
      appRegistration: { resumeRegisterMessage, resumeRegisterUrl },
    } = props;
    return (
      <>
        <TextWrapper>
          {resumeRegisterMessage ||
            'Click "Resume Registration" to continue or check status of your self registration'}
        </TextWrapper>
        <Button
          onClick={() => {
            resumeRegisterUrl && window.location.assign(`${resumeRegisterUrl}`);
          }}
        >
          {' '}
          Resume Registration
        </Button>
      </>
    );
  };

  const renderRegistration = () => {
    const {
      appRegistration: { registerMessage },
    } = props;
    return (
      <>
        <TextWrapper>
          {registerMessage ||
            'You have not being provisioned to an application. Click "Register Now" to initiate self registration'}
        </TextWrapper>
        <Button loading={registration.isSaving} onClick={initiateRegistration}>
          {' '}
          Register Now
        </Button>
      </>
    );
  };

  const registrationError = () => {
    return (
      <Message>There is an error retrieving the registration status.</Message>
    );
  };

  const renderMyappsError = () => {
    const { myapps, myappsErrors } = props;
    return (
      <Message
        actions={[
          <Button onClick={handleRetry} data-test-id="myapps-retry">
            Retry
          </Button>,
        ]}
        error
        data-test-id="partial-retrieval-error"
      >
        {getErrorMessage(myappsErrors, myapps)}
      </Message>
    );
  };
  const renderDeactivateUserMessage = (
    orgName: string,
    adminEmail: string,
    shouldShowInstructionsPopUp: boolean
  ) => {
    const { siteDomain, noAppsMessage } = props;
    return (
      <StyledDialog data-test-id="deactivate-no-apps-message">
        {noAppsMessage || (
          <>
            Your account has been disabled due to inactivity. Click
            <CustomLink underline onClick={handleRequestAccessClick}>
              here
            </CustomLink>
            to submit a request to reactivate your account.
          </>
        )}
        <AccessRequestDialogContainer
          dialogTitle={UserAccessRequest.REACTIVATE_POPUP_TITLE}
          accessReason={UserAccessRequest.DEFAULT_REACTIVATION_REASON}
          orgName={orgName}
          orgUrl={siteDomain}
          templateName={UserAccessRequest.REACTIVATE_ACCESS_TEMPLATE_NAME}
          adminEmail={adminEmail}
          shouldShowInstructionsPopUp={shouldShowInstructionsPopUp}
        />
      </StyledDialog>
    );
  };

  const renderGenericNoAppMessage = () => {
    const { noAppsMessage } = props;
    return (
      <StyledDialog data-test-id="generic-no-apps-message">
        {noAppsMessage || (
          <>
            You have not been provisioned any applications or your account might
            be inactive. Please contact your administrator or support team.
          </>
        )}
      </StyledDialog>
    );
  };

  const renderNoAppMessageForEnvironmentChange = () => {
    return (
      <StyledDialog data-test-id="generic-no-apps-message-environment">
        {<>No applications available for selected environment.</>}
      </StyledDialog>
    );
  };

  const getFilteredAccount = () => {
    const { activeOrgId, userAccounts } = props;
    return userAccounts.length > 1
      ? userAccounts?.find(a => a.orgClaim && a.orgClaim.orgId === activeOrgId)
      : userAccounts[0];
  };

  const getOrgName = (filteredAccount: UserAccount) => {
    const { tenancy } = props;
    return filteredAccount.orgClaim &&
      !SELF_REGISTRATION_SUPPORTED_TENANCIES.includes(tenancy)
      ? filteredAccount.orgClaim.name
      : '';
  };

  const renderUnAuthorizedNoAppMessage = () => {
    return (
      <ButtonWrapper data-test-id="self-registration">
        {isSelfRegistration()
          ? renderSelfRegistration()
          : renderNoSelfRegistration()}
      </ButtonWrapper>
    );
  };
  const renderAuthorizedNoAppMessage = (userAccounts: UserAccount[]) => {
    const filteredAccount = getFilteredAccount();
    const orgName = getOrgName(filteredAccount);
    const filteredAccountWhereSecureFlagIsTrue = userAccounts.find(
      a => a.secureFlag === true
    );
    const adminEmail = filteredAccount.adminEmail;
    if (
      filteredAccount.isInternalUser === false &&
      filteredAccount.accountStatusCode === 'D'
    ) {
      return renderDeactivateUserMessage(orgName, adminEmail, false);
    } else if (
      (filteredAccount.accountStatusCode === 'D' ||
        filteredAccount.accountStatusCode === 'R') &&
      filteredAccountWhereSecureFlagIsTrue?.accountStatusCode === 'A' &&
      filteredAccountWhereSecureFlagIsTrue?.hasSecureNontaRole === true
    ) {
      return renderDeactivateUserMessage(orgName, adminEmail, false);
    } else if (
      (filteredAccount.accountStatusCode === 'D' ||
        filteredAccount.accountStatusCode === 'R') &&
      (filteredAccountWhereSecureFlagIsTrue?.hasSecureNontaRole === false ||
        filteredAccountWhereSecureFlagIsTrue?.accountStatusCode === 'R')
    ) {
      return renderDeactivateUserMessage(orgName, adminEmail, true);
    } else {
      return renderGenericNoAppMessage();
    }
  };

  const checkForSpecificNoAppScenario = (userAccounts: UserAccount[]) => {
    return !userAccounts || userAccounts.length === 0
      ? renderUnAuthorizedNoAppMessage()
      : renderAuthorizedNoAppMessage(userAccounts);
  };

  const renderNoApps = () => {
    const { enableRegister, userAccounts, siteProvisioningStores } = props;
    return (
      <Message data-test-id="no-apps-message">
        {enableRegister ? (
          <ButtonWrapper>
            {registration.error
              ? registrationError()
              : registration.resume
              ? renderResumeRegistration()
              : renderRegistration()}
          </ButtonWrapper>
        ) : env.accessMessageEnabled !== undefined &&
          env.accessMessageEnabled &&
          (siteProvisioningStores || []).includes(2) ? (
          checkForSpecificNoAppScenario(userAccounts)
        ) : (
          renderGenericNoAppMessage()
        )}
      </Message>
    );
  };

  const renderApps = () => {
    const {
      myapps,
      activeOrgId,
      siteDomain,
      user,
      enableAppEnvironment,
    } = props;

    const richAppTiles = getTiles(
      myapps,
      'embedded',
      siteDomain,
      enableAppEnvironment,
      appEnv
    );

    const standardAppTiles = getTiles(
      myapps,
      'standard',
      siteDomain,
      enableAppEnvironment,
      appEnv,
      activeOrgId
    );

    return (
      <Apps data-test-id="app-lists">
        {standardAppTiles.length === 0 && richAppTiles.length === 0 ? (
          renderNoAppMessageForEnvironmentChange()
        ) : (
          <>
            <RichAppList tiles={richAppTiles} />
            <StandardAppListDummy
              tiles={standardAppTiles}
              handleClick={handleClick}
              impersonationActive={!!user.profile.superuser}
              borderColor={lineColor}
              cardStyle={cardStyle}
            />
          </>
        )}
      </Apps>
    );
  };

  const renderContent = () => {
    const {
      myappsErrors,
      myapps,
      myappsMeta: { pending: fetchAppsPending, successful, error },
    } = props;
    if (fetchAppsPending || registration.isFetching) {
      return <Loading message="Loading apps" />;
    } else if (successful) {
      // Show if any upstream errors are reported.
      const errors = myappsErrors.length > 0 && renderMyappsError();

      // Show if no apps AND no upstream errors
      const noApps =
        myapps.length === 0 && myappsErrors.length === 0 && renderNoApps();

      // Show if any apps
      const appsList = myapps.length > 0 && renderApps();
      if (errors) {
        // tracking.trackEvent({
        //   action: 'myapps_loadfailure',
        //   error: getErrorMessage(myappsErrors, myapps),
        // });
      }
      return (
        <>
          {errors}
          {noApps}
          {appsList}
        </>
      );
    } else if (error) {
      return (
        <Message
          error
          actions={[
            <Button onClick={handleRetry} data-test-id="myapps-retry">
              Retry
            </Button>,
          ]}
        >
          {error || 'Error loading apps'}
        </Message>
      );
    }

    return null;
  };

  const renderOrgSelector = () => {
    const { activeOrgId, orgs } = props;

    if (orgs.length > 0) {
      const active = orgs.find(org => org.orgId === activeOrgId);

      return (
        <SelectWrapper>
          <SelectLabel
            data-test-id="org-caption"
            variant="caption"
            gutterBottom
          >
            Organization
          </SelectLabel>
          <FlexSelect
            width={setOrgSelectWidth()}
            onChange={handleOrgChange}
            value={active.orgId}
            disabled={orgs.length === 0}
            data-test-id="org-select"
          >
            {orgs.map(org => (
              <MenuItem
                id={org.orgId}
                value={org.orgId}
                style={{ fontFamily: 'OptumSans', cursor: 'default' }}
              >
                {org.name}
              </MenuItem>
            ))}
          </FlexSelect>
        </SelectWrapper>
      );
    }
  };

  const renderEnvironmentSelector = () => {
    const {
      enableAppEnvironment,
      appsEnvironmentList,
    } = props.widgetState.config;
    // for existing sites appsEnvironmentList will be undefined which will break to render the appTiles if this doesnt checked
    const environmentList =
      typeof appsEnvironmentList !== 'undefined'
        ? appsEnvironmentList
            .split(',')
            .map(value => value.trim())
            .filter(value => value !== '')
            .map(str => {
              return str.charAt(0).toUpperCase() + str.slice(1);
            })
        : [];

    if (enableAppEnvironment && environmentList.length > 0) {
      return (
        <SelectWrapper>
          <SelectLabel
            data-test-id="env-caption"
            variant="caption"
            gutterBottom
          >
            Environment
          </SelectLabel>
          <FlexSelect
            width={setOrgSelectWidth()}
            onChange={handleEnvironmentChange}
            disabled={environmentList.length === 0}
            data-test-id="env-select"
            value={appEnv}
          >
            {environmentList.map(it => (
              <MenuItem value={it} style={{ fontFamily: 'OptumSans' }}>
                {it}
              </MenuItem>
            ))}
          </FlexSelect>
        </SelectWrapper>
      );
    }
  };

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  // Define other functions and JSX components as required
  return (
    <Wrapper
      style={{
        marginTop: '0',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingTop: `${paddingData?.top}px`,
        paddingRight: `${paddingData?.right}px`,
        paddingBottom: `${paddingData?.bottom}px`,
        paddingLeft: `${paddingData?.left}px`,
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderColor: `${lineColor}`,
        borderRadius: `${borderData?.radius}px`,
        height: '100%',
        width: '100%',
        fontFamily: font,
      }}
    >
      <Header>
        {renderOrgSelector()}
        {renderEnvironmentSelector()}
      </Header>
      <Apps>{renderContent()}</Apps>
    </Wrapper>
  );
};

export default MyApps;
