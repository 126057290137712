import React from 'react';
import styled from 'styled-components';
import { Panel, Label } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import { linkDeveloperTheme as theme } from 'link-ui-react';

import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';

import { Typography } from 'builder/components/WidgetConfigurations/Typography/Typography';
import {
  Background,
  BackgroundData,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';

export const StyledPanelTitle = {
  fontFamily: 'OptumSans',
  fontSize: '14px',
  color: '#1d1929',
  fontWeight: '700',
  marginLeft: '0.8em',
};

export const PanelTop = styled.div`
  .cGdfIs {
    padding: 0 2rem 3.5rem 1.5rem;
  }
`;

export const StyledPanel = styled.div`
  & > div {
    border-radius: 0;
  }
  & button[class^='Panelcomponent__HeaderSection'] {
    height: 2.5em;
    background: rgba(141, 145, 160, 0.07);
    border-radius: 0;
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
  & span[class^='Panelcomponent__HeaderMainContent'] {
    height: 2.5em;
  }
  & div[class^='uitk-panel Panelcomponent__Panel'] {
    margin-bottom: 0;
    border: none;
  }
  & div[class^='Panelcomponent__Content'] {
    padding: 1.5rem;
  }
`;

export const SizeLocationPanel = styled(Panel)`
  > div {
    padding: 1rem;
  }
`;

const TextWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const {
    value,
    onChange,
    widgetState,
    pages,
    currentPage: { layoutLocked },
  } = props;
  const handleBoldData = data => {
    onChange({
      ...value,
      bold: data,
      iconBackgroundColor: theme.colors.aux.skyBlue,
    });
  };
  const handleItalic = data => {
    onChange({
      ...value,
      italic: data,
      iconBackgroundColor: theme.colors.aux.skyBlue,
    });
  };
  const handleLeftAlignData = data => {
    onChange({
      ...value,
      align: data,
      iconBackgroundColor: theme.colors.aux.skyBlue,
    });
  };
  const handleCenterAlignData = data => {
    onChange({
      ...value,
      align: data,
      iconBackgroundColor: theme.colors.aux.skyBlue,
    });
  };
  const handleRightAlignData = data => {
    onChange({
      ...value,
      align: data,
      iconBackgroundColor: theme.colors.aux.skyBlue,
    });
  };
  const handleTextColorData = data => {
    onChange({
      ...value,
      textColor: data,
      iconBackgroundColor: theme.colors.aux.skyBlue,
    });
  };
  const handleTextHighlightColorData = data => {
    onChange({
      ...value,
      textHighlightColor: data,
      iconBackgroundColor: theme.colors.aux.skyBlue,
    });
  };

  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };

  const handleBackgroundColor = (data: string) => {
    onChange({
      ...value,
      backgroundData: {
        ...value.backgroundData,
        color: data,
      },
    });
  };

  const updateWidgetSizeLocation = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const updateBackgroundData = (data: BackgroundData) => {
    onChange({
      ...value,
      backgroundData: data,
    });
  };
  const updateBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };
  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };

  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            key={'typography-panel'}
            title={<Label style={StyledPanelTitle}>Typography</Label>}
            icon={Arrow}
            data-test-id="typography-panel"
          >
            <PanelTop>
              <Typography
                data-test-id="typography-component"
                disabled={layoutLocked}
                widgetState={props.widgetState}
                boldData={handleBoldData}
                handleFontSize={data => {
                  onChange({
                    ...value,
                    fontSize: data,
                  });
                }}
                handleFont={data => {
                  onChange({
                    ...value,
                    font: data,
                  });
                }}
                handleItalicData={handleItalic}
                handleUnderLine={data => {
                  onChange({
                    ...value,
                    underline: data,
                    strikeThrough: data ? !data : data,
                    iconBackgroundColor: theme.colors.aux.skyBlue,
                  });
                }}
                handleStrikeThrough={data => {
                  onChange({
                    ...value,
                    strikeThrough: data,
                    underline: data ? !data : data,
                    iconBackgroundColor: theme.colors.aux.skyBlue,
                  });
                }}
                leftAlignData={handleLeftAlignData}
                centerAlignData={handleCenterAlignData}
                rightAlignData={handleRightAlignData}
                handleLineSpacing={data => {
                  onChange({
                    ...value,
                    lineSpacing: data,
                  });
                }}
                handleCharacterSpacing={data => {
                  onChange({
                    ...value,
                    characterSpacing: data,
                  });
                }}
                site={props.site}
                handleTextColor={handleTextColorData}
                handleTextHighlightColor={handleTextHighlightColorData}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                pages={pages}
              />
            </PanelTop>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            key={'size-location-panel'}
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="authentication-panel"
          >
            <WidgetSizeLocation
              disabled={layoutLocked}
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLocation={updateWidgetSizeLocation}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <PanelTop>
            <Panel
              key={'text-background-panel'}
              title={<Label style={StyledPanelTitle}>Background</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="text-background-panel"
            >
              <Background
                data-test-id="background-component"
                disabled={layoutLocked}
                backgroundData={updateBackgroundData}
                backgroundImage={updateBackgroundImage}
                widgetState={widgetState}
                handleLineColor={handleLineColor}
                handleBackgroundColor={handleBackgroundColor}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
              />
            </Panel>
          </PanelTop>
        </StyledPanel>
        <StyledPanel>
          <PanelTop>
            <Panel
              key={'border-panel'}
              title={<Label style={StyledPanelTitle}>Border</Label>}
              initiallyClosed
              icon={Arrow}
            >
              <Border
                data-test-id="border-data"
                disabled={layoutLocked}
                borderData={updateBorderData}
                handleLineColor={handleLineColor}
                widgetState={widgetState}
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
              />
            </Panel>
          </PanelTop>
        </StyledPanel>
        <StyledPanel>
          <PanelTop>
            <Panel
              key="padding-panel"
              title={<Label style={StyledPanelTitle}>Padding</Label>}
              initiallyClosed
              icon={Arrow}
            >
              <Padding
                data-test-id="padding-data"
                disabled={layoutLocked}
                paddingData={updatePaddingData}
                widgetState={widgetState}
              />
            </Panel>
          </PanelTop>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default TextWidgetControl;
