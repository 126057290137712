import React from 'react';
import styled from 'styled-components';
import { Panel, Label } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config, DateSelection } from './types';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import {
  BackgroundData,
  Background,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import { BannerGeneralConfig } from 'builder/components/WidgetConfigurations/BannerGeneralConfig/BannerGeneralConfig';
import { StyledPanel, StyledPanelTitle } from '../Text/TextWidgetControl';

const SizeLocationPanel = styled(Panel)`
  > div {
    padding: 1rem;
  }
`;

const BannerWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const {
    value,
    onChange,
    widgetState,
    updateWidgetPosition,
    currentPage: { layoutLocked },
  } = props;

  const updateWidgetSizeLocation = (sizeLoc: SizeLocationData) => {
    handleWidgetPosition(widgetState.id, sizeLoc.height, value.height);
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };

  const handleWidgetPosition = (
    id: string,
    height: number,
    oldHeight: number
  ) => {
    updateWidgetPosition(id, height, oldHeight);
  };

  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };

  const updateBackgroundData = (data: BackgroundData) => {
    onChange({
      ...value,
      backgroundData: data,
    });
  };

  const updateBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };

  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };

  const handleBackgroundColor = (data: string) => {
    onChange({
      ...value,
      backgroundData: {
        ...value.backgroundData,
        color: data,
      },
    });
  };

  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };

  const onChangeBrowserList = (data: string) => {
    onChange({
      ...value,
      browserList: data,
    });
  };

  const onChangeBrowserCondition = (data: boolean) => {
    onChange({
      ...value,
      browserCondition: data,
    });
  };

  const onChangeDateSelection = (data: DateSelection) => {
    onChange({
      ...value,
      dateSelection: data,
    });
  };

  const handleFullScreen = (data: boolean) => {
    onChange({
      ...value,
      enableFullScreen: data,
    });
  };

  return (
    <Panel.Group>
      <StyledPanel>
        <Panel
          title={<Label style={StyledPanelTitle}>General</Label>}
          icon={Arrow}
          data-test-id="general-panel"
        >
          <BannerGeneralConfig
            disabled={layoutLocked}
            showonAllpage={widgetState.config.showonAllPage}
            browserCondition={widgetState.config.browserCondition}
            browserList={widgetState.config.browserList}
            onChangeBrowserList={onChangeBrowserList}
            onChangeBrowserCondition={onChangeBrowserCondition}
            onChangeDateSelection={onChangeDateSelection}
            dateSelectionRange={widgetState.config.dateSelection}
            handleFullScreen={handleFullScreen}
            fullScreenEnabled={widgetState.config.enableFullScreen}
            handleWidgetPosition={handleWidgetPosition}
            widgetState={widgetState}
            removeWidget={props.removeWidget}
            currentPage={props.currentPage}
          />
        </Panel>
      </StyledPanel>
      <StyledPanel>
        <SizeLocationPanel
          title={<Label style={StyledPanelTitle}>Size and Location</Label>}
          initiallyClosed
          icon={Arrow}
          data-test-id="sizeandlocation-panel"
        >
          <WidgetSizeLocation
            data-test-id="widget-size-location"
            disabled={layoutLocked}
            activeWidgetId={props.widgetState.id}
            sizeLocation={{
              xLocation: value.xLocation,
              yLocation: value.yLocation,
              width: value.width,
              height: value.height,
              uniqueId: value.uniqueId,
            }}
            updateWidgetSizeLocation={updateWidgetSizeLocation}
          />
        </SizeLocationPanel>
      </StyledPanel>
      <StyledPanel>
        <Panel
          title={<Label style={StyledPanelTitle}>Background</Label>}
          initiallyClosed
          icon={Arrow}
          data-test-id="background-banner-panel"
        >
          <Background
            data-test-id="background-component"
            disabled={layoutLocked}
            backgroundData={updateBackgroundData}
            backgroundImage={updateBackgroundImage}
            widgetState={widgetState}
            handleLineColor={handleLineColor}
            handleBackgroundColor={handleBackgroundColor}
            site={props.site}
            updateSite={props.updateSite}
            saveSite={props.saveSite}
          />
        </Panel>
      </StyledPanel>
      <StyledPanel>
        <Panel
          title={<Label style={StyledPanelTitle}>Border</Label>}
          initiallyClosed
          icon={Arrow}
          data-test-id="border-panel"
        >
          <Border
            data-test-id="border-component"
            disabled={layoutLocked}
            borderData={updateBorderData}
            handleLineColor={handleLineColor}
            widgetState={widgetState}
            pages={props.pages}
            site={props.site}
            updateSite={props.updateSite}
            saveSite={props.saveSite}
          />
        </Panel>
      </StyledPanel>
      <StyledPanel>
        <Panel
          title={<Label style={StyledPanelTitle}>Padding</Label>}
          initiallyClosed
          icon={Arrow}
        >
          <Padding
            data-test-id="padding-data"
            disabled={layoutLocked}
            paddingData={updatePaddingData}
            widgetState={widgetState}
          />
        </Panel>
      </StyledPanel>
    </Panel.Group>
  );
};

export default BannerWidgetControl;
