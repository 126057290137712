import React, { ReactElement } from 'react';
import { Config } from './types';
import styled from 'styled-components';
import Calendar from './Calendar';
import { RootState } from 'shared/state/reducer';
import { useSelector } from 'react-redux';

const StyledCalendar = styled.div<{
  backgroundData: any;
  backgroundImage: any;
  borderData: any;
  lineColor: string;
}>`
  margin-top: 0;
  background-image: ${p =>
    p.backgroundData?.style === 'Image'
      ? `url('${p.backgroundImage?.url}')`
      : ''};
  background-color: ${p =>
    p.backgroundData?.color !== '' ? p.backgroundData?.color : ''};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-width: ${p => p.borderData?.thickness}px;
  border-style: solid;
  border-color: ${p => p.lineColor};
  border-radius: ${p => p.borderData?.radius}px;
  height: 100%;
  width: 100%;
  box-shadow: none;
`;

const CalendarWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    backgroundData,
    backgroundImage,
    borderData,
    lineColor,
  } = props.widgetState.config;

  if (
    props.widgetState.config.visible === false ||
    props.widgetState.config.isCustomWidget
  ) {
    return <></>;
  }

  const published = useSelector((state: RootState) => state.published);

  const widgetId = props.widgetState.id;

  return (
    <StyledCalendar
      backgroundData={backgroundData}
      backgroundImage={backgroundImage}
      borderData={borderData}
      lineColor={lineColor}
      data-test-id="calendar-widget"
    >
      {/* Calendar content */}
      <Calendar
        width={props.widgetState.config.width}
        height={props.widgetState.config.height}
        accentColor={props.widgetState.config.calendarAccentColor}
        fontFamily={props.widgetState.config.fontFamily}
        editing={props.editing}
        widgetId={widgetId}
        published={published}
        data-test-id="calendar"
      />
    </StyledCalendar>
  );
};

export default CalendarWidget;
