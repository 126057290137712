import React, { useState, useRef, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Config, SearchResult } from './types';
import { IconX } from '@tabler/icons-react';
import { BorderData } from 'builder/components/WidgetConfigurations/Border';
import { PaddingData } from 'builder/components/WidgetConfigurations/Padding';
import uuidv4 from 'uuid/v4';

const SearchContainer = styled.div`
  position: relative;
  max-width: 600px;
  margin: 20px auto;
`;

const SearchInput = styled.input<{
  borderColor: string;
  background: string;
  textColor: string;
  borderData: BorderData;
  paddingData: PaddingData;
  font: string;
}>`
  width: 100%;
  padding: 12px 40px;
  border-radius: ${props => props.borderData.radius}px;
  border: ${props => props.borderData.thickness}px solid
    ${props => props.borderColor || '#444'};
  background: ${props => props.background || '#222'};
  color: ${props => props.textColor || '#fff'};
  padding-top: ${props => props.paddingData?.top}px;
  padding-right: ${props => props.paddingData?.right}px;
  padding-bottom: ${props => props.paddingData?.bottom}px;
  padding-left: ${props => props.paddingData?.left}px;
  font-size: 14px;
  outline: none;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-family: ${props => props.font || 'sans-serif'};
  &::placeholder {
    color: #aaa;
  }

  &:focus {
    border-color: #1e90ff;
    box-shadow: 0 0 10px rgba(30, 144, 255, 0.6);
  }
`;

const SearchResultsContainer = styled.div<{
  background: string;
  borderColor: string;
  font: string;
}>`
  position: absolute;
  width: 100%;
  background: ${props => props.background || '#222'};
  border-radius: 8px;
  margin-top: 5px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  max-height: 390px;
  overflow-y: auto;
  z-index: 1000;
  border: 1px solid ${props => props.borderColor || '#444'};
  font-family: ${props => props.font || 'sans-serif'};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  color: #bbb;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

const ResultItem = styled.a<{ textColor: string; hoverColor: string }>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: ${props => props.textColor || '#ddd'};
  padding: 12px;
  border-bottom: 1px solid #444;
  transition: background 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.hoverColor || '#333'};
  }
`;

const Category = styled.div`
  font-size: 12px;
  color: #888;
  text-transform: uppercase;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
  color: #1e90ff;

  &:hover {
    text-decoration: underline;
  }
`;

const Description = styled.div`
  font-size: 14px;
  color: #ccc;
`;

const SeeAllButton = styled.div`
  padding: 12px;
  text-align: center;
  cursor: pointer;
  color: #1e90ff;
  font-weight: bold;
  transition: background 0.2s ease-in-out;

  &:hover {
    background-color: #333;
  }
`;

const SearchBarWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    borderData,
    backgroundData,
    lineColor,
    textColor,
    paddingData,
    font,
  } = props.widgetState.config;

  const { defaultResults } = props.widgetState.config;
  const { siteId } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState<SearchResult[]>(
    defaultResults
  );
  const [showResults, setShowResults] = useState(false);
  const history = useHistory();
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (debounceRef.current) clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      if (query.trim() === '') {
        setFilteredResults(defaultResults);
      } else {
        const filtered = defaultResults.filter(result =>
          result.title.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredResults(filtered);
      }
    }, 300);
  };

  const handleCloseResults = () => {
    setShowResults(false);
  };

  const handleFocus = () => setShowResults(true);

  const handleSeeAll = () => {
    if (searchQuery.trim()) {
      history.push(
        `/sites/${siteId}/search-results?q=${encodeURIComponent(
          searchQuery.trim()
        )}`
      );
    } else {
      history.push(`/sites/${siteId}/search-results`);
    }
  };

  return (
    <SearchContainer>
      <SearchInput
        data-test-id="search-input"
        type="text"
        placeholder="Search or jump to..."
        value={searchQuery}
        onChange={handleInputChange}
        onFocus={handleFocus}
        borderColor={lineColor}
        background={
          backgroundData?.style === 'Color' ? backgroundData.color : '#222'
        }
        textColor={textColor || '#fff'} // Ensure white text color in dark mode
        borderData={borderData}
        paddingData={paddingData}
        font={font}
      />

      {showResults && (
        <SearchResultsContainer
          data-test-id="search-results-container"
          background={
            backgroundData?.style === 'Color' ? backgroundData.color : '#222'
          }
          borderColor={lineColor}
          font={font}
        >
          <CloseButton data-test-id="close-button" onClick={handleCloseResults}>
            <IconX />
          </CloseButton>
          {filteredResults.length > 0 ? (
            filteredResults.map(result => (
              <ResultItem
                key={uuidv4()}
                href={result.link}
                textColor={'#fff'}
                hoverColor={'#333'}
              >
                <Category>{result.category}</Category>
                <Title>{result.title}</Title>
                <Description>{result.description}</Description>
              </ResultItem>
            ))
          ) : (
            <Description
              data-test-id="no-results-found"
              style={{
                textAlign: 'center',
                padding: '10px',
                color: textColor || '#fff',
              }}
            >
              No results found
            </Description>
          )}
          {filteredResults.length > 0 && (
            <SeeAllButton data-test-id="see-all-results" onClick={handleSeeAll}>
              See all results ({filteredResults.length})
            </SeeAllButton>
          )}
        </SearchResultsContainer>
      )}
    </SearchContainer>
  );
};

export default SearchBarWidget;
