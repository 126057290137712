import { ActionCreator, AnyAction, Reducer } from 'redux';
import { toast } from 'react-toastify';
import { RootState, Selector, ThunkActionCreator } from 'shared/state';
import {
  createTemplate,
  deleteTemplate,
  fetchTemplates,
  getTemplateById,
  updateTemplate,
} from 'shared/api/pageTemplate';
import { TEMPLATE_STATUS } from '../../../builder/scenes/PageTemplates/constants';

// Setup types
export type PageTemplate = {
  createdByUserName: string;
  updatedByUserName: string;
  id: number;
  title: string;
  description: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  tenancies: Array<string>;
  layoutDefinitions: string;
  layoutLocked: boolean;
};

export type PageTemplatePostRequestBody = Pick<
  PageTemplate,
  'title' | 'description' | 'tenancies' | 'layoutDefinitions' | 'layoutLocked'
>;

export type PageTemplatePatchRequestBody = Pick<
  PageTemplate,
  'title' | 'description' | 'layoutLocked'
> & {
  id?: number;
  layoutDefinitions?: string;
};

export type PageTemplateState = {
  data: {
    pageTemplates: Array<PageTemplate>;
    size: number;
  };
  meta: StateMeta;
  selectedPageTemplate: PageTemplate | null;
  isEditingTemplate: boolean;
  limit: number;
  offset: number;
};

export const defaultState: PageTemplateState = {
  data: {
    pageTemplates: [],
    size: 0,
  },
  meta: {
    pending: false,
    error: null,
  },
  selectedPageTemplate: null,
  isEditingTemplate: false,
  limit: 25,
  offset: 0,
};

// Setup actions
const FETCH_PAGE_TEMPLATES_FULFILLED = 'duplo/FETCH_PAGE_TEMPLATES_FULFILLED';
const FETCH_PAGE_TEMPLATES_PENDING = 'duplo/FETCH_PAGE_TEMPLATES_PENDING';
const FETCH_PAGE_TEMPLATES_FAILED = 'duplo/FETCH_PAGE_TEMPLATES_FAILED';

const CREATE_PAGE_TEMPLATE_PENDING = 'duplo/CREATE_PAGE_TEMPLATE_PENDING';
const CREATE_PAGE_TEMPLATE_FULFILLED = 'duplo/CREATE_PAGE_TEMPLATE_FULFILLED';
const CREATE_PAGE_TEMPLATE_FAILED = 'duplo/CREATE_PAGE_TEMPLATE_FAILED';

const UPDATE_PAGE_TEMPLATE_PENDING = 'duplo/UPDATE_PAGE_TEMPLATE_PENDING';
const UPDATE_PAGE_TEMPLATE_FULFILLED = 'duplo/UPDATE_PAGE_TEMPLATE_FULFILLED';
const UPDATE_PAGE_TEMPLATE_FAILED = 'duplo/UPDATE_PAGE_TEMPLATE_FAILED';

const SET_SELECTED_PAGE_TEMPLATE = 'duplo/SET_SELECTED_PAGE_TEMPLATE';

const FETCH_TEMPLATE_BY_ID_PENDING = 'duplo/FETCH_TEMPLATE_BY_ID_PENDING';
const FETCH_TEMPLATE_BY_ID_FULFILLED = 'duplo/FETCH_TEMPLATE_BY_ID_FULFILLED';
const FETCH_TEMPLATE_BY_ID_FAILED = 'duplo/FETCH_TEMPLATE_BY_ID_FAILED';

const DELETE_PAGE_TEMPLATE_PENDING = 'duplo/DELETE_PAGE_TEMPLATE_PENDING';
const DELETE_PAGE_TEMPLATE_FULFILLED = 'duplo/DELETE_PAGE_TEMPLATE_FULFILLED';
const DELETE_PAGE_TEMPLATE_FAILED = 'duplo/DELETE_PAGE_TEMPLATE_FAILED';

const CLEAR_PAGE_TEMPLATE_META = 'duplo/CLEAR_PAGE_TEMPLATE_META';

export const SET_IS_EDITING_TEMPLATE = 'duplo/SET_IS_EDITING_TEMPLATE';

const SET_LIMIT = 'duplo/SET_LIMIT';
const SET_OFFSET = 'duplo/SET_OFFSET';

// Setup reducer
const reducer: Reducer<PageTemplateState> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_PAGE_TEMPLATES_PENDING:
    case FETCH_TEMPLATE_BY_ID_PENDING:
    case CREATE_PAGE_TEMPLATE_PENDING:
    case UPDATE_PAGE_TEMPLATE_PENDING:
    case DELETE_PAGE_TEMPLATE_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_PAGE_TEMPLATES_FULFILLED:
      return {
        ...state,
        data: {
          pageTemplates: action.pageTemplates,
          size: action.pageTemplates.length,
        },
        meta: {
          ...state.meta,
          pending: false,
          error: null,
        },
      };
    case FETCH_PAGE_TEMPLATES_FAILED:
      return {
        ...state,
        data: {
          pageTemplates: [],
          size: 0,
        },
        meta: {
          ...state.meta,
          pending: false,
          error: action.error,
        },
      };
    case CREATE_PAGE_TEMPLATE_FULFILLED:
      return {
        ...state,
        data: {
          pageTemplates: [...state.data.pageTemplates, action.pageTemplate],
          size: state.data.pageTemplates.length + 1,
        },
        meta: {
          ...state.meta,
          pending: false,
          error: null,
        },
      };
    case CREATE_PAGE_TEMPLATE_FAILED:
    case UPDATE_PAGE_TEMPLATE_FAILED:
    case FETCH_TEMPLATE_BY_ID_FAILED:
    case DELETE_PAGE_TEMPLATE_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          error: action.error,
        },
      };
    case UPDATE_PAGE_TEMPLATE_FULFILLED:
      return {
        ...state,
        data: {
          pageTemplates: [
            ...state.data.pageTemplates.map(template =>
              template.id === action.pageTemplate.id
                ? action.pageTemplate
                : template
            ),
          ],
          size: state.data.pageTemplates.length,
        },
        meta: {
          ...state.meta,
          pending: false,
          error: null,
        },
        selectedPageTemplate: state.isEditingTemplate
          ? action.pageTemplate
          : state.selectedPageTemplate,
      };
    case SET_SELECTED_PAGE_TEMPLATE:
      return {
        ...state,
        selectedPageTemplate:
          state.data.pageTemplates.find(
            template => template.id === action.pageTemplateId
          ) || null,
      };
    case FETCH_TEMPLATE_BY_ID_FULFILLED:
      return {
        ...state,
        selectedPageTemplate: action.pageTemplate,
        meta: {
          ...state.meta,
          pending: false,
          error: null,
        },
      };
    case DELETE_PAGE_TEMPLATE_FULFILLED:
      const filteredPageTemplates = state.data.pageTemplates.filter(
        template => template.id !== action.pageTemplateId
      );
      return {
        ...state,
        data: {
          pageTemplates: filteredPageTemplates,
          size: filteredPageTemplates.length,
        },
        meta: {
          ...state.meta,
          pending: false,
          error: null,
        },
        selectedPageTemplate: null,
      };
    case CLEAR_PAGE_TEMPLATE_META:
      return {
        ...state,
        meta: {
          pending: false,
          error: null,
        },
      };
    case SET_IS_EDITING_TEMPLATE:
      return {
        ...state,
        isEditingTemplate: action.isEditing,
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: action.limit,
        offset: 0,
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.offset,
      };
    default:
      return state;
  }
};

// Setup action creators
export const fetchPageTemplatesPending: ActionCreator<AnyAction> = () => {
  return {
    type: FETCH_PAGE_TEMPLATES_PENDING,
  };
};

export const fetchPageTemplatesFulfilled: ActionCreator<AnyAction> = (
  pageTemplates: Array<PageTemplate>
) => {
  return {
    type: FETCH_PAGE_TEMPLATES_FULFILLED,
    pageTemplates,
  };
};

export const fetchPageTemplatesFailed: ActionCreator<AnyAction> = (
  error: Error
) => {
  return {
    type: FETCH_PAGE_TEMPLATES_FAILED,
    error: error.message,
  };
};

export const fetchPageTemplates: ThunkActionCreator = (
  tenancies?: string[],
  limit?: number,
  offset?: number
) => async dispatch => {
  try {
    dispatch(fetchPageTemplatesPending());
    const serverStoredPageTemplates = await fetchTemplates(
      tenancies,
      limit,
      offset
    );
    dispatch(fetchPageTemplatesFulfilled(serverStoredPageTemplates));
  } catch (error) {
    dispatch(fetchPageTemplatesFailed(error));
  }
};

export const createPageTemplatePending: ActionCreator<AnyAction> = () => {
  return {
    type: CREATE_PAGE_TEMPLATE_PENDING,
  };
};

export const createPageTemplateFulfilled: ActionCreator<AnyAction> = (
  pageTemplate: PageTemplate
) => {
  return {
    type: CREATE_PAGE_TEMPLATE_FULFILLED,
    pageTemplate,
  };
};

export const createPageTemplateFailed: ActionCreator<AnyAction> = (
  error: Error
) => {
  return {
    type: CREATE_PAGE_TEMPLATE_FAILED,
    error: error.message,
  };
};

export const createPageTemplate: ThunkActionCreator = (
  template: PageTemplatePostRequestBody,
  userName: string
) => async dispatch => {
  try {
    dispatch(createPageTemplatePending());
    const data = await createTemplate(template);
    const newTemplate = {
      id: data.id,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      createdByUserName: userName,
      status: TEMPLATE_STATUS.DRAFT,
      ...template,
    };
    dispatch(createPageTemplateFulfilled(newTemplate));
    toast('Template created', { type: 'success', theme: 'colored' });
    return true;
  } catch (error) {
    toast('Failed to create template', {
      type: 'error',
      theme: 'colored',
    });
    dispatch(createPageTemplateFailed(error));
    return false;
  }
};

export const updatePageTemplatePending: ActionCreator<AnyAction> = () => {
  return {
    type: UPDATE_PAGE_TEMPLATE_PENDING,
  };
};

export const updatePageTemplateFulfilled: ActionCreator<AnyAction> = (
  pageTemplate: PageTemplate
) => {
  return {
    type: UPDATE_PAGE_TEMPLATE_FULFILLED,
    pageTemplate,
  };
};

export const updatePageTemplateFailed: ActionCreator<AnyAction> = (
  error: Error
) => {
  return {
    type: UPDATE_PAGE_TEMPLATE_FAILED,
    error: error.message,
  };
};

export const setSelectedPageTemplate: ActionCreator<AnyAction> = (
  pageTemplateId: string | number | null
) => {
  return {
    type: SET_SELECTED_PAGE_TEMPLATE,
    pageTemplateId,
  };
};

export const updatePageTemplate: ThunkActionCreator = (template: {
  id: number;
  title: string;
  description: string;
  layoutLocked: boolean;
  layoutDefinitions?: string;
  status?: string;
}) => async dispatch => {
  try {
    dispatch(updatePageTemplatePending());
    const updatedTemplate = await updateTemplate(template);
    dispatch(updatePageTemplateFulfilled(updatedTemplate));
    toast('Template updated', { type: 'success', theme: 'colored' });
    return true;
  } catch (error) {
    toast('Failed to update template', {
      type: 'error',
      theme: 'colored',
    });
    dispatch(updatePageTemplateFailed(error));
    dispatch(setSelectedPageTemplate(null));
    return false;
  }
};

export const duplicateTemplate: ThunkActionCreator = (
  templateId: number,
  userName: string
) => async dispatch => {
  try {
    dispatch(fetchTemplateByIdPending());
    const template: PageTemplate = await getTemplateById(templateId);
    dispatch(fetchTemplateByIdFulfilled(template));

    const newTemplate = {
      title: `${template.title} - COPY`,
      description: template.description,
      layoutLocked: template.layoutLocked,
      layoutDefinitions: template.layoutDefinitions,
      tenancies: template.tenancies,
      status: TEMPLATE_STATUS.DRAFT,
      createdByUserName: userName,
    };

    dispatch(createPageTemplatePending());
    const data = await createTemplate(newTemplate);
    const createdTemplate = {
      id: data.id,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      ...newTemplate,
    };
    dispatch(createPageTemplateFulfilled(createdTemplate));
    dispatch(setSelectedPageTemplate(null));
    toast('Template duplicated', { type: 'success', theme: 'colored' });
    return data;
  } catch (error) {
    toast('Failed to duplicate template', {
      type: 'error',
      theme: 'colored',
    });
    dispatch(fetchTemplateByIdFailed(error));
    dispatch(createPageTemplateFailed(error));
    dispatch(setSelectedPageTemplate(null));
    throw error;
  }
};

export const fetchTemplateByIdPending: ActionCreator<AnyAction> = () => {
  return {
    type: FETCH_TEMPLATE_BY_ID_PENDING,
  };
};

export const fetchTemplateByIdFulfilled: ActionCreator<AnyAction> = (
  pageTemplate: PageTemplate
) => {
  return {
    type: FETCH_TEMPLATE_BY_ID_FULFILLED,
    pageTemplate,
  };
};

export const fetchTemplateByIdFailed: ActionCreator<AnyAction> = (
  error: Error
) => {
  return {
    type: FETCH_TEMPLATE_BY_ID_FAILED,
    error: error.message,
  };
};

export const fetchTemplateById: ThunkActionCreator = (
  templateId: number
) => async dispatch => {
  try {
    dispatch(fetchTemplateByIdPending());
    const pageTemplate = await getTemplateById(templateId);
    dispatch(fetchTemplateByIdFulfilled(pageTemplate));
    return pageTemplate;
  } catch (error) {
    toast('Failed to fetch template', {
      type: 'error',
      theme: 'colored',
    });
    dispatch(fetchTemplateByIdFailed(error));
    return null;
  }
};

export const deletePageTemplatePending: ActionCreator<AnyAction> = () => {
  return {
    type: DELETE_PAGE_TEMPLATE_PENDING,
  };
};

export const deletePageTemplateFulfilled: ActionCreator<AnyAction> = (
  pageTemplateId: number
) => {
  return {
    type: DELETE_PAGE_TEMPLATE_FULFILLED,
    pageTemplateId,
  };
};

export const deletePageTemplateFailed: ActionCreator<AnyAction> = (
  error: Error
) => {
  return {
    type: DELETE_PAGE_TEMPLATE_FAILED,
    error: error.message,
  };
};

export const deletePageTemplate: ThunkActionCreator = (
  templateId: number
) => async dispatch => {
  try {
    dispatch(deletePageTemplatePending());
    await deleteTemplate(templateId);
    dispatch(deletePageTemplateFulfilled(templateId));
    toast('Template deleted', { type: 'success', theme: 'colored' });
    return true;
  } catch (error) {
    toast('Failed to delete template', {
      type: 'error',
      theme: 'colored',
    });
    dispatch(deletePageTemplateFailed(error));
    return false;
  }
};

export const clearPageTemplateMeta: ActionCreator<AnyAction> = () => {
  return {
    type: CLEAR_PAGE_TEMPLATE_META,
  };
};

export const setIsEditingTemplate: ActionCreator<AnyAction> = (
  isEditing: boolean
) => {
  return {
    type: SET_IS_EDITING_TEMPLATE,
    isEditing,
  };
};

export const setLimit: ActionCreator<AnyAction> = (limit: number) => {
  return {
    type: SET_LIMIT,
    limit,
  };
};

export const setOffset: ActionCreator<AnyAction> = (offset: number) => {
  return {
    type: SET_OFFSET,
    offset,
  };
};

// Setup selectors
const selectSlice: Selector<PageTemplateState> = () => state => {
  return state.pageTemplates;
};

export const selectPageTemplates: Selector<Array<
  PageTemplate
>> = () => state => {
  return selectSlice()(state).data.pageTemplates;
};

export const selectPageTemplatesByStatus = (
  state: RootState,
  status: string
): Array<PageTemplate> => {
  return state.pageTemplates.data.pageTemplates.filter(
    (template: PageTemplate) => template.status === status
  );
};

export const selectPageTemplatesMeta: Selector<StateMeta> = () => state => {
  return selectSlice()(state).meta;
};

export const selectSelectedPageTemplate: Selector<PageTemplate | null> = () => state => {
  return selectSlice()(state).selectedPageTemplate;
};

export const selectIsEditingTemplate: Selector<boolean> = () => state => {
  return selectSlice()(state).isEditingTemplate;
};

export const selectLimit: Selector<number> = () => state => {
  return selectSlice()(state).limit;
};

export const selectOffset: Selector<number> = () => state => {
  return selectSlice()(state).offset;
};

export const selectSize: Selector<number> = () => state => {
  return selectSlice()(state).data.size;
};

export default reducer;
