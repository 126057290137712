import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  IconAlertTriangle,
  IconBulb,
  IconHandStop,
  IconInfoSquare,
  IconPencilExclamation,
  IconQuestionMark,
  IconX,
} from '@tabler/icons-react';
import { HIDE_INLINE_NOTIFICATION_EXPIRY } from '../../constants';

export type NotificationType =
  | 'note'
  | 'info'
  | 'tip'
  | 'question'
  | 'danger'
  | 'warning';

export const TYPE_ICON_MAP: Record<
  NotificationType,
  React.ComponentType<any>
> = {
  note: IconPencilExclamation,
  info: IconInfoSquare,
  tip: IconBulb,
  question: IconQuestionMark,
  danger: IconHandStop,
  warning: IconAlertTriangle,
};

const borderColorMap = {
  note: '#1655b8',
  info: '#209cee',
  tip: '#007000',
  question: '#6b47d1',
  danger: '#c40900',
  warning: '#f5b702',
};

const backgroundColorMap = {
  note: '#e9f1ff',
  info: '#e0f4ff',
  tip: '#eff6ef',
  question: '#f0e6ff',
  danger: '#fcf0f0',
  warning: '#fef9ea',
};

const Notification = styled.div<{ notificationType: NotificationType }>`
  display: flex;
  align-items: center;
  font-size: 1rem;
  justify-content: space-between;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: ${({ notificationType }) =>
    backgroundColorMap[notificationType]};
  border: 1px solid
    ${({ notificationType }) => borderColorMap[notificationType]};
`;

const Message = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
`;

const CloseIcon = styled(IconX)`
  cursor: pointer;
  margin: 0.25rem 0.875rem;
  border-radius: 0.25rem;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const Button = styled.button<{ notificationType: NotificationType }>`
  background: none;
  border: none;
  color: ${({ notificationType }) => borderColorMap[notificationType]};
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 0.25rem;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const Actions = styled.div<{ dontShowAgain: boolean }>`
  min-width: ${({ dontShowAgain }) => (dontShowAgain ? '240px' : '48px')};
  display: flex;
  align-self: baseline;
`;

const IconWrapper = styled.div<{ notificationType: NotificationType }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: baseline;
  margin-right: 1rem;
  color: ${({ notificationType }) => borderColorMap[notificationType]};
`;

interface InlineNotificationProps {
  /**
   * Determines whether the "Don't show this again" button is displayed.
   * @default false
   */
  dontShowAgain?: boolean;

  /**
   * The unique identifier of the notification.
   */
  id: string | number;

  /**
   * The notification message to be displayed.
   */
  message: string;

  /**
   * The type of notification. It can be one of the following values:
   * 'note', 'info', 'tip', 'question', 'danger', 'warning'.
   */
  type: NotificationType;
}

const InlineNotification: React.FC<InlineNotificationProps> = ({
  dontShowAgain = false,
  id,
  message,
  type,
}) => {
  const [visible, setVisible] = useState(true);
  const IconComponent = TYPE_ICON_MAP[type];
  const localStorageKey = `notification-${type}-${message}-${id}`;

  useEffect(() => {
    const storedValue = localStorage.getItem(localStorageKey);
    if (storedValue) {
      const { expiry } = JSON.parse(storedValue);
      if (new Date().getTime() < expiry) {
        setVisible(false);
      }
    }
  }, [localStorageKey]);

  const handleDontShowAgain = () => {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({ expiry: HIDE_INLINE_NOTIFICATION_EXPIRY })
    );
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <Notification notificationType={type} aria-live="assertive" role="alert">
      <Message>
        <IconWrapper notificationType={type} aria-hidden="true">
          <IconComponent />
        </IconWrapper>
        {message}
      </Message>
      <Actions dontShowAgain={dontShowAgain}>
        {dontShowAgain && (
          <Button notificationType={type} onClick={handleDontShowAgain}>
            Don't show this again
          </Button>
        )}
        <div onClick={() => setVisible(false)} aria-label="Close notification">
          <CloseIcon />
        </div>
      </Actions>
    </Notification>
  );
};

export default InlineNotification;
