import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Label } from '@uitk/react/';
import { ArrowDropDownIcon } from '@uitk/react-icons';
import { Icon } from 'link-ui-react';
import {
  Button as MenuButton,
  Divider,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import config from 'builder/config';
import {
  Select,
  SelectChangeEvent,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import {
  StelliumData,
  stelliumRedirectionUrl,
} from 'shared/util/stelliumRedirection';
import { SiteListDialogType } from 'builder/components/SiteListDialog/SiteListDialog';
import SiteListDialog from 'builder/components/SiteListDialog';
import { horizonUhcTheme } from 'builder/scenes/SiteBuilder/SiteStylesPanel/BrandThemes';
import {
  IconGrid3x3,
  IconArrowBackUp,
  IconArrowForwardUp,
} from '@tabler/icons-react';
import { PageTemplate } from '../../../shared/state/ducks/pageTemplates';
import { compareLayoutDefinitions } from '../../scenes/PageTemplates/utils';
import { TEMPLATE_EDITOR_PAGE_ID } from '../../scenes/PageTemplates/constants';
import SiteUrlDropdown from '../SiteUrlDropdown/SiteUrlDropdown';
import { SwitchLovely } from 'builder/scenes/SiteBuilder/SwitchLovely';
import Tooltip from 'shared/components/Tooltip';

const TopNav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 4rem;
  border-bottom: 0.1rem solid #e0e0e0;
`;

const TopNavLeft = styled.div`
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

const TopNavRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const SelectWrapper = styled.div`
  padding-top: 0.5rem;
  & .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    display: block;
    font-family: OptumSans, sans-serif;
    border: none;
    border-radius: 0.25rem;
  }
  & > div > div {
    max-width: 10rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  & .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    fill: #717272;
  }
`;

const StyledManagePage = styled.div`
  cursor: pointer;
  font-family: OptumSans, sans-serif;
  font-size: 12pt;
  padding: 0.5rem;
  border-top: 1px solid #e0e0e0;
  color: #105bc8;
  text-align: center;
`;

const StyledArrow = styled(Icon)`
  width: 0.75rem;
  margin: 0.5rem;
`;

const ButtonWrapper = styled.div`
  padding-bottom: 0.75rem;
  display: contents;
  .uitk-button {
    margin-right: 0.625rem;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    font-family: OptumSans, sans-serif;
    font-weight: 700;
  }
`;

const StyledMenu = styled(Menu)`
  & .MuiMenuItem-root {
    font-family: OptumSans, sans-serif;
  }
  & .MuiTypography-body1 {
    font-family: OptumSans, sans-serif;
  }
  & .MuiList-padding {
    padding: 0;
  }
`;

const PublishMenu = styled(Menu)`
  margin-top: 0.5rem;
  & .MuiMenuItem-root {
    font-family: OptumSans, sans-serif;
  }
  & .MuiTypography-body1 {
    font-family: OptumSans, sans-serif;
  }
`;

const StyledPageHeading = styled.div`
  color: #000;
  font-family: OptumSans !important;
  font-size: 1rem;
  padding-bottom: 5px;
`;

const StyledMenuButton = styled.span<{ open: boolean }>`
  display: flex;
  border: ${p => (p.open ? '1px solid #e0e0e0' : '1px solid transparent')};
  border-radius: 0.25rem;
  background-color: ${p => (p.open ? 'rgb(251, 249, 244)' : 'transparent')};
  .MuiButton-root {
    font-family: OptumSans, sans-serif;
    text-transform: none;
    font-weight: 400;
    background-color: transparent;
    border: 1px solid transparent;
  }
  .MuiButton-root:hover,
  .MuiButton-root:active {
    border: 1px solid #e0e0e0;
    border-radius: 0.25rem;
    background-color: rgb(251, 249, 244);
  }
  .MuiButton-label {
    font-size: 1rem;
  }
  .MuiButton-text {
    padding: 0;
  }
  @media (min-width: 1597px) {
    .MuiButton-text {
      padding: 0.75rem;
    }
  }
`;

const StyledLabel = styled(Label)`
  padding: 0 0.25rem 0 1.25rem;
  font-family: OptumSans, sans-serif;
`;

const StyledDropdownOption = styled(MenuItem)`
  font-family: OptumSans !important;
  font-size: 12pt !important;
`;

const StyledButtonPlain = styled.button<{
  checked: boolean;
  isDisabled?: boolean;
}>`
  border: 2px solid ${p => (p.isDisabled ? '#999899' : '#2E3034')};
  margin-right: 10px;
  display: flex;
  padding: 0.5rem 0.5rem;
  border-radius: 100%;
  background-color: ${p =>
    p.checked ? p.theme.colors.aux.skyBlue : 'transparent'};
  color: ${p => (p.checked ? '#002677' : 'transparent')};
  &:hover {
    background: ${p =>
      !p.isDisabled ? p.theme.colors.aux.skyBlue : 'transparent'};
  }
`;

export const StyledButtonUitkSave = styled(Button)`
  min-width: 0rem !important;
`;

const StyledSelect = styled(Select)`
  #page-select-dropdown {
    cursor: default;
  }
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: '12pt',
    border: '1px solid #dadde9',
    fontFamily: 'OptumSans !important',
  },
}));

export interface BuilderTopMenuProps {
  pages: Array<Page>;
  page?: Page;
  pageId?: number;
  isPreviewMode?: boolean;
  currentPageId?: String;
  isNewPage?: boolean;
  updateSite: (site: Site) => void;
  saveSite: () => void;
  savePage: (page: PageVersion) => void;
  toggleShowGrid?: () => void;
  siteId: number;
  closeConfigPanel?: (open: boolean) => void;
  publishSite: () => void;
  publishSiteToDemo: () => void;
  site: Site;
  canUndo?: boolean;
  canRedo?: boolean;
  onUndo?: () => void;
  onRedo?: () => void;
  whenClickedUndo?: () => void;
  whenClickedRedo?: () => void;
  fetchCompareSiteandPage?: (siteId: number) => void;
  hasChanged?: boolean;
  isEditingTemplate?: boolean;
  selectedPageTemplate?: PageTemplate;
  openSiteListDialog?: (
    dialogType: string,
    siteId: number,
    siteName: string
  ) => void;
  updatePageTemplate: (pageTemplate: PageTemplate) => void;
}

export const BuilderTopMenu: React.FC<BuilderTopMenuProps> = (
  props: BuilderTopMenuProps
) => {
  const {
    pages,
    siteId,
    closeConfigPanel,
    toggleShowGrid,
    isPreviewMode,
    site,
    canUndo,
    canRedo,
    onUndo,
    onRedo,
    whenClickedUndo,
    whenClickedRedo,
    fetchCompareSiteandPage,
    pageId,
    currentPageId,
    isNewPage,
    isEditingTemplate,
    selectedPageTemplate,
    updatePageTemplate,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isNewPageValue, setIsNewPageValue] = useState(isNewPage);
  const [publishAnchorEl, setPublishAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [checked, setChecked] = useState(false);
  const [pageValue, setPageValue] = useState(
    pageId ??
      pages.find(page => page.current.pageId === site.horizonhomepageId)?.id
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [
    updatedTemplateLayoutDefinitions,
    setUpdatedTemplateLayoutDefinitions,
  ] = useState({});

  const open = Boolean(anchorEl);
  const publishOpen = Boolean(publishAnchorEl);
  const history = useHistory();
  const pageDropdownRef = React.useRef(null);
  const handleTooltip = open => {
    setTooltipOpen(open);
  };

  const handleDropdown = isOpen => {
    setDropdownOpen(isOpen);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleUnPublishSiteClick = (site: Site) => {
    props.openSiteListDialog(SiteListDialogType.UNPUBLISH, site.id, site.name);
  };
  const publishButton = document.getElementById('publish-button');
  const handlePublishClick = () => {
    setPublishAnchorEl(publishButton);
  };
  const handlePublishClose = () => {
    setPublishAnchorEl(null);
  };
  const handleCheck = (isChecked: boolean) => {
    toggleShowGrid();
    setChecked(isChecked);
  };

  const handleAutoSave = (isChecked: boolean) => {
    props.updateSite({ ...site, autoSave: isChecked });
  };
  const handleDisableBorder = (isChecked: boolean) => {
    props.updateSite({ ...site, disableBorder: isChecked });
  };

  const handlePageClick = (event: SelectChangeEvent<typeof pageValue>) => {
    const {
      target: { value },
    } = event;
    setIsNewPageValue(false);
    setPageValue(Number(value));
    setDropdownOpen(false);
  };

  const isTemplateSaveButtonDisabled = () => {
    const currentLayoutDefinitions = JSON.parse(
      selectedPageTemplate?.layoutDefinitions || '{}'
    );
    const newLayoutDefinitions = pages.find(
      page => page.id === TEMPLATE_EDITOR_PAGE_ID
    )?.current?.content;

    return compareLayoutDefinitions(
      currentLayoutDefinitions,
      newLayoutDefinitions
    );
  };

  const handleSaveClick = () => {
    if (isEditingTemplate) {
      updatePageTemplate({
        ...selectedPageTemplate,
        layoutDefinitions: JSON.stringify(updatedTemplateLayoutDefinitions),
      });
    } else {
      pages.forEach(page => {
        Object.keys(page.current.content).map(item => {
          const widget = page.current.content[item]; // Access each widget by key

          // Map yLocation to originalYLocation
          widget.config.originalYLocation = widget.config.yLocation;
        });
      });
      fetchCompareSiteandPage(siteId);
    }
  };

  const handlePublish = () => {
    const { publishSite } = props;
    publishSite();
  };

  const handlePublishToDemo = () => {
    const { publishSiteToDemo } = props;
    publishSiteToDemo();
  };

  const handleUndoWorkflow = () => {
    if (!canUndo || !site.access.edit) {
      return;
    }
    if (config.undoRedoEnabled) {
      onUndo();
      whenClickedUndo();
    }
  };

  const handleRedoWorkflow = () => {
    if (!canRedo || !site.access.edit) {
      return;
    }
    if (config.undoRedoEnabled) {
      onRedo();
      whenClickedRedo();
    }
  };

  const tenantId = site.tenantId ? site.tenantId : 'LD';

  useEffect(() => {
    if (isEditingTemplate) {
      setUpdatedTemplateLayoutDefinitions(
        pages.find(page => page.id === TEMPLATE_EDITOR_PAGE_ID)?.current
          ?.content
      );
    }
  }, [pages, isEditingTemplate]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'z') {
        if (event.shiftKey) {
          event.preventDefault();
          handleRedoWorkflow();
        } else {
          event.preventDefault();
          handleUndoWorkflow();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUndoWorkflow, handleRedoWorkflow]);

  useEffect(() => {
    if (!isPreviewMode && !isEditingTemplate) {
      closeConfigPanel(false);
      history.push(`/sites/${siteId}/pages/${pageValue}`);
    }
  }, [pageValue]);

  useEffect(() => {
    if (!isPreviewMode && pageId !== null) {
      setPageValue(pageId);
    }
  }, [pageId]);

  const getPreviewPath = () => {
    if (isEditingTemplate) {
      return `/page-templates/${selectedPageTemplate?.id}/preview`;
    } else if (isNewPageValue) {
      return `/sites/${siteId}/preview/${
        pages.find(page => page.current.title === currentPageId).current.slug
      }`;
    } else if (pageValue && pages.find(page => page.id === pageValue)) {
      return `/sites/${siteId}/preview/${
        pages.find(page => page.id === pageValue).current.slug
      }`;
    } else {
      return `/sites/${siteId}/preview`;
    }
  };

  const handlePreview = () => {
    history.push({
      pathname: getPreviewPath(),
      state: {
        pageId: isNewPageValue
          ? pages.find(page => page.current.title === currentPageId)?.id
          : pageValue,
      },
    });
  };

  const handleBackToEdit = () => {
    const path = isEditingTemplate
      ? `page-templates/${selectedPageTemplate?.id}`
      : `/sites/${siteId}/pages/${pageValue}`;
    history.push(path);
  };

  const handleManageAppClick = () => {
    const stelliumSessionData: StelliumData = {
      siteIds: [site.id],
      tenantId: site.tenantId,
      askId: site.aideId,
    };
    window.open(stelliumRedirectionUrl(stelliumSessionData), '_blank');
  };

  const getClassNameByTheme = (buttonType: string) => {
    if (site.theme.colors.primary === horizonUhcTheme.colors.primary) {
      return `save-button-uhc-${buttonType}`;
    }
    return `save-button-optum-${buttonType}`;
  };
  const getIconColor = canPerformAction => {
    return !canPerformAction || !site.access.edit ? '#999899' : '#2E3034';
  };

  return (
    <>
      <TopNav>
        {!isPreviewMode && !isEditingTemplate && (
          <TopNavLeft>
            <StyledLabel>Page:</StyledLabel>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <StyledPageHeading>Switch page</StyledPageHeading>{' '}
                  <p>See all the pages on your site and switch between them.</p>
                </React.Fragment>
              }
              open={tooltipOpen}
              arrow
            >
              <SelectWrapper>
                <StyledSelect
                  id="page-select-dropdown"
                  open={dropdownOpen}
                  ref={pageDropdownRef}
                  onClick={() => {
                    handleDropdown(!dropdownOpen);
                  }}
                  onMouseEnter={() => {
                    !dropdownOpen && handleTooltip(true);
                  }}
                  onMouseLeave={() => {
                    handleTooltip(false);
                  }}
                  onOpen={() => {
                    handleTooltip(false);
                    handleDropdown(true);
                  }}
                  data-test-id="page-select-dropdown"
                  onChange={handlePageClick}
                  value={
                    isNewPageValue
                      ? pages.find(page => page.current.title === currentPageId)
                          ?.id
                      : pages.find(page => page.current.pageId === pageValue)
                      ? pageValue
                      : pages.find(
                          page => page.current.pageId === site.horizonhomepageId
                        )?.id
                  }
                >
                  {pages.map(
                    page =>
                      page.current.slug !== 'defaultContentPage' && (
                        <StyledDropdownOption
                          disableRipple
                          key={page.id}
                          value={page.id}
                        >
                          {page.current.title}
                        </StyledDropdownOption>
                      )
                  )}
                  <StyledManagePage
                    onClick={e => {
                      pageDropdownRef.current.click();
                      document.getElementById('pages-panel-sidebar').click();
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    Manage Pages
                  </StyledManagePage>
                </StyledSelect>
              </SelectWrapper>
            </HtmlTooltip>
            <Divider orientation="vertical" variant="middle" flexItem />
            <SiteUrlDropdown site={site} />
          </TopNavLeft>
        )}

        <TopNavRight className="builder-top-nav">
          <Divider orientation="vertical" variant="middle" flexItem />
          {!isPreviewMode && !isEditingTemplate && (
            <div data-test-id="site-options-controls">
              <StyledMenuButton open={open}>
                <MenuButton
                  data-test-id="more-options-button"
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  disableRipple
                >
                  Manage Site
                  <StyledArrow icon="ArrowDownOpen" />
                </MenuButton>
              </StyledMenuButton>
              <StyledMenu
                data-test-id="more-options-menu"
                getContentAnchorEl={null}
                elevation={3}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Divider variant="middle" />
                <MenuItem disableRipple>
                  <div
                    style={
                      !site.access.edit
                        ? { pointerEvents: 'none', opacity: '0.3' }
                        : {}
                    }
                  >
                    Autosave {site.autoSave ? 'On' : 'Off'}
                    <SwitchLovely
                      checked={site.autoSave}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleAutoSave(e.target.checked ? true : false)
                      }
                      value={site.autoSave}
                      data-test-id="auto-save-switch"
                    />
                  </div>
                </MenuItem>
                <MenuItem
                  disableRipple
                  onClick={handleClose}
                  disabled={!site.access.edit}
                >
                  Version History
                </MenuItem>
                <MenuItem
                  href={config.userManagementBaseUrl + `/${tenantId}/users`}
                  target="_blank"
                  component="a"
                  disabled={!site.access.edit}
                >
                  Grant Access to Site
                </MenuItem>{' '}
                <MenuItem disableRipple onClick={handleManageAppClick}>
                  Manage Applications
                </MenuItem>{' '}
                <Divider variant="middle" />
                <MenuItem
                  disableRipple
                  onClick={() => handleUnPublishSiteClick(site)}
                  disabled={!site.access.delete || !site.published}
                >
                  Unpublish Site
                </MenuItem>
                <MenuItem disableRipple>
                  <div
                    style={
                      !site.access.edit
                        ? { pointerEvents: 'none', opacity: '0.3' }
                        : {}
                    }
                  >
                    Disable Border
                    <SwitchLovely
                      checked={site.disableBorder}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleDisableBorder(e.target.checked ? true : false)
                      }
                      value={site.disableBorder}
                      data-test-id="disable-border-switch"
                    />
                  </div>
                </MenuItem>
              </StyledMenu>
              <Divider orientation="vertical" variant="middle" flexItem />
            </div>
          )}
          <ButtonWrapper className="builder-button-wrapper">
            {!isPreviewMode && (
              <>
                <Tooltip
                  arrow
                  textAlign="left"
                  placement="bottom-start"
                  title={`${checked ? 'Disable' : 'Enable'} page grid`}
                >
                  <StyledButtonPlain
                    data-test-id="grid-icon"
                    aria-label="page grid"
                    onClick={() => handleCheck(!checked)}
                    checked={checked}
                  >
                    <IconGrid3x3 size={24} color="#2E3034" />
                  </StyledButtonPlain>
                </Tooltip>
                <Tooltip
                  arrow
                  textAlign="left"
                  placement="bottom-start"
                  title={canUndo ? 'Undo last change' : 'Nothing to undo'}
                >
                  <StyledButtonPlain
                    checked={false}
                    isDisabled={!canUndo || !site.access.edit}
                    onClick={handleUndoWorkflow}
                    data-test-id="undo-button"
                  >
                    <IconArrowBackUp size={24} color={getIconColor(canUndo)} />
                  </StyledButtonPlain>
                </Tooltip>
                <Tooltip
                  arrow
                  textAlign="left"
                  placement="bottom-start"
                  title={canRedo ? 'Redo last change' : 'Nothing to redo'}
                >
                  <StyledButtonPlain
                    checked={false}
                    isDisabled={!canRedo || !site.access.edit}
                    onClick={handleRedoWorkflow}
                    data-test-id="redo-button"
                  >
                    <IconArrowForwardUp
                      size={24}
                      color={getIconColor(canRedo)}
                    />
                  </StyledButtonPlain>
                </Tooltip>
              </>
            )}

            <StyledButtonUitkSave
              className={getClassNameByTheme('save')}
              data-test-id="save-button"
              variant="secondary"
              onPress={handleSaveClick}
              disabled={
                !site.access.edit ||
                (isEditingTemplate && isTemplateSaveButtonDisabled())
              }
            >
              Save
            </StyledButtonUitkSave>
            {isPreviewMode ? (
              <StyledButtonUitkSave
                className={getClassNameByTheme('back-to-edit')}
                data-test-id="back-to-edit-button"
                variant="secondary"
                onPress={handleBackToEdit}
              >
                Back to Edit
              </StyledButtonUitkSave>
            ) : (
              <StyledButtonUitkSave
                data-test-id="preview-button"
                variant="secondary"
                onPress={handlePreview}
                className={getClassNameByTheme('preview')}
              >
                Preview
              </StyledButtonUitkSave>
            )}
            {!isEditingTemplate && (
              <StyledButtonUitkSave
                data-test-id="publish-button"
                id="publish-button"
                onPress={handlePublishClick}
                icon={<ArrowDropDownIcon />}
                disabled={!site.access.edit}
              >
                Publish
                <PublishMenu
                  data-test-id="publish-menu"
                  getContentAnchorEl={null}
                  elevation={3}
                  anchorEl={publishAnchorEl}
                  open={publishOpen}
                  onClose={handlePublishClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'publish-button',
                  }}
                >
                  <MenuItem onClick={handlePublishToDemo}>
                    Publish to Demo
                  </MenuItem>
                  <MenuItem onClick={handlePublish}>Publish to Live</MenuItem>
                </PublishMenu>
              </StyledButtonUitkSave>
            )}
          </ButtonWrapper>
        </TopNavRight>
        <SiteListDialog />
      </TopNav>
    </>
  );
};
