import { ActionCreator, AnyAction, Reducer } from 'redux';
import {
  ThunkActionCreator,
  Selector,
  AsyncThunkActionCreator,
} from 'shared/state';
import {
  fetchEvents as doFetchEvents,
  saveEvent as doSaveEvent,
  deleteEvent as doDeleteEvent,
  updateEvent as doUpdateEvent,
} from 'shared/api/events';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

export interface State {
  data: { events: EventData[] };
  meta: StateMeta;
}

const defaultState: State = {
  data: {
    events: [],
  },
  meta: {
    pending: false,
  },
};

export const FETCH_EVENTS_FULFILLED = 'duplo/event/FETCH_EVENTS_FULFILLED';
export const FETCH_EVENTS_PENDING = 'duplo/event/FETCH_EVENTS_PENDING';
export const FETCH_EVENTS_FAILED = 'duplo/event/FETCH_EVENTS_FAILED';

export const SAVE_EVENT_FULFILLED = 'duplo/event/SAVE_EVENT_FULFILLED';
export const SAVE_EVENT_PENDING = 'duplo/event/SAVE_EVENT_PENDING';
export const SAVE_EVENT_FAILED = 'duplo/event/SAVE_EVENT_FAILED';

export const DELETE_EVENT_FULFILLED = 'duplo/event/DELETE_EVENT_FULFILLED';
export const DELETE_EVENT_PENDING = 'duplo/event/DELETE_EVENT_PENDING';
export const DELETE_EVENT_FAILED = 'duplo/event/DELETE_EVENT_FAILED';

export const UPDATE_EVENT_FULFILLED = 'duplo/event/UPDATE_EVENT_FULFILLED';
export const UPDATE_EVENT_PENDING = 'duplo/event/UPDATE_EVENT_PENDING';
export const UPDATE_EVENT_FAILED = 'duplo/event/UPDATE_EVENT_FAILED';

// Action Creators
const deleteEventPending: ActionCreator<AnyAction> = () => ({
  type: DELETE_EVENT_PENDING,
});

const deleteEventFulfilled: ActionCreator<AnyAction> = (eventId: number) => ({
  type: DELETE_EVENT_FULFILLED,
  eventId,
});

const deleteEventFailed: ActionCreator<AnyAction> = (error: string) => ({
  type: DELETE_EVENT_FAILED,
  error,
});

const updateEventPending: ActionCreator<AnyAction> = () => ({
  type: UPDATE_EVENT_PENDING,
});

const updateEventFulfilled: ActionCreator<AnyAction> = (event: EventData) => ({
  type: UPDATE_EVENT_FULFILLED,
  event,
});

const updateEventFailed: ActionCreator<AnyAction> = (error: string) => ({
  type: UPDATE_EVENT_FAILED,
  error,
});

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_EVENTS_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_EVENTS_FULFILLED:
      return {
        ...state,
        data: { events: action.events },
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case FETCH_EVENTS_FAILED:
      return {
        ...state,
        data: {
          events: [],
        },
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    case SAVE_EVENT_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case SAVE_EVENT_FULFILLED:
      return {
        ...state,
        data: { events: [...state.data.events, action.event] },
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case SAVE_EVENT_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    case DELETE_EVENT_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case DELETE_EVENT_FULFILLED:
      return {
        ...state,
        data: {
          events: state.data.events.filter(
            event => event.id !== action.eventId
          ),
        },
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case DELETE_EVENT_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    case UPDATE_EVENT_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case UPDATE_EVENT_FULFILLED:
      return {
        ...state,
        data: {
          events: state.data.events.map(event =>
            event.id === action.event.id ? action.event : event
          ),
        },
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case UPDATE_EVENT_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;

const fetchEventsPending: ActionCreator<AnyAction> = () => {
  return { type: FETCH_EVENTS_PENDING };
};

const fetchEventsFulfilled: ActionCreator<AnyAction> = (
  events: Array<EventData>
) => {
  return { type: FETCH_EVENTS_FULFILLED, events };
};

const fetchEventsFailed: ActionCreator<AnyAction> = () => {
  return { type: FETCH_EVENTS_FAILED };
};
const saveEventPending: ActionCreator<AnyAction> = () => ({
  type: SAVE_EVENT_PENDING,
});

const saveEventFulfilled: ActionCreator<AnyAction> = (event: EventData) => ({
  type: SAVE_EVENT_FULFILLED,
  event,
});

const saveEventFailed: ActionCreator<AnyAction> = (error: string) => ({
  type: SAVE_EVENT_FAILED,
  error,
});

export const fetchEvents: ThunkActionCreator = (
  widgetId: string
) => dispatch => {
  dispatch(fetchEventsPending());
  doFetchEvents(widgetId)
    .then(events => {
      dispatch(fetchEventsFulfilled(events));
    })
    .catch(_ => {
      dispatch(fetchEventsFailed());
    });
};

export const saveEvent: ThunkActionCreator = (
  event: EventPayload,
  siteId: number,
  pageId: number
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(saveEventPending());
    doSaveEvent(event, siteId, pageId)
      .then(() => {
        const action = saveEventFulfilled(event);
        dispatch(action);
        toast('event added', { type: 'success', theme: 'colored' });
        resolve(action);
      })
      .catch(error => {
        const action = saveEventFailed(error);
        dispatch(action);
        toast(error.message, { type: 'error', theme: 'colored' });
        reject(action);
      });
  });
};

export const deleteEvent: ThunkActionCreator = (
  eventId: number
) => dispatch => {
  dispatch(deleteEventPending());
  doDeleteEvent(eventId)
    .then(() => {
      const action = deleteEventFulfilled(eventId);
      dispatch(action);
      toast('Event deleted', { type: 'success', theme: 'colored' });
    })
    .catch(error => {
      const action = deleteEventFailed(error);
      dispatch(action);
      toast(error.message, { type: 'error', theme: 'colored' });
    });
};
export const updateEvent: AsyncThunkActionCreator<Promise<void>> = (
  eventId: number,
  eventPayload: EventPayload
) => {
  return dispatch => {
    dispatch(updateEventPending());
    return doUpdateEvent(eventId, eventPayload)
      .then(event => {
        dispatch(updateEventFulfilled(event));
        toast('Event updated', { type: 'success', theme: 'colored' });
      })
      .catch(error => {
        dispatch(updateEventFailed(error));
        toast(error.message, { type: 'error', theme: 'colored' });
      });
  };
};

const selectEventsSlice: Selector<State> = () => state => {
  return state.events;
};

export const selectEvents: Selector<Array<EventData>> = () => state => {
  const events = selectEventsSlice()(state).data.events;
  return events;
};

export const selectEventsMeta: Selector<StateMeta> = () => state => {
  return selectEventsSlice()(state).meta;
};
