import styled from 'styled-components';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { Config } from './types';
import { Checkbox, FormControl, Label } from '@uitk/react';
import { csvRegex } from 'builder/util/constants';

const StyledFieldSet = styled(FormControl)`
  font-family: 'OptumSans';
  padding: 0.25rem;
  border-width: 0;
  & > legend {
    padding: 0.25rem 0 0 0;
  }
  & > ul {
    flex-wrap: wrap;
    display: inline-flex;
    & > li {
      padding: 0 0.5rem 0.5rem 0;
      & > div > input + label:before {
        margin-right: 0.5rem;
        cursor: default;
      }
    }
  }
  #checkbox-group-controlled-err {
    display: none;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  cursor: default;
  margin-top: 0;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: flex-start;
  & input:checked + label:before {
    background-color: ${p => p.buttonColor} !important;
    cursor: default;
  }
  & input + label:hover:before,
  & input + label:active:before,
  & input + label:focus:before {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px ${p => p.buttonColor};
    cursor: default;
  }
  & input:focus + label:before,
  & input:active + label:before {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px ${p => p.buttonColor} !important;
    cursor: default;
  }
`;

const CheckboxButton: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    value,
    borderData,
    lineColor,
    label,
    buttonColor,
    required,
    visible,
    isCustomWidget,
    font,
  } = props.widgetState.config;

  const [checkedValues, setCheckedValues] = useState([]);
  const [blurred, setBlurred] = useState(false);
  const error =
    checkedValues.length === 0 && blurred && required
      ? 'A minimum of one value is required'
      : '';

  const setCheckedValue = (checked: boolean, value: string) => {
    if (checked) {
      setCheckedValues([...checkedValues, value]);
    } else {
      setCheckedValues([
        ...checkedValues.filter(checkedValue => checkedValue !== value),
      ]);
    }
  };

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return (
    <div
      style={{
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderRadius: `${borderData?.radius}px`,
        borderColor: `${lineColor}`,
        width: '100%',
        height: '100%',
      }}
    >
      <StyledFieldSet
        className="checkbox-group"
        id={`${'checkbox-group-controlled' + props.widgetState.id}`}
        error={error}
        required={required}
      >
        <Label style={{ fontFamily: font }}>{label}</Label>
        {value &&
          value.split(csvRegex).map(item => (
            <StyledCheckbox
              className="checkbox-button"
              buttonColor={buttonColor}
              id="checkbox-button"
              key={item}
              value={item}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCheckedValue(e.target.checked, e.target.value)
              }
              onBlur={() => setBlurred(true)}
              checked={checkedValues.includes(item)}
              style={{ cursor: 'default' }}
            >
              {item}
            </StyledCheckbox>
          ))}
      </StyledFieldSet>
    </div>
  );
};

export default CheckboxButton;
