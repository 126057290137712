import axios from 'shared/util/axios';

// write method to fetch events using below path
//site-builder-pr-833/event/widget-id-test

export const fetchEvents = (widgetId: string) => {
  return axios
    .get<Array<EventData>>(`site-builder/event/${widgetId}`)
    .then(response => response.data as Array<EventData>)
    .catch(error => {
      return Promise.reject(error);
    });
};

export const saveEvent = (
  eventPayload: EventPayload,
  siteId: number,
  pageId: number
): Promise<EventData> => {
  const params = {
    siteId: siteId.toString(),
    pageId: pageId.toString(),
  };
  const queryString = new URLSearchParams(params).toString();
  return axios
    .post(`site-builder/event/create?${queryString}`, eventPayload)
    .then(response => response.data as EventData)
    .catch(error => {
      return Promise.reject(error);
    });
};

export const deleteEvent = (eventId: number) => {
  return axios
    .delete(`site-builder/event/delete/${eventId}`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};
export const updateEvent = (
  eventId: number,
  eventPayload: EventPayload
): Promise<EventData> => {
  return axios
    .put(`site-builder/event/update/${eventId}`, eventPayload)
    .then(response => response.data as EventData)
    .catch(error => {
      return Promise.reject(error);
    });
};

export const uploadBulkEvents = (
  widgetId: string,
  siteId: number,
  pageId: number,
  file: File
): Promise<any> => {
  const formData = new FormData();
  formData.append('siteId', siteId.toString());
  formData.append('pageId', pageId.toString());
  formData.append('file', file);

  return axios
    .post(`site-builder/event/uploadBulk/${widgetId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};
