import axios from 'shared/util/axios';

export const deleteCalendarWidget = async (widgetId: string) => {
  try {
    const response = await axios.delete(`site-builder/calendar/delete`, {
      params: {
        widgetId: widgetId,
      },
    });
    console.log('Delete successful:', response.data);
  } catch (error) {
    console.error('Error deleting widget:', error);
  }
};
