import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { DRAG_DROP_PASTE } from '@lexical/rich-text';
import { isMimeType, mediaFileReader } from '@lexical/utils';
import axios from 'axios';
import { COMMAND_PRIORITY_LOW } from 'lexical';
import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import SiteEditContext from 'shared/util/SiteEditContext';
import { INSERT_IMAGE_COMMAND } from '../ImagesPlugin';
import { getSignedUrl, SignedUrlObject } from 'shared/api/s3';
import { selectIsEditingTemplate } from '../../../../state/ducks/pageTemplates';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state';

const ACCEPTABLE_IMAGE_TYPES = [
  'image/',
  'image/heic',
  'image/heif',
  'image/gif',
  'image/webp',
];

interface DragDropPasteProps {
  isRichText: boolean;
  siteId: string;
}

export default function DragDropPaste({
  isRichText,
  siteId,
}: DragDropPasteProps): null {
  const [editor] = useLexicalComposerContext();
  const isEditingPageTemplate = useSelector((state: RootState) =>
    selectIsEditingTemplate()(state)
  );
  const { siteEditPermission } = useContext(SiteEditContext) || {};

  useEffect(() => {
    return editor.registerCommand(
      DRAG_DROP_PASTE,
      files => {
        if (!isRichText) {
          return false;
        }
        // Todo: Revisit this when we implement template editor roles https://rally1.rallydev.com/#/?detail=/userstory/819799154015
        if (!siteEditPermission && !isEditingPageTemplate) {
          toast('You do not have permission to upload images to this site', {
            type: 'error',
            theme: 'colored',
          });
          return false;
        }
        (async () => {
          const filesResult = await mediaFileReader(
            files,
            ACCEPTABLE_IMAGE_TYPES
          );
          for (const { file } of filesResult) {
            if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
              getSignedUrl(
                file,
                async (signedUrlObject: SignedUrlObject) => {
                  try {
                    await axios.put(signedUrlObject.signedUrl, file, {
                      headers: {
                        'Content-Type': file.type,
                      },
                    });
                    editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                      altText: file.name,
                      src: signedUrlObject.signedUrl.split('?')[0],
                    });
                    toast('Image uploaded successfully', {
                      type: 'success',
                      theme: 'colored',
                    });
                  } catch (error) {
                    toast('Error uploading image: ' + error.message, {
                      type: 'error',
                      theme: 'colored',
                    });
                  }
                },
                siteId
              );
            }
          }
        })();
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor, isRichText, siteEditPermission, siteId]);
  return null;
}
