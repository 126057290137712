import { Label, TextInput } from '@uitk/react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from '../../GradientColorPicker/GradientColorPicker';
import { ColorButtonWrapper } from '../Background/Background';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';

const StyledSlidesConfig = styled.div`
  display: flex;
  align-items: center;
`;

const StyledConfig = styled.div`
  padding-top: 2.5px;
  display: flex;
  align-items: center;
`;

const TextBoxStyles = {
  width: '6.5em',
  height: '1em',
  marginLeft: '-0.5em',
  marginTop: '-2.5px',
  color: '#4b4d4f',
  fontFamily: 'OptumSans',
  fontSize: '12px',
};

export interface SlideShowProps<T> {
  disabled?: boolean;
  handleArrowColor?: (data: string) => void;
  handleSelectedDotsColor?: (data: string) => void;
  handleUnSelectedDotsColor?: (data: string) => void;
  handleArrowsChecked?: (data: Boolean) => void;
  handleAutoplayChecked?: (data: Boolean) => void;
  handleAutoplaySpeed: (data: string) => void;
  handlePositionalDotsChecked: (data: Boolean) => void;
  widgetState: Widget<T>;
  pages?: Array<Page>;
  site?: Site;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
}

export const SlideShow: React.FC<SlideShowProps<any>> = (
  props: SlideShowProps<any>
) => {
  const {
    widgetState,
    handleArrowColor,
    handleSelectedDotsColor,
    handleUnSelectedDotsColor,
    handleArrowsChecked,
    handleAutoplayChecked,
    handleAutoplaySpeed,
    handlePositionalDotsChecked,
    site,
    updateSite,
    saveSite,
    disabled,
  } = props;

  const [arrowsChecked, setArrowsChecked] = useState(
    widgetState.config.arrowsCheck
  );
  const [positionalDotsChecked, setPositionalDotsChecked] = useState(
    widgetState.config.positionalDotsCheck
  );
  const [autoPlayChecked, setAutoPlayChecked] = useState(
    widgetState.config.autoPlayChecked
  );
  const [arrowColor, setArrowColor] = useState(
    widgetState.config.slideArrowColor
  );
  const [selectedDotsColor, setSelectedDotsColor] = useState(
    widgetState.config.slideSelectedDotsColor
  );
  const [unselectedDotsColor, setUnselectedDotsColor] = useState(
    widgetState.config.slideUnselectedDotsColor
  );
  const [speed, setSpeed] = useState('500');

  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    handleSelectedDotsColor(selectedDotsColor);
  }, [selectedDotsColor]);

  useEffect(() => {
    handleUnSelectedDotsColor(unselectedDotsColor);
  }, [unselectedDotsColor]);

  useEffect(() => {
    handleArrowColor(arrowColor);
  }, [arrowColor]);

  useEffect(() => {
    handleAutoplaySpeed(speed);
  }, [speed]);

  useEffect(() => {
    handleArrowsChecked(arrowsChecked);
  }, [arrowsChecked]);

  useEffect(() => {
    handleAutoplayChecked(autoPlayChecked);
  }, [autoPlayChecked]);

  useEffect(() => {
    handlePositionalDotsChecked(positionalDotsChecked);
  }, [positionalDotsChecked]);

  const handleSpeedChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSpeed(e.target.value);
  };

  const handleArrow = (e: ChangeEvent<HTMLInputElement>) => {
    setArrowsChecked(e.target.checked);
  };

  const handleColorChange = newColor => {
    if (pickerAnchorEl === document.querySelector('#arrow-color')) {
      setArrowColor(newColor);
    }
    if (pickerAnchorEl === document.querySelector('#selected-dots-color')) {
      setSelectedDotsColor(newColor);
    }
    if (pickerAnchorEl === document.querySelector('#unselected-dots-color')) {
      setUnselectedDotsColor(newColor);
    }
  };

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleAutoCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAutoPlayChecked(e.target.checked);
  };

  const handleColorValue = (anchorEl: HTMLDivElement | null) => {
    switch (anchorEl) {
      case document.querySelector('#arrow-color'):
        return arrowColor;
      case document.querySelector('#selected-dots-color'):
        return selectedDotsColor;
      case document.querySelector('#unselected-dots-color'):
        return unselectedDotsColor;
      default:
        return '';
    }
  };

  return (
    <>
      <StyledSlidesConfig>
        <Label
          style={{
            fontSize: '12px',
            fontWeight: 'unset',
            paddingRight: '15px',
            fontFamily: 'OptumSans',
          }}
        >
          Include navigational arrows
        </Label>
        <Checkbox
          data-test-id="arrows-checkbox"
          disabled={disabled}
          checked={arrowsChecked}
          onChange={handleArrow}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
        />
        <div>
          <ColorButtonWrapper
            id="arrow-color"
            data-test-id="arrow-color-icon"
            onClick={handleColorEditClick}
          >
            <ColorButton color={arrowColor} disabled={disabled} />
          </ColorButtonWrapper>
          <StyledPickerPopover
            data-test-id="arrow-color-picker-popover"
            id={pickerId}
            open={pickerOpen}
            onClick={handlePickerClick}
            onClose={handlePickerClose}
            anchorEl={pickerAnchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <StyledPickerWrapper>
              <StyledPickerHeader data-test-id="styled-picker-header">
                Color Selector
              </StyledPickerHeader>
              <StyledClose
                data-test-id="arrow-close-icon"
                onClick={handlePickerClose}
              />
            </StyledPickerWrapper>
            <GradientColorPicker
              data-test-id="arrow-color"
              value={handleColorValue(pickerAnchorEl)}
              site={site}
              handleColorChange={handleColorChange}
              onChange={handleColorChange}
              updateSite={updateSite}
              saveSite={saveSite}
              anchorEl={pickerAnchorEl}
            />
          </StyledPickerPopover>
        </div>
      </StyledSlidesConfig>
      <StyledConfig>
        <Label
          style={{
            fontSize: '12px',
            fontWeight: 'unset',
            paddingRight: '40px',
            fontFamily: 'OptumSans',
          }}
        >
          Include positional dots
        </Label>
        <Checkbox
          data-test-id="include-positional-dots-checked"
          disabled={disabled}
          checked={positionalDotsChecked}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPositionalDotsChecked(e.target.checked)
          }
          sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
        />
        <ColorButtonWrapper
          id="selected-dots-color"
          data-test-id="active-dots-icon"
          onClick={handleColorEditClick}
        >
          <ColorButton color={selectedDotsColor} disabled={disabled} />
        </ColorButtonWrapper>
        <ColorButtonWrapper
          id="unselected-dots-color"
          data-test-id="inactive-dots-icon"
          onClick={handleColorEditClick}
        >
          <ColorButton color={unselectedDotsColor} disabled={disabled} />
        </ColorButtonWrapper>
      </StyledConfig>
      <StyledConfig>
        <Label
          style={{
            fontSize: '12px',
            fontWeight: 'unset',
            paddingRight: '15px',
            fontFamily: 'OptumSans',
          }}
        >
          Autoplay
        </Label>
        <Checkbox
          data-test-id="autoplay-checked"
          disabled={disabled}
          checked={autoPlayChecked}
          onChange={handleAutoCheckChange}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
        />
        <Label
          style={{
            fontSize: '12px',
            fontWeight: 'unset',
            paddingRight: '30px',
            paddingLeft: '17px',
            fontFamily: 'OptumSans',
          }}
        >
          Speed
        </Label>
        <TextInput
          data-test-id="speed"
          disabled={disabled}
          style={TextBoxStyles}
          value={speed}
          onChange={handleSpeedChange}
        />
      </StyledConfig>
    </>
  );
};
