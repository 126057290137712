import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  selectSite,
  selectPages,
  updateSite,
  saveSite,
  selectCurrentPage,
  addChild,
  updateWidgetConfig,
  selectWidget,
  setActiveWidgetId,
  updateCurrentPage,
  savePage,
  selectPage,
  updateWidgetPosition,
  createPage,
  removePage,
  selectDefaultPage,
  updatePageDefaultWidget,
  resetSiteDataForTemplateEditing,
  selectSiteInternal,
} from 'shared/state/ducks/sites';
import { SiteLandingPage } from './SiteLandingPage';
import { fetchIdpsIfNeeded, selectIdps } from 'shared/state/ducks/idps';
import { fetchProvisioningStores } from 'shared/state/ducks/provisioningStores';
import {
  getShowGridToggleValue,
  openSiteListDialog,
  toggleShowGrid,
} from 'shared/state/ducks/ui';
import { setResetToFalse } from 'shared/state/ducks/ui';
import {
  swapResetData,
  resetChange,
  saveSitePages,
  selectInitialSiteDataSlice,
} from 'shared/state/ducks/site';
import { initiateLogout } from 'shared/state/ducks/session';
import {
  fetchEvents,
  selectEvents,
  saveEvent,
  deleteEvent,
  updateEvent,
} from 'shared/state/ducks/events';
import {
  selectIsEditingTemplate,
  setIsEditingTemplate,
  setSelectedPageTemplate,
} from '../../../shared/state/ducks/pageTemplates';
import { fetchCompareSiteandPage } from 'shared/state/ducks/site';
import {
  fetchFeaturesIfNeeded,
  selectFeatures,
} from 'shared/state/ducks/features';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

interface SiteLandingPageContainerProps {
  siteId: number;
  pageId: number;
  templateId?: number;
  site?: Site;
  page?: Page;
  widgetId?: string;
  parentWidgetId: string;
}

const mapStateToProps = (
  state: RootState,
  ownProps: SiteLandingPageContainerProps
) => ({
  site: selectSite(ownProps.siteId)(state),
  newSite: selectSiteInternal(ownProps.siteId)(state),
  features: selectFeatures()(state),
  oldSite: selectInitialSiteDataSlice()(state),
  pages: selectPages(ownProps.siteId)(state),
  page: selectPage(ownProps.siteId, ownProps.pageId)(state),
  defaultPage: selectDefaultPage(ownProps.siteId)(state),
  idps: selectIdps()(state),
  provisioningStores: state.provisioningStores.data.provisioningStores,
  currentPage: selectCurrentPage(ownProps.siteId, ownProps.pageId)(state),
  widgetState: selectWidget(ownProps.siteId, ownProps.pageId, 'root')(state),
  activeWidgetId: state.sites.activeWidgetId,
  shouldDisplayGridLines: getShowGridToggleValue()(state),
  hasChanged: state.site.hasChanged,
  userModifiedDataState: state.site.newData,
  isAccordionPanelOpen: state.ui.panelWidget.open,
  accordionHeightShift: state.ui.panelWidget.height,
  eventList: selectEvents()(state),
  isEditingTemplate: selectIsEditingTemplate()(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: SiteLandingPageContainerProps
) => ({
  updateSite: (site: Site) => {
    dispatch(updateSite(ownProps.siteId, site));
  },
  fetchFeatures: () => {
    dispatch(fetchFeaturesIfNeeded());
  },
  saveSite: (showToastMessage?: boolean) => {
    return dispatch(saveSite(ownProps.siteId, showToastMessage));
  },
  fetchIdps: () => {
    dispatch(fetchIdpsIfNeeded());
  },
  fetchProvisioningStores: () => {
    dispatch(fetchProvisioningStores());
  },
  addChild: (
    type: string,
    initialConfig: any,
    location: number,
    widgetId: string,
    newParentWidgetId: string,
    tabIndex: number
  ) => {
    dispatch(
      addChild(
        ownProps.siteId,
        ownProps.pageId,
        newParentWidgetId || ownProps.parentWidgetId,
        type,
        initialConfig,
        location,
        widgetId,
        tabIndex
      )
    );
  },
  updateWidgetPosition: (bannerWidgetId, height, oldHeight) => {
    dispatch(
      updateWidgetPosition(
        ownProps.siteId,
        ownProps.pageId,
        bannerWidgetId,
        height,
        oldHeight
      )
    );
  },
  onUpdateConfig: (config: any) => {
    dispatch(
      updateWidgetConfig(ownProps.siteId, ownProps.pageId, 'root', config)
    );
  },
  openSiteListDialog: (
    dialogType: string,
    siteId: number,
    siteName: string
  ) => {
    dispatch(openSiteListDialog(dialogType, siteId, siteName));
  },
  setActiveWidgetId: (widgetId: string) => {
    dispatch(setActiveWidgetId(widgetId));
  },
  updateCurrentPage: (pageVersion: PageVersion) => {
    dispatch(updateCurrentPage(ownProps.siteId, pageVersion));
  },
  updatePageDefaultWidget: (
    widgetId: string,
    config: any,
    xDifference: number,
    yDifference: number
  ) => {
    dispatch(
      updatePageDefaultWidget(
        ownProps.siteId,
        ownProps.pageId,
        widgetId,
        config,
        xDifference,
        yDifference
      )
    );
  },
  setResetToFalse: () => dispatch(setResetToFalse()),
  toggleShowGrid: () => {
    dispatch(toggleShowGrid());
  },
  saveSitePages: (site: Site, pages: Array<Page>) => {
    dispatch(saveSitePages(site, pages));
  },
  swapResetData: () => {
    dispatch(swapResetData());
  },
  resetUserSyncChange: () => {
    dispatch(resetChange());
  },
  createPage: (page: Partial<PageVersion>) => {
    dispatch(createPage(ownProps.siteId, page));
  },
  removePage: (pageId: number) => {
    dispatch(removePage(ownProps.siteId, pageId));
  },
  savePage: (pageId: number, page: PageVersion) => {
    dispatch(savePage(ownProps.siteId, pageId, page));
  },
  initiateLogout: () => {
    dispatch(initiateLogout());
  },
  fetchEventList: (widgetId: string) => {
    return dispatch(fetchEvents(widgetId));
  },
  saveEvent: (event: EventPayload, siteId: number, pageId: number) => {
    dispatch(saveEvent(event, siteId, pageId));
  },
  deleteEvent: (eventId: number) => {
    dispatch(deleteEvent(eventId));
  },
  updateEvent: (eventId: number, eventPayload: EventPayload) => {
    dispatch(updateEvent(eventId, eventPayload));
  },
  setSelectedPageTemplate: (templateId: number | null) => {
    dispatch(setSelectedPageTemplate(templateId));
  },
  resetSiteDataForTemplateEditing: () => {
    dispatch(resetSiteDataForTemplateEditing());
  },
  setIsEditingTemplate: (isEditing: boolean) => {
    dispatch(setIsEditingTemplate(isEditing));
  },
  fetchCompareSiteandPage: (siteId: number) => {
    dispatch(fetchCompareSiteandPage(siteId));
  },
  clearAllUndoRedo: () => dispatch(UndoActionCreators.clearHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteLandingPage);
