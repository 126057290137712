import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { RootState, Dispatch } from 'shared/state';
import {
  selectSite,
  selectPages,
  selectDomain,
  selectWidget,
  updateWidgetConfig,
} from 'shared/state/ducks/sites';
import { selectUser } from 'shared/state/misc/oidc';
import SiteRoutes from './SiteRoutes';
import { initiateLogout } from 'shared/state/ducks/session';
import { setResetToFalse } from 'shared/state/ducks/ui';

interface SiteRootContainerProps {
  siteId: number;
  domain?: string;
  pageId?: number;
  initiateLogout?: () => void;
}

const mapStateToProps = (
  state: RootState,
  ownProps: SiteRootContainerProps & RouteComponentProps<{}>
) => ({
  site: selectSite(ownProps.siteId)(state),
  pages: selectPages(ownProps.siteId)(state),
  user: selectUser()(state),
  domain: selectDomain()(state),
  widgetState: selectWidget(ownProps.siteId, ownProps.pageId, 'root')(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: SiteRootContainerProps & RouteComponentProps<{}>
) => ({
  onUpdateConfig: (config: any, widgetId: string, defaultWidget: boolean) => {
    dispatch(
      updateWidgetConfig(
        ownProps.siteId,
        ownProps.pageId,
        widgetId,
        config,
        defaultWidget
      )
    );
  },
  setResetToFalse: () => dispatch(setResetToFalse()),
  initiateLogout: () => {
    dispatch(initiateLogout());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiteRoutes)
);
