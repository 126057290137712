import {
  $getSelection,
  $isRangeSelection,
  RootNode,
  LexicalNode,
} from 'lexical';

const checkParentNode = (
  node: LexicalNode,
  $isNodeType: (node: LexicalNode) => boolean
): boolean => {
  let currentNode = node;
  while (currentNode) {
    if ($isNodeType(currentNode)) {
      return true;
    }
    currentNode = currentNode.getParent();
  }
  return false;
};

export default function isSelectionInNodeType(
  $isNodeType: (node: LexicalNode) => boolean
): boolean {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    const anchorTopLevel =
      anchorNode instanceof RootNode
        ? anchorNode
        : anchorNode.getTopLevelElementOrThrow();
    const focusTopLevel =
      focusNode instanceof RootNode
        ? focusNode
        : focusNode.getTopLevelElementOrThrow();
    return (
      $isNodeType(anchorTopLevel) ||
      $isNodeType(focusTopLevel) ||
      checkParentNode(anchorTopLevel, $isNodeType) ||
      checkParentNode(focusTopLevel, $isNodeType)
    );
  }
  return false;
}
