import { Redirect, Route, Switch } from 'react-router';
import { Helmet } from 'react-helmet';
import { User } from 'shared/state/misc/oidc';
import PageContext from 'shared/util/PageContext';
import SitePage from 'shared/components/SitePage';
import SiteBody from 'shared/components/SiteBody';
import SiteFooter from 'shared/components/SiteFooter';
import PageWrapper from 'client/components/PageWrapper';
import Terms from 'client/components/Terms';
import PrivacyPolicy from 'client/components/PrivacyPolicy';
import ProfileSettings from 'client/components/ProfileSettings';
import Callback from 'client/scenes/Callback';
import Utilities from 'client/scenes/Utilities';
import AuthenticatedRoute from 'client/components/AuthenticatedRoute';
import UnauthenticatedRoute from 'client/components/UnauthenticatedRoute';
import UserImpersonation from 'client/components/UserImpersonation';
import Impersonation from 'client/scenes/Impersonation/Impersonation';
import { NO_IDP } from 'builder/util/constants';
import WidgetConnectorNew from 'builder/scenes/SiteBuilder/WidgetConnector';
import Feedback from 'shared/components/Feedback/Feedback';
import { processWidgetData } from '../../components/PageLayout/utils';
import PageLayout from '../../components/PageLayout';
import React from 'react';

interface SiteRoutesProps {
  site: Site;
  pages: Array<Page>;
  user: User;
  domain: string;
  onUpdateConfig?: (
    config: any,
    widgetId: string,
    defaultWidget: boolean
  ) => void;
  initiateLogout?: () => void;
  setResetToFalse?: () => void;
}
const SiteRoutes: React.FC<SiteRoutesProps> = ({
  site,
  pages,
  user,
  domain,
  onUpdateConfig,
  initiateLogout,
}) => {
  const siteRoutes = pages.map(page => {
    const RouteComponent =
      page.current.authentication && !(site.idpHint === NO_IDP)
        ? AuthenticatedRoute
        : UnauthenticatedRoute;
    return (
      <RouteComponent
        siteId={site.id}
        pageId={page.id}
        key={page.id}
        path={`/${page.current.slug}`}
        exact
        render={() => (
          <PageContext.Provider
            value={{
              site,
              pages,
              page,
            }}
          >
            <SitePage>
              <Helmet>
                <title>
                  {page.current.title} | {site.name}
                </title>
              </Helmet>
              <UserImpersonation clientId={site.clientId} user={user} />
              <SiteBody>
                <WidgetConnectorNew
                  page={page}
                  widgetId={'root'}
                  currentPage={page.current}
                  siteId={site.id}
                  pageId={page.id}
                />
                <Feedback
                  value={site.header.feedback}
                  siteId={site.id}
                  siteName={site.name}
                  user={user}
                />
              </SiteBody>
            </SitePage>
          </PageContext.Provider>
        )}
      />
    );
  });

  const homepage: Page = pages.find(page => page.id === site.horizonhomepageId);
  const homepageSlug: string =
    (homepage && homepage.current.slug) ||
    (pages[0] && pages[0].current.slug) ||
    '/';
  const postLoginPage: Page = pages.find(
    page => page.id === site.horizonpostLoginPageId
  );
  const postLoginPageSlug: string =
    postLoginPage && postLoginPage.current && postLoginPage.current.slug;

  return (
    <Switch>
      <Route
        path="/callback"
        render={({ history, location }) => (
          <Callback
            history={history}
            location={location}
            successRedirect={`/${postLoginPageSlug || homepageSlug}`}
            site={site}
            pages={pages}
            user={user}
          />
        )}
      />
      {siteRoutes}
      <Route
        path="/terms"
        render={() => (
          <PageWrapper
            title="Terms"
            header={site.header}
            logo={site.theme.logo}
            pages={pages}
            siteId={site.id}
            user={user}
            clientId={site.clientId}
          >
            <Terms />
            <SiteFooter
              footer={site.footer}
              logo={site.theme.logo}
              pages={pages}
            />
          </PageWrapper>
        )}
      />
      <Route
        path="/privacy"
        render={() => (
          <PageWrapper
            title="Privacy Policy"
            header={site.header}
            logo={site.theme.logo}
            pages={pages}
            siteId={site.id}
            user={user}
            clientId={site.clientId}
          >
            <PrivacyPolicy />
            <SiteFooter
              footer={site.footer}
              logo={site.theme.logo}
              pages={pages}
            />
          </PageWrapper>
        )}
      />
      <AuthenticatedRoute
        path="/profile"
        siteId={site.id}
        pageId={pages.find(page => page).id}
        homepageSlug={homepageSlug}
        render={() => {
          const widgets = processWidgetData(
            pages.find(page => page).current,
            onUpdateConfig,
            user,
            pages,
            site.id,
            initiateLogout
          );
          return (
            <PageLayout
              initialWidgets={widgets}
              currentPage={pages.find(page => page).current}
            >
              <ProfileSettings domain={site.domain} />
            </PageLayout>
          );
        }}
      />
      <Route
        path="/__util__"
        render={() => {
          const widgets = processWidgetData(
            pages.find(page => page).current,
            onUpdateConfig,
            user,
            pages,
            site.id,
            initiateLogout
          );
          return (
            <PageLayout
              initialWidgets={widgets}
              currentPage={pages.find(page => page).current}
            >
              <Utilities domain={domain} />
            </PageLayout>
          );
        }}
      />
      <Route
        path="/impersonation"
        render={() => (
          <PageWrapper
            title="Impersonation"
            header={site.header}
            logo={site.theme.logo}
            pages={pages}
            siteId={site.id}
            user={user}
            clientId={site.clientId}
          >
            <Impersonation
              postImpersonationPage={postLoginPageSlug || homepageSlug}
            />
          </PageWrapper>
        )}
      />
      <Route>
        <Redirect to={`/${homepageSlug}`} />
      </Route>
    </Switch>
  );
};

export default SiteRoutes;
