import { ReactNode } from 'react';
import styled from 'styled-components';
import Typography from 'shared/components/Typography';
import Version from './Version';
import Manifest from './Manifest';

const Wrapper = styled.div`
  display: flex;
  margin: 10vh auto;
  max-width: 1100px;
  flex-direction: column;
  padding: 16px 0;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 0;
  border-bottom: 2px solid ${p => p.theme.colors.aux.lightGrey};

  &:last-child {
    border-bottom: none;
  }
`;

interface UtilitiesProps {
  domain: string;
}
const getBaseDomain = (domain: string) => domain.substring(domain.indexOf('.'));

const Section = ({
  title,
  children,
}: {
  title: string;
  children?: ReactNode;
}) => (
  <SectionWrapper>
    <Typography variant="headline" gutterBottom>
      {title}
    </Typography>
    {children}
  </SectionWrapper>
);

const Utilities: React.FC<UtilitiesProps> = ({ domain }: UtilitiesProps) => (
  <Wrapper>
    <Section title="Version">
      <Version domain={getBaseDomain(domain)} />
    </Section>
    <Section title="Manifest">
      <Manifest />
    </Section>
  </Wrapper>
);

export default Utilities;
