import React, { useState, useEffect, useMemo } from 'react';
import { FormControl, Select, IPaginationState, Pagination } from '@uitk/react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
const ResultsContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #121212;
  color: #e0e0e0;
  font-family: Arial, sans-serif;
`;

const Title = styled.h2`
  color: #ffffff;
  font-size: 22px;
  margin-left: 20px;
`;

const SortDropdown = styled(Select)`
  margin-left: 10px;
  padding: 5px;
  border-radius: 4px;
  background-color: #1e1e1e;
  color: #e0e0e0;
  border: 1px solid #444;
  cursor: pointer;
  transition: all 0.2s ease;
  width: auto;
  max-width: 200px;
  min-width: 150px;
  display: inline-block;
  &:hover {
    border-color: #007bff;
  }
`;

const ResultsList = styled.div`
  margin-top: 20px;
`;

const ResultItem = styled.div`
  padding: 15px;
  border-bottom: 1px solid #333;
  background-color: #1e1e1e;
  &:hover {
    background-color: #252525;
  }
`;

const Category = styled.div`
  font-weight: bold;
  color: #b0b0b0;
`;

const ResultTitle = styled.a`
  font-size: 18px;
  color: #66b2ff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Description = styled.p`
  margin: 5px 0;
  color: #bbb;
`;

const DateUpdated = styled.div`
  font-size: 14px;
  color: #888;
`;

interface SearchResult {
  category: string;
  title: string;
  description: string;
  link: string;
  dateUpdated: string;
}

interface SearchResultsProps {
  fetchSearchResults: (searchQuery: string) => Promise<SearchResult[]>;
  searchResults?: SearchResult[];
}

const SearchResults: React.FC<SearchResultsProps> = ({
  fetchSearchResults,
}) => {
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get('q') || '';
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [sortBy, setSortBy] = useState('relevance');

  useEffect(() => {
    setLoading(true);
    fetchSearchResults(searchQuery)
      .then(data => setResults(data || []))
      .catch(err => {
        setError(err);
        setResults([]);
      })
      .finally(() => setLoading(false));
  }, [searchQuery, fetchSearchResults]);

  const sortedResults = useMemo(() => {
    return [...results].sort((a, b) => {
      switch (sortBy) {
        case 'date_updated':
          return (
            new Date(b.dateUpdated).getTime() -
            new Date(a.dateUpdated).getTime()
          );
        case 'title_asc':
          return a.title.localeCompare(b.title);
        case 'title_desc':
          return b.title.localeCompare(a.title);
        default:
          return 0;
      }
    });
  }, [results, sortBy]);

  const paginationConfig = { totalItemsCount: sortedResults.length };
  const entriesPerPageConfig = {
    pageSizeOptions: [
      { label: '3', value: 3 },
      { label: '6', value: 6 },
      { label: '9', value: 9 },
    ],
    initialValue: 3,
  };

  return (
    <ResultsContainer>
      <Title>
        Search results for:{' '}
        <span style={{ color: '#66b2ff' }}>{searchQuery}</span>
      </Title>
      <FormControl id={'sortBy-select'}>
        <SortDropdown
          data-test-id={'sort-dropdown-search'}
          value={sortBy}
          onChange={e => setSortBy(e.target.value)}
        >
          <option value="relevance">Sort: Relevance</option>
          <option value="date_updated">Date Updated</option>
          <option value="title_asc">Title A-Z</option>
          <option value="title_desc">Title Z-A</option>
        </SortDropdown>
      </FormControl>
      {loading && <div>Loading...</div>}
      {error && <div>Error fetching results.</div>}
      {sortedResults.length === 0 && !loading && !error && (
        <div>No results found.</div>
      )}
      <Pagination
        id="pagination-entries"
        entriesPerPageConfig={entriesPerPageConfig}
        config={paginationConfig}
      >
        {({ page, pageSize }: IPaginationState) => (
          <ResultsList>
            {sortedResults
              .slice((page - 1) * pageSize, page * pageSize)
              .map(result => (
                <ResultItem key={uuidv4()}>
                  <Category>{result.category}</Category>
                  <ResultTitle href={result.link}>{result.title}</ResultTitle>
                  <Description>{result.description}</Description>
                  <DateUpdated>Updated: {result.dateUpdated}</DateUpdated>
                </ResultItem>
              ))}
          </ResultsList>
        )}
      </Pagination>
    </ResultsContainer>
  );
};

export default SearchResults;
