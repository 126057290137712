import React, { ChangeEvent } from 'react';
import { Panel, Label, Checkbox, FormControl } from '@uitk/react';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from 'styled-components';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import { StyledPanel, StyledPanelTitle } from '../Text/TextWidgetControl';
import { SizeLocationPanel } from '../Link/LinkWidgetControl';
import Tooltip from '../../components/Tooltip';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';

export const StyledLabel = styled(Label)`
  color: #1d1929;
  font-size: 14px;
  font-family: OptumSans, sans-serif;
  padding-right: 10px;
  font-weight: 500;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 0;
`;

export const FieldWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const RichTextEditorWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const {
    value,
    onChange,
    widgetState: {
      config: { enableTableOfContents },
      id,
    },
    currentPage: { layoutLocked },
  } = props;

  const updateWidgetSizeLocation = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };

  const handleEnableTableOfContents = (enabled: boolean) => {
    onChange({
      ...value,
      enableTableOfContents: enabled,
    });
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    handleEnableTableOfContents(e.target.checked);
  };

  const tooltipText = `
  If selected, a table of content will be autogenerated based on the headings in the rich text editor. It will display
   on the page to the right of the rich text editor.
  `;

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
            data-test-id="rte-general-section-panel"
          >
            <FieldWrapper>
              <StyledLabel>Enable Table of Contents</StyledLabel>
              <FormControl
                id="rte-checkbox-form-control"
                disabled={layoutLocked}
              >
                <StyledCheckbox
                  data-test-id="rte-required-checkbox"
                  checked={enableTableOfContents}
                  onChange={handleCheck}
                />
              </FormControl>
              <Tooltip
                title={tooltipText}
                arrow
                placement="bottom-start"
                leaveDelay={100}
              >
                <IconButton>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </FieldWrapper>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="rte-size-control-panel"
          >
            <WidgetSizeLocation
              data-test-id="rte-widget-size-location"
              disabled={layoutLocked}
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLocation={updateWidgetSizeLocation}
              activeWidgetId={id}
            />
          </SizeLocationPanel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default RichTextEditorWidgetControl;
