import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  updateWidgetConfig,
  selectWidget,
  setActiveWidgetId,
  removeWidget,
  selectPages,
  updatePageContent,
  updateDefaultWidgetConfig,
} from 'shared/state/ducks/sites';
import { addUndoRedoState } from 'shared/state/ducks/todos';
import { setResetToFalse } from 'shared/state/ducks/ui';
import WidgetControlWrapperNew from './WidgetControlWrapperNew';
import {
  saveEvent,
  selectEvents,
  deleteEvent,
  updateEvent,
} from 'shared/state/ducks/events';
import { selectIsEditingTemplate } from 'shared/state/ducks/pageTemplates';

interface WidgetControlWrapperContainerNewProps {
  siteId: number;
  pageId: number;
  site?: Site;
  page?: Page;
  widgetId: string;
  eventList?: Array<EventData>;
  fetchEventList?: (widgetId: string) => Promise<Array<EventData>>;
  saveEvent?: (
    event: EventPayload,
    siteId: number,
    pageId: number
  ) => Promise<EventData>;
  removeEvent?: (eventId: number) => Promise<void>;
  updateEvent?: (
    eventId: number,
    eventPayload: EventPayload
  ) => Promise<EventData>;
}

const mapStateToProps = (
  state: RootState,
  ownProps: WidgetControlWrapperContainerNewProps
) => ({
  widgetState: selectWidget(
    ownProps.siteId,
    ownProps.pageId,
    ownProps.widgetId
  )(state),
  activeWidgetId: state.sites.activeWidgetId,
  eventList: selectEvents()(state),
  pagesnew: selectPages(ownProps.site.id)(state),
  undoState:
    state.undoabletodos.present[state.undoabletodos.present.length - 1],
  isEditingTemplate: selectIsEditingTemplate()(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: WidgetControlWrapperContainerNewProps
) => ({
  onUpdateConfig: (config: any, defaultWidget: boolean) => {
    dispatch(
      updateWidgetConfig(
        ownProps.siteId,
        ownProps.pageId,
        ownProps.widgetId,
        config,
        defaultWidget
      )
    );
  },
  onUpdateDefaultConfig: (config: any) => {
    dispatch(
      updateDefaultWidgetConfig(
        ownProps.siteId,
        ownProps.pageId,
        ownProps.widgetId,
        config
      )
    );
  },
  setActiveWidgetId: (widgetId: string) => {
    dispatch(setActiveWidgetId(widgetId));
  },
  removeWidget: (widgetId: string) => {
    dispatch(removeWidget(ownProps.siteId, ownProps.pageId, widgetId));
  },
  addTodoState: (pageContent: any) => {
    dispatch(addUndoRedoState(ownProps.site.id, ownProps.page.id, pageContent));
  },
  setResetToFalse: () => dispatch(setResetToFalse()),
  updatePageContent: (pageContent: any) => {
    dispatch(
      updatePageContent(ownProps.site.id, ownProps.page.id, pageContent)
    );
  },
  saveEvent: (event: EventPayload, siteId: number, pageId: number) => {
    dispatch(saveEvent(event, siteId, pageId));
  },
  removeEvent: (eventId: number) => {
    dispatch(deleteEvent(eventId));
  },
  updateEvent: (eventId: number, eventPayload: EventPayload) => {
    dispatch(updateEvent(eventId, eventPayload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetControlWrapperNew);
