import { createContext, useEffect, useMemo, useState } from 'react';
import { INSERT_TABLE_COMMAND } from '@lexical/table';
import {
  $getRoot,
  EditorThemeClasses,
  Klass,
  LexicalEditor,
  LexicalNode,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  TableCellNode,
  TableNode,
  TableRowNode,
  TableCellHeaderStates,
  $isTableRowNode,
  $isTableCellNode,
} from '@lexical/table';
import { Button } from '@uitk/react';

import { DialogActions } from '../../ui/Dialog';
import TextInput from '../../ui/TextInput';
import { mergeConsecutiveTablesTransform } from '../MarkdownTransformers';
import { traverseDescendants } from '../../utils/traverseDescendents';

export type CellContextShape = {
  cellEditorConfig: null | CellEditorConfig;
  cellEditorPlugins: null | JSX.Element | Array<JSX.Element>;
  set: (
    cellEditorConfig: null | CellEditorConfig,
    cellEditorPlugins: null | JSX.Element | Array<JSX.Element>
  ) => void;
};

export type CellEditorConfig = Readonly<{
  namespace: string;
  nodes?: ReadonlyArray<Klass<LexicalNode>>;
  onError: (error: Error, editor: LexicalEditor) => void;
  readOnly?: boolean;
  theme?: EditorThemeClasses;
}>;

export const CellContext = createContext<CellContextShape>({
  cellEditorConfig: null,
  cellEditorPlugins: null,
  set: () => {
    // Empty
  },
});

export function TableContext({ children }: { children: JSX.Element }) {
  const [contextValue, setContextValue] = useState<{
    cellEditorConfig: null | CellEditorConfig;
    cellEditorPlugins: null | JSX.Element | Array<JSX.Element>;
  }>({
    cellEditorConfig: null,
    cellEditorPlugins: null,
  });
  return (
    <CellContext.Provider
      value={useMemo(
        () => ({
          cellEditorConfig: contextValue.cellEditorConfig,
          cellEditorPlugins: contextValue.cellEditorPlugins,
          set: (cellEditorConfig, cellEditorPlugins) => {
            setContextValue({ cellEditorConfig, cellEditorPlugins });
          },
        }),
        [contextValue.cellEditorConfig, contextValue.cellEditorPlugins]
      )}
    >
      {children}
    </CellContext.Provider>
  );
}

export function InsertTableDialog({
  activeEditor,
  onClose,
}: {
  activeEditor: LexicalEditor;
  onClose: () => void;
}): JSX.Element {
  const [rows, setRows] = useState('5');
  const [columns, setColumns] = useState('5');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const row = Number(rows);
    const column = Number(columns);
    if (row && row > 0 && row <= 500 && column && column > 0 && column <= 50) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [rows, columns]);

  const onClick = () => {
    activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, {
      columns,
      rows,
    });

    onClose();
  };

  return (
    <>
      <TextInput
        placeholder={'# of rows (1-500)'}
        label="Rows"
        onChange={setRows}
        value={rows}
        data-test-id="table-modal-rows"
        type="number"
      />
      <TextInput
        placeholder={'# of columns (1-50)'}
        label="Columns"
        onChange={setColumns}
        value={columns}
        data-test-id="table-modal-columns"
        type="number"
      />
      <DialogActions data-test-id="table-model-confirm-insert">
        <Button size="m" disabled={isDisabled} onClick={onClick}>
          Confirm
        </Button>
      </DialogActions>
    </>
  );
}

export default function TableHeaderPlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    const unregisterTransform = editor.registerNodeTransform(
      TableNode,
      mergeConsecutiveTablesTransform
    );

    const unregisterUpdateListener = editor.registerUpdateListener(() => {
      editor.update(() => {
        const root = $getRoot();
        traverseDescendants(root, (node: LexicalNode) => {
          if (node instanceof TableNode) {
            const rows = node
              .getChildren()
              .filter(child => $isTableRowNode(child));
            if (rows.length === 0) return;
            for (let i = 1; i < rows.length; i++) {
              let cells: TableCellNode[] = [];
              if ($isTableRowNode(rows[i])) {
                const rowNode = rows[i] as TableRowNode;
                cells = rowNode
                  .getChildren()
                  .filter($isTableCellNode) as TableCellNode[];
              }
              if (cells.length > 0) {
                const leftCell = cells[0];
                if (
                  leftCell.getHeaderStyles() !== TableCellHeaderStates.NO_STATUS
                ) {
                  leftCell.setHeaderStyles(
                    TableCellHeaderStates.NO_STATUS,
                    TableCellHeaderStates.BOTH
                  );
                }
              }
            }
          }
        });
      });
    });
    return () => {
      unregisterTransform();
      unregisterUpdateListener();
    };
  }, [editor]);

  return null;
}
