import { Config } from './types';
import { defaultRichTextEditorData } from 'builder/util/constants';
import RichTextEditorWidgetControl from './RichTextEditorWidgetControl';
import RichTextEditorContainer from './RichTextEditorContainer';

export default ({
  type: 'RichText',
  displayName: 'Rich Text EditorUI',
  initialConfig: {
    ...defaultRichTextEditorData,
    content: '',
    enableTableOfContents: false,
    isEditingRichText: false,
    uniqueId: '123abc',
    width: 1120,
    height: 'auto',
    xLocation: 10,
    yLocation: 10,
    visible: true,
  },
  components: {
    render: RichTextEditorContainer,
    control: RichTextEditorWidgetControl,
  },
  copyable: true,
} as unknown) as HorizonWidgetDefinition<Config>;
