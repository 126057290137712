import React, { ChangeEvent, useState, useEffect } from 'react';
import { FormControl } from '@uitk/react';
import styled from 'styled-components';
import { getCombinedFontsForTheme } from 'builder/util/getCombinedFonts';
import { fontSizes } from 'builder/util/constants';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import { ColorButtonWrapper } from '../Background/Background';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import { MainLabelStyles } from '../Typography/Typography';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import { FontSelect, FontSizeSelect } from '../Accordion/AccordionTypography';

const ColorButtonAndLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 1rem 0;
`;

export const FontDropdowns = styled.div`
  display: flex;
  padding-left: 50px;
  align-content: center;
`;

export const TextIconWrapper = styled.div`
  cursor: pointer;
  font-family: OptumSans, sans-serif;
  text-align: center;
  padding-right: 5px;
  border-radius: 7px;
  &:hover {
    background: #d9f6fa;
  }
`;

export const StyledCustomIcon = styled.span`
  height: 1em;
`;

export interface TabsTypographyProps<T> {
  handleFontSize: (data: string) => void;
  handleFont: (data: string) => void;
  handleTextColor: (data: string) => void;
  widgetState: Widget<T>;
  pages: Array<Page>;
  site?: Site;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
  disabled?: boolean;
}

export const TabsTypography: React.FC<TabsTypographyProps<any>> = (
  tabsTypographyProps: TabsTypographyProps<any>
) => {
  const {
    handleFontSize,
    handleFont,
    handleTextColor,
    widgetState,
    site,
    updateSite,
    saveSite,
    disabled,
  } = tabsTypographyProps;

  const [fontFamily, setFontFamily] = useState(widgetState.config.font);
  const [fontSize, setFontSize] = useState(widgetState.config.fontSize);
  const [textColor, setTextColor] = useState(widgetState.config.tabsTextColor);
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover-tabs-typography' : undefined;

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  useEffect(() => {
    handleFontSize(fontSize);
  }, [fontSize]);

  useEffect(() => {
    handleFont(fontFamily);
  }, [fontFamily]);

  useEffect(() => {
    handleTextColor(textColor);
  }, [textColor]);

  useEffect(() => {
    setFontFamily(widgetState.config.font);
    setFontSize(widgetState.config.fontSize);
    setTextColor(widgetState.config.tabsTextColor);
  }, [widgetState.config]);

  const handleFontChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setFontFamily(e.target.value);
    handleFont(fontFamily);
  };

  const handleFontSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setFontSize(e.target.value);
    handleFontSize(fontSize);
  };

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleColorChange = newColor => {
    setTextColor(newColor);
  };

  return (
    <>
      <ColorButtonAndLabelWrapper>
        <MainLabelStyles>Font</MainLabelStyles>
        <FontDropdowns>
          <FormControl
            data-test-id="font-select-dropdown"
            disabled={disabled}
            id="controlled-select"
          >
            <FontSelect onChange={handleFontChange} value={fontFamily}>
              {Object.keys(getCombinedFontsForTheme()).map(f => {
                return (
                  <option value={f} key={f}>
                    {f}
                  </option>
                );
              })}
            </FontSelect>
          </FormControl>
          <FormControl
            data-test-id="fontsize-dropdown"
            disabled={disabled}
            id="controlled-select"
          >
            <FontSizeSelect onChange={handleFontSizeChange} value={fontSize}>
              {fontSizes.map(f => (
                <option value={f}>{f}pt</option>
              ))}
            </FontSizeSelect>
          </FormControl>
        </FontDropdowns>
      </ColorButtonAndLabelWrapper>
      <ColorButtonAndLabelWrapper>
        <MainLabelStyles>Color</MainLabelStyles>
        <ColorButtonWrapper
          data-test-id="text-color-icon"
          id="button-color"
          onClick={handleColorEditClick}
        >
          <ColorButton color={textColor} disabled={disabled} />
        </ColorButtonWrapper>
        <StyledPickerPopover
          data-test-id="widget-color-picker-popover"
          id={pickerId}
          open={pickerOpen}
          onClick={handlePickerClick}
          onClose={handlePickerClose}
          anchorEl={pickerAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <StyledPickerWrapper>
            <StyledPickerHeader data-test-id="styled-picker-header">
              Color Selector
            </StyledPickerHeader>
            <StyledClose
              data-test-id="close-icon"
              onClick={handlePickerClose}
            />
          </StyledPickerWrapper>
          <GradientColorPicker
            value={textColor}
            site={site}
            handleColorChange={handleColorChange}
            onChange={handleColorChange}
            updateSite={updateSite}
            saveSite={saveSite}
            anchorEl={pickerAnchorEl}
          />
        </StyledPickerPopover>
      </ColorButtonAndLabelWrapper>
    </>
  );
};
