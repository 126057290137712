import React, { ChangeEvent, Fragment, useState } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { defaultTheme as theme } from 'link-ui-react';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import { IconCopy, IconTrash } from '@tabler/icons-react';
import Checkbox from 'shared/components/Checkbox/Checkbox';
import { RequiredFieldWrapper } from './EnableFullScreenWidth';

const StyledItems = styled.div`
  padding-top: 10px;
`;
const StyledLabel = styled.span`
  color: rgb(29, 25, 41);
  font-size: 12px;
  font-family: OptumSans;
  font-weight: bold;
  margin-right: 19px;
  display: inline-block;
  white-space: normal;
`;
const radiusLabelStyles = {
  color: '#1d1929',
  'font-size': '12px',
  'font-family': 'OptumSans',
  'font-weight': 'bold',
  marginRight: '19px',
};

const StyledIcon = styled.div`
  height: 1em;
`;

const TextInput = styled.input`
  -webkit-appearance: none;
  border: none;
  box-shadow: 0 0 0 1px #737373;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #333333;
  font-size: 1rem;
  font-weight: 400;
  font-family: UHC Sans;
  padding: 0.75rem;
  width: 100%;
  outline: none;
  -webkit-transition: 70ms;
  transition: 70ms;
  -webkit-transition-property: border, box-shadow;
  transition-property: border, box-shadow;
  margin-top: 0.25rem;
  &:hover {
    box-shadow: 0 0 0 1px #196ecf;
  }
  &:active,
  &:focus {
    background-color: #ffffff;
    box-shadow: 0 0 0 3px #196ecf;
  }
  &input &:focus-visible {
    outline-offset: 0px;
  }
  &:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
`;
export const StyledRequiredLabel = styled.span`
  color: #ff0000;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
`;

export const reorder = (list, startIndex, endIndex) => {
  const result: Array<DragDropItemData> = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getDeletedItems = (prevArray, itemID) => {
  const newItemsArray = [];
  let idIncremented = true;
  let after = false;

  for (const item of prevArray) {
    if (item.id === itemID && idIncremented) {
      idIncremented = false;
      after = true;
    } else if (item.id > itemID && (after || idIncremented)) {
      item.id -= 1;
      newItemsArray.push(item);
    } else {
      newItemsArray.push(item);
    }
  }
  return newItemsArray;
};

export const getDuplicatedItems = (prevArray, itemID) => {
  const newItemsArray = [];
  let idIncremented = true;
  let after = false;

  for (const item of prevArray) {
    if (item.id === itemID && idIncremented) {
      newItemsArray.push(item);
      const duplicateItem = { ...item };
      duplicateItem.id += 1;
      duplicateItem.name = `${item.name} Copy`;
      newItemsArray.push(duplicateItem);
      idIncremented = false;
      after = true;
    } else if (item.id > itemID && (idIncremented || after)) {
      item.id += 1;
      newItemsArray.push(item);
    } else {
      newItemsArray.push(item);
    }
  }
  return newItemsArray;
};
export interface DragDropItemData {
  id: number;
  name: string;
  required: boolean;
}
export interface DragDropBeautifulDnDProps<T> {
  dragDropItemsData: (
    data: Array<DragDropItemData>,
    currentTabIndex?: number
  ) => void;
  itemsState: Array<DragDropItemData>;
  itemName: string;
  heading: string;
  currentPage?: PageVersion;
  addChild?: (
    type: string,
    initialConfig: T,
    location: number,
    widgetId: string,
    newParentWidgetId: string,
    tabIndex: number
  ) => void;
  widgetState?: Widget<T>;
  updateCurrentPage?: (pageVersion: PageVersion) => void;
  updateCurrentTab?: (panelId: number) => void;
  removeWidget?: (widgetId: string) => void;
  isEditingTemplate?: boolean;
}
export const DragDropBeautifulDnD: React.FC<DragDropBeautifulDnDProps<any>> = (
  props: DragDropBeautifulDnDProps<any>
) => {
  const {
    itemsState,
    dragDropItemsData,
    itemName,
    heading,
    widgetState,
    currentPage,
    updateCurrentPage,
    addChild,
    updateCurrentTab,
    isEditingTemplate,
  } = props;
  const [items, setItems] = useState(itemsState);
  const [isItemDeleted, setItemDeleted] = useState(false);
  React.useEffect(() => {
    const currentTabIndex = isItemDeleted ? 0 : undefined;
    dragDropItemsData(items, currentTabIndex);
    setItemDeleted(false);
  }, [items]);

  const handleItemChange = (e: ChangeEvent<HTMLInputElement>, index) => {
    const updatedItems = [...items];
    for (var i = 0; i < updatedItems.length; i++) {
      if (updatedItems[i].id === index) {
        updatedItems[i].name = e.target.value;
        break;
      }
    }
    setItems(updatedItems);
  };

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const parentWidgetId = widgetState.id;
    const tabWidgetChildren = currentPage.content[parentWidgetId].children;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    if (!isEditingTemplate) {
      // Get indexes of required items
      const requiredIndexes = items.reduce((indexes, item, index) => {
        if (item.required) indexes.push(index);
        return indexes;
      }, []);

      // Disable reordering if the source or destination item is required or if it affects any required item's position
      if (
        requiredIndexes.includes(sourceIndex) ||
        requiredIndexes.includes(destinationIndex) ||
        requiredIndexes.some(
          index =>
            (sourceIndex < index && destinationIndex >= index) ||
            (sourceIndex > index && destinationIndex <= index)
        )
      ) {
        return;
      }
    }

    // Reorder the items in the list
    const reorderedItems = reorder(items, source.index, destination.index);
    setItems(reorderedItems);

    // Create a map to store the new tabIndexes for each item
    const newTabIndexes = {};

    // Calculate new tabIndex values for each item
    for (let i = 0; i < tabWidgetChildren.length; i++) {
      const widgetId = tabWidgetChildren[i];
      const widget = props.currentPage.content[widgetId];
      const oldTabIndex = widget.tabIndex;

      // Calculate the new tabIndex based on the reorder
      if (oldTabIndex === sourceIndex) {
        newTabIndexes[widgetId] = destinationIndex;
      } else if (
        sourceIndex < destinationIndex &&
        oldTabIndex > sourceIndex &&
        oldTabIndex <= destinationIndex
      ) {
        newTabIndexes[widgetId] = oldTabIndex - 1;
      } else if (
        sourceIndex > destinationIndex &&
        oldTabIndex >= destinationIndex &&
        oldTabIndex < sourceIndex
      ) {
        newTabIndexes[widgetId] = oldTabIndex + 1;
      } else {
        newTabIndexes[widgetId] = oldTabIndex;
      }
    }

    // Update the tabIndex values in childTabWidgets
    for (let i = 0; i < tabWidgetChildren.length; i++) {
      const widgetId = tabWidgetChildren[i];
      props.currentPage.content[widgetId].tabIndex = newTabIndexes[widgetId];
    }
  };

  const handleAddItem = () => {
    setItems(items => [
      ...items,
      {
        id: items.length,
        name: `${itemName}` + `${items.length + 1}`,
        required: false,
      },
    ]);
  };

  const handleDuplicate = itemID => {
    setItems(prevArray => {
      return getDuplicatedItems(prevArray, itemID);
    });
    const parentWidgetId = widgetState.id;
    let widgetToDuplicate = currentPage.content[parentWidgetId];
    const childWidgets = widgetToDuplicate.children;
    const duplicatedTabWidgets = childWidgets.map(
      widgetId => props.currentPage.content[widgetId]
    );

    let tabIndexIncremented = true;
    let afterIndex = false;

    for (const [index, widgetItem] of duplicatedTabWidgets.entries()) {
      if (widgetItem.tabIndex === itemID && tabIndexIncremented) {
        const duplicateItem = { ...widgetItem };
        duplicateItem.id = uuidv4();
        duplicateItem.tabIndex += 1;
        addChild(
          duplicateItem.type,
          duplicateItem.config,
          0,
          duplicateItem.id,
          parentWidgetId,
          duplicateItem.tabIndex
        );
        if (index === duplicatedTabWidgets.length) {
          tabIndexIncremented = false;
          afterIndex = true;
        }
      } else if (
        widgetItem.tabIndex > itemID &&
        (tabIndexIncremented || afterIndex)
      ) {
        widgetItem.tabIndex += 1;
      }
    }
  };
  const handleRequired = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const updatedItems = [...items];
    for (let i = 0; i < updatedItems.length; i++) {
      if (updatedItems[i].id === id) {
        updatedItems[i].required = e.target.checked;
        break;
      }
    }
    setItems(updatedItems);
  };
  const handleDelete = itemID => {
    updateCurrentTab(0);
    setItemDeleted(true);
    setItems(prevArray => {
      return getDeletedItems(prevArray, itemID);
    });
    const parentWidgetId = widgetState.id;
    let widgetToDelete = currentPage.content[parentWidgetId];
    const childWidgets = widgetToDelete.children;

    const tabWidgetChildren = childWidgets.map(
      widgetId => props.currentPage.content[widgetId]
    );

    let tabIndexIncremented = true;
    let afterIndex = false;
    let newWidgetArray = _.cloneDeep(currentPage.content);
    for (const [index, widgetItem] of tabWidgetChildren.entries()) {
      if (widgetItem.tabIndex === itemID && tabIndexIncremented) {
        newWidgetArray = Object.keys(newWidgetArray)
          .filter(key => key !== widgetItem.id)
          .reduce((obj, key) => {
            obj[key] = {
              ...newWidgetArray[key],
              children: newWidgetArray[key].children.filter(
                id => id !== widgetItem.id
              ),
            };
            return obj;
          }, {} as any);
        if (index === tabWidgetChildren.length) {
          tabIndexIncremented = false;
          afterIndex = true;
        }
      } else if (
        widgetItem.tabIndex > itemID &&
        (tabIndexIncremented || afterIndex)
      ) {
        widgetItem.tabIndex -= 1;
        newWidgetArray[widgetItem.id] = { ...widgetItem };
      } else {
        newWidgetArray[widgetItem.id] = { ...widgetItem };
      }
    }
    currentPage.content = newWidgetArray;
    updateCurrentPage(currentPage);
  };
  const getDeleteDuplicateVisibility = item =>
    isEditingTemplate || !item.required;
  return (
    <StyledItems>
      <StyledLabel style={radiusLabelStyles}>{heading}</StyledLabel>
      <div style={{ paddingTop: '5px' }}>
        <DragDropContext
          data-test-id="drag-drop-context"
          onDragEnd={handleDragEnd}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  ...(snapshot.isDraggingOver
                    ? {
                        background: 'white',
                        borderRadius: '2.5px',
                      }
                    : {}),
                }}
              >
                {items.map((item, index) => (
                  <Draggable
                    data-test-id="draggable"
                    key={item.id}
                    index={index}
                    draggableId={item.id.toString()}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          // default item style
                          padding: '1px 0px 5px 16px',
                          borderWidth: '1px',
                          borderColor: 'white',
                          borderStyle: 'solid',
                          borderRadius: '2.5px',
                          marginBottom: '5px',
                          // default drag style
                          ...provided.draggableProps.style,
                          // customized drag style
                          ...(snapshot.isDragging
                            ? {
                                borderWidth: '1px',
                                borderColor: 'white',
                                borderStyle: 'solid',
                                borderRadius: '2.5px',
                              }
                            : {}),
                        }}
                      >
                        <div style={{ display: 'inline-flex' }}>
                          <TextInput
                            style={{
                              fontSize: '12px',
                              fontFamily: 'OptumSans',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                              width: 'auto',
                              marginLeft: '-15px',
                            }}
                            data-test-id="paneltext-box"
                            value={item.name}
                            onChange={e => {
                              handleItemChange(e, item.id);
                            }}
                            disabled={!isEditingTemplate && item.required}
                          />
                          {getDeleteDuplicateVisibility(item) && (
                            <Fragment>
                              <StyledIcon
                                data-test-id="duplicate-slide-icon"
                                style={{
                                  paddingRight: '10px',
                                  paddingLeft: '10px',
                                  paddingTop: '9px',
                                }}
                                title="duplicate"
                                onClick={() => handleDuplicate(item.id)}
                              >
                                <IconCopy />
                              </StyledIcon>
                              <StyledIcon
                                data-test-id="delete-slide-icon"
                                style={{ paddingTop: '9px' }}
                                title="delete"
                                onClick={() => handleDelete(item.id)}
                              >
                                <IconTrash />
                              </StyledIcon>
                            </Fragment>
                          )}

                          {isEditingTemplate ? (
                            <RequiredFieldWrapper>
                              <Checkbox
                                data-test-id="required-checkbox"
                                checked={item.required || false}
                                onChange={e => handleRequired(e, item.id)}
                              />
                              <StyledLabel>Required</StyledLabel>
                            </RequiredFieldWrapper>
                          ) : (
                            item.required && (
                              <StyledRequiredLabel>
                                *Required
                              </StyledRequiredLabel>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <StyledLabel
        data-test-id="add-slide"
        style={{ color: `${theme.colors.aux.lightBlue}`, fontSize: '12px' }}
        onClick={handleAddItem}
      >
        Add {itemName} +
      </StyledLabel>
    </StyledItems>
  );
};
