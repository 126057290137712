import {
  $createParagraphNode,
  $isParagraphNode,
  ElementNode,
  LexicalNode,
  NodeKey,
  SerializedElementNode,
  Spread,
  DOMExportOutput,
  DOMConversionOutput,
  DOMConversionMap,
} from 'lexical';

/** The allowed Admonition types. */
export type AdmonitionType =
  | 'note'
  | 'info'
  | 'tip'
  | 'question'
  | 'danger'
  | 'warning';

const ALLOWED_ADMONITION_TYPES: AdmonitionType[] = [
  'note',
  'info',
  'tip',
  'question',
  'danger',
  'warning',
];

/** For serialization: what this node looks like in JSON. */
export type SerializedAdmonitionNode = Spread<
  {
    type: 'admonition';
    version: 1;
    admonitionType: AdmonitionType;
    title: string;
  },
  SerializedElementNode
>;

export class AdmonitionNode extends ElementNode {
  __admonitionType: AdmonitionType;
  __title: string;

  static getType(): string {
    return 'admonition';
  }

  static clone(node: AdmonitionNode): AdmonitionNode {
    return new AdmonitionNode(node.__admonitionType, node.__title, node.__key);
  }

  constructor(admonitionType: AdmonitionType, title: string, key?: NodeKey) {
    super(key);
    this.__admonitionType = admonitionType;
    this.__title = title;
  }

  exportJSON(): SerializedAdmonitionNode {
    return {
      ...super.exportJSON(),
      type: 'admonition',
      version: 1,
      admonitionType: this.__admonitionType,
      title: this.__title,
    };
  }

  static importJSON(serializedNode: SerializedAdmonitionNode): AdmonitionNode {
    const { admonitionType, title, children } = serializedNode;
    const node = $createAdmonitionNode(admonitionType, title);
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    const childrenNodes = children.map(() => $createParagraphNode());
    childrenNodes.forEach(child => node.append(child));
    return node;
  }

  createDOM(): HTMLElement {
    const element = document.createElement('div');
    element.setAttribute('class', `admonition ${this.__admonitionType}`);
    return element;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('div');
    element.setAttribute('class', `admonition ${this.__admonitionType}`);
    return { element };
  }

  updateDOM(prevNode: AdmonitionNode, dom: HTMLElement): boolean {
    if (prevNode.__admonitionType !== this.__admonitionType) {
      dom.className = `admonition ${this.__admonitionType}`;
    }
    // Return false because we don't need Lexical to do any deeper updates to the DOM
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode: HTMLElement) => {
        if (!domNode.classList.contains('admonition')) {
          return null;
        }
        return {
          conversion: $convertAdmonitionElement,
          priority: 2,
        };
      },
    };
  }

  isEmpty(): boolean {
    const children = this.getChildren();
    if (children.length === 0) {
      return true;
    }
    if (children.length === 1) {
      const child = children[0];
      // If it’s a ParagraphNode with zero text, consider AdmonitionNode empty
      return child.getTextContent().length === 0;
    }
    return false;
  }

  getTitle(): string {
    return this.__title;
  }

  setTitle(title: string): void {
    this.__title = title;
  }

  append(...nodesToAppend: LexicalNode[]): this {
    nodesToAppend.forEach(node => {
      if ($isParagraphNode(node)) {
        super.append(node);
      } else {
        // This shouldn't happen because the toolbar is disabled while in an Admonition, but if it does, warn the user.
        console.warn('Only paragraph nodes can be added to an AdmonitionNode.');
      }
    });
    return this;
  }
}

/** Helper to create a new AdmonitionNode. */
export function $createAdmonitionNode(
  admonitionType: AdmonitionType,
  title: string
) {
  return new AdmonitionNode(admonitionType, title);
}

/** Helper to convert a DOM node into an AdmonitionNode. */
function $convertAdmonitionElement(
  domNode: HTMLElement
): null | DOMConversionOutput {
  let admonitionType = 'note';
  for (const c of domNode.classList) {
    if (ALLOWED_ADMONITION_TYPES.includes(c as AdmonitionType)) {
      admonitionType = c;
      break;
    }
  }
  const node = $createAdmonitionNode(admonitionType as AdmonitionType, '');
  return { node };
}

/** Type-guard helper. */
export function $isAdmonitionNode(
  node: LexicalNode | null | undefined
): node is AdmonitionNode {
  return node instanceof AdmonitionNode;
}
