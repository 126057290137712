import { forwardRef } from 'react';
import { defaultTheme as theme } from 'link-ui-react';
import { Divider } from '@material-ui/core';

interface ContextMenuItem {
  label: string;
  action: () => void;
  shortcut?: string;
  enableOption?: boolean;
}

interface ContextMenuProps {
  menuitems: ContextMenuItem[];
  position: { x: number; y: number };
  disabled?: boolean;
}

export const ContextMenu = forwardRef<HTMLDivElement, ContextMenuProps>(
  ({ menuitems, position, disabled }, ref) => (
    <span title={disabled ? 'This item cannot be deleted or duplicated.' : ''}>
      <div
        ref={ref}
        className="custom-context-menu"
        role="menu"
        style={{
          position: 'absolute',
          top: position.y,
          left: position.x,
          padding: '4px 0',
          background: theme.colors.aux.white,
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          border: '1px solid #ccc',
          borderRadius: '4px',
          zIndex: 9999,
          minWidth: '10rem', // Adjust the minimum width as needed
          color: `${disabled ? 'rgb(204, 204, 204)' : 'black'}`,
        }}
      >
        {menuitems.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              item.action();
            }}
            role="menuitem"
            style={{
              pointerEvents: `${
                !item.enableOption && disabled ? 'none' : 'auto'
              }`,
              padding: `${item.label === 'Divider' ? '0 8px' : '8px'}`,
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              color: `${
                !item.enableOption && disabled ? 'rgb(204, 204, 204)' : 'black'
              }`,
            }}
          >
            {item.label === 'Divider' ? (
              <Divider variant="middle" style={{ margin: '0px' }} />
            ) : (
              item.label
            )}
            <span
              style={{
                float: 'right',
                color: `${
                  !item.enableOption && disabled
                    ? 'rgb(204, 204, 204)'
                    : 'black'
                }`,
              }}
            >
              {item.shortcut}
            </span>
          </div>
        ))}
      </div>
    </span>
  )
);
